<script context="module" lang="ts">
  import Dropdown from "./Dropdown.svelte";
  export interface AvailableLanguage {
    language_name: string;
  }

  export interface AvailableLanguages {
    [code: string]: AvailableLanguage;
  }

  interface LanguageOption {
    code: string;
    language_name: string;
  }

  function update(available: AvailableLanguages) {
    const language_names: LanguageOption[] = Object.entries(available).map(
      ([code, language]: [string, AvailableLanguage]) => ({code, language_name: language.language_name})
    );

    language_names.sort((a, b) => (a.code < b.code ? -1 : a.code === b.code ? 0 : 1));

    return {language_names};
  }
</script>

<script lang="ts">
  import {_, language} from "./translate";

  let extra_classes: string | undefined = undefined;
  export let available: AvailableLanguages;
  export {extra_classes as class};
  export let id: string;

  let open = false;
  let selected_index = 0;
  const language_inputs_elements: {[code: string]: HTMLInputElement} = {};

  function on_keydown(event: KeyboardEvent) {
    if (!event.repeat) {
      if (event.ctrlKey && event.key === " ") {
        open = true;
      }
    }

    if (open) {
      if (event.key === "ArrowUp" || event.key === "ArrowLeft" || (event.shiftKey && event.key === "Tab")) {
        selected_index = (selected_index - 1 + language_names.length) % language_names.length;
        language_inputs_elements[language_names[selected_index].code].focus();
      } else if (event.key === "ArrowDown" || event.key === "ArrowRight" || event.key === "Tab") {
        selected_index = (selected_index + 1) % language_names.length;
        language_inputs_elements[language_names[selected_index].code].focus();
      }
    }
  }

  function selected(option: LanguageOption) {
    $language = option.code;
    language_names.forEach((lang, idx) => {
      if (lang.code === option.code) {
        selected_index = idx;
        language_inputs_elements[lang.code].focus();
      }
    });
  }

  $: classes = "cg-language-select" + (extra_classes ? ` ${extra_classes}` : "");
  $: ({language_names} = update(available));
  $: if ($language && open) {
    language_names.forEach((lang) => {
      if (lang.code === $language) {
        selected(lang);
      }
    });
  }
</script>

<span class={classes} {id}>
  <Dropdown
    options={language_names}
    toggle_aria_label={$_("Select language")}
    toggle_tabindex={0}
    menu_class="dropdown-menu-right"
    {selected}
    bind:open
  >
    <svelte:fragment slot="toggle">
      <span class="glyphicon glyphicon-globe" />
      <span class="link" id={id + "-current"}>{$language.toLocaleUpperCase()}</span>
    </svelte:fragment>
    <svelte:fragment slot="option" let:option>
      <!-- svelte-ignore a11y-missing-attribute -->
      <a>
        <label lang={option.code}>
          <input
            name="language-selected"
            value={option.code}
            type="radio"
            checked={$language === option.code}
            on:keydown|preventDefault={on_keydown}
            bind:this={language_inputs_elements[option.code]}
          />
          <span>{option.language_name}</span>
        </label>
      </a>
    </svelte:fragment>
  </Dropdown>
</span>
