<script context="module" lang="ts">
  import type {TranslateFn} from "$lib/translate";
  import {default_badge_height, get_store_image} from "./branding";

  function update(_: TranslateFn, language: string, badge_height?: number) {
    // Google Play badges already contain the required quarter height
    // padding.
    return {
      image_height: Math.ceil(1.5 * Number(badge_height ?? default_badge_height)),
      image_path: get_store_image("google-play", language),
      alt: _("Get it on {google_play}").replace("{google_play}", "Google Play"),
    };
  }
</script>

<script lang="ts">
  import {_} from "$lib/translate";

  export let language: string;
  export let badge_height: number | undefined = undefined;

  // Get reactive state
  $: ({image_height, image_path, alt} = update($_, language, badge_height));
</script>

<span>
  <img src={image_path} {alt} height={image_height} />
</span>
