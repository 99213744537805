<!-- @component
Default HTML content that can be replaced with custom HTML (**untrusted**).

Custom HTML will be **sanitized**.

## Parameters
@param {string} html - An optional **untrusted** custom HTML to override the
default content.

@param {string} class - An optional class string to apply to a wrapping
container.

@param {string} container - An optional HTML tag name of the element that will
be used to wrap the default or custom HTML content.

If neither `class` or `container` are specified, the default or custom HTML
content will be included directly *without* a wrapping HTML container element.
If only `class` is specified, a `<div>` will be used as the default container.
-->
<script lang="ts">
  import UntrustedHtml from "./UntrustedHtml.svelte";

  let className: string | null | undefined = undefined;

  export let html: string | null | undefined;
  export {className as class};
  export let container: string | undefined = undefined;
  export let id: string | undefined = undefined;
</script>

{#if className || container || id}
  <svelte:element this={container || "div"} class={className} {id}>
    {#if html}
      <UntrustedHtml {html} />
    {:else}
      <slot />
    {/if}
  </svelte:element>
{:else if html}
  <UntrustedHtml {html} />
{:else}
  <slot />
{/if}
