<script context="module" lang="ts">
  import type {Config} from "./Header.types";
  import PlaceRegion from "$lib/place/PlaceRegion.svelte";
  import Placement from "$lib/place/Placement.svelte";
  import Profile from "$lib/Profile.svelte";
  import LanguageSelect from "$lib/LanguageSelect.svelte";

  function update(config: Config) {
    const header_classes: string[] = ["skin", config.page.skin?.show_header ? "header" : "no-header"];

    if (config.page.skin?.brand_location) {
      header_classes.push(`text-${config.page.skin?.brand_location}`);
    }

    const header_brand_classes = "skin align brand group" + (!config.page.skin?.show_header ? " hide" : "");

    return {
      header_classes: header_classes.join(" "),
      header_brand_classes,
    };
  }
</script>

<script lang="ts">
  export let config: Config;

  $: ({header_classes, header_brand_classes} = update(config));
</script>

<div class={header_classes} id="cg-guest-header">
  <div>
    <Profile profile={config.profile} class="pull-right" id="cg-profile" />
    <LanguageSelect available={config.language.available} class="pull-right" id="cg-language-select" />
    <PlaceRegion tag="div" name="header" self_contained={true} class="skin stack">
      <Placement
        tag="div"
        position="header-brand"
        parameters={config.page.skin?.brand_placement}
        class={header_brand_classes}
      >
        {#if config.page.skin?.brand_link_url}
          <a href={config.page.skin.brand_link_url} class="skin brand link">
            {#if config.page.skin.brand_image_url}
              <!-- svelte-ignore a11y-missing-attribute -->
              <img src={config.page.skin.brand_image_url} class="skin brand image" />
            {/if}
            {#if config.page.skin.brand_title}
              <h1 class="skin brand title">{config.page.skin.brand_title}</h1>
            {/if}
            {#if !config.page.skin.brand_image_url && !config.page.skin.brand_title}
              <h1 class="skin brand no-image no-title">&nbsp;</h1>
            {/if}
          </a>
        {/if}
        {#if !config.page.skin?.brand_link_url}
          <span class="skin brand no-link">
            {#if config.page.skin?.brand_image_url}
              <!-- svelte-ignore a11y-missing-attribute -->
              <img src={config.page.skin.brand_image_url} class="skin brand image" />
            {/if}
            {#if config.page.skin?.brand_title}
              <h1 class="skin brand title">{config.page.skin.brand_title}</h1>
            {/if}
            {#if !config.page.skin?.brand_image_url && !config.page.skin?.brand_title}
              <h1 class="skin brand no-image no-title">&nbsp;</h1>
            {/if}
          </span>
        {/if}
      </Placement>
    </PlaceRegion>
  </div>
</div>
