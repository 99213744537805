import app_store_da_black from "$assets/branding/app-store/da/black.svg";
import app_store_da_white from "$assets/branding/app-store/da/white.svg";
import app_store_de_black from "$assets/branding/app-store/de/black.svg";
import app_store_de_white from "$assets/branding/app-store/de/white.svg";
import app_store_en_black from "$assets/branding/app-store/en/black.svg";
import app_store_en_white from "$assets/branding/app-store/en/white.svg";
import app_store_es_black from "$assets/branding/app-store/es/black.svg";
import app_store_es_white from "$assets/branding/app-store/es/white.svg";
import app_store_eu_black from "$assets/branding/app-store/eu/black.svg";
import app_store_eu_white from "$assets/branding/app-store/eu/white.svg";
import app_store_fr_black from "$assets/branding/app-store/fr/black.svg";
import app_store_fr_white from "$assets/branding/app-store/fr/white.svg";
import app_store_it_black from "$assets/branding/app-store/it/black.svg";
import app_store_it_white from "$assets/branding/app-store/it/white.svg";
import app_store_ja_black from "$assets/branding/app-store/ja/black.svg";
import app_store_ja_white from "$assets/branding/app-store/ja/white.svg";
import app_store_ko_black from "$assets/branding/app-store/ko/black.svg";
import app_store_ko_white from "$assets/branding/app-store/ko/white.svg";
import app_store_nl_black from "$assets/branding/app-store/nl/black.svg";
import app_store_nl_white from "$assets/branding/app-store/nl/white.svg";
import app_store_no_black from "$assets/branding/app-store/no/black.svg";
import app_store_no_white from "$assets/branding/app-store/no/white.svg";
import app_store_pt_black from "$assets/branding/app-store/pt/black.svg";
import app_store_pt_white from "$assets/branding/app-store/pt/white.svg";
import app_store_ru_black from "$assets/branding/app-store/ru/black.svg";
import app_store_ru_white from "$assets/branding/app-store/ru/white.svg";
import app_store_sv_black from "$assets/branding/app-store/sv/black.svg";
import app_store_sv_white from "$assets/branding/app-store/sv/white.svg";
import app_store_tr_black from "$assets/branding/app-store/tr/black.svg";
import app_store_tr_white from "$assets/branding/app-store/tr/white.svg";
import app_store_zh_black from "$assets/branding/app-store/zh/black.svg";
import app_store_zh_white from "$assets/branding/app-store/zh/white.svg";
import mac_app_store_da_black from "$assets/branding/mac-app-store/da/black.svg";
import mac_app_store_da_white from "$assets/branding/mac-app-store/da/white.svg";
import mac_app_store_de_black from "$assets/branding/mac-app-store/de/black.svg";
import mac_app_store_de_white from "$assets/branding/mac-app-store/de/white.svg";
import mac_app_store_en_black from "$assets/branding/mac-app-store/en/black.svg";
import mac_app_store_en_white from "$assets/branding/mac-app-store/en/white.svg";
import mac_app_store_es_black from "$assets/branding/mac-app-store/es/black.svg";
import mac_app_store_es_white from "$assets/branding/mac-app-store/es/white.svg";
import mac_app_store_eu_black from "$assets/branding/mac-app-store/eu/black.svg";
import mac_app_store_eu_white from "$assets/branding/mac-app-store/eu/white.svg";
import mac_app_store_fr_black from "$assets/branding/mac-app-store/fr/black.svg";
import mac_app_store_fr_white from "$assets/branding/mac-app-store/fr/white.svg";
import mac_app_store_it_black from "$assets/branding/mac-app-store/it/black.svg";
import mac_app_store_it_white from "$assets/branding/mac-app-store/it/white.svg";
import mac_app_store_ja_black from "$assets/branding/mac-app-store/ja/black.svg";
import mac_app_store_ja_white from "$assets/branding/mac-app-store/ja/white.svg";
import mac_app_store_ko_black from "$assets/branding/mac-app-store/ko/black.svg";
import mac_app_store_ko_white from "$assets/branding/mac-app-store/ko/white.svg";
import mac_app_store_nl_black from "$assets/branding/mac-app-store/nl/black.svg";
import mac_app_store_nl_white from "$assets/branding/mac-app-store/nl/white.svg";
import mac_app_store_no_black from "$assets/branding/mac-app-store/no/black.svg";
import mac_app_store_no_white from "$assets/branding/mac-app-store/no/white.svg";
import mac_app_store_pt_black from "$assets/branding/mac-app-store/pt/black.svg";
import mac_app_store_pt_white from "$assets/branding/mac-app-store/pt/white.svg";
import mac_app_store_ru_black from "$assets/branding/mac-app-store/ru/black.svg";
import mac_app_store_ru_white from "$assets/branding/mac-app-store/ru/white.svg";
import mac_app_store_sv_black from "$assets/branding/mac-app-store/sv/black.svg";
import mac_app_store_sv_white from "$assets/branding/mac-app-store/sv/white.svg";
import mac_app_store_tr_black from "$assets/branding/mac-app-store/tr/black.svg";
import mac_app_store_tr_white from "$assets/branding/mac-app-store/tr/white.svg";
import mac_app_store_zh_black from "$assets/branding/mac-app-store/zh/black.svg";
import mac_app_store_zh_white from "$assets/branding/mac-app-store/zh/white.svg";
import google_play_ar from "$assets/branding/google-play/ar/badge.png";
import google_play_da from "$assets/branding/google-play/da/badge.png";
import google_play_de from "$assets/branding/google-play/de/badge.png";
import google_play_en from "$assets/branding/google-play/en/badge.png";
import google_play_es from "$assets/branding/google-play/es/badge.png";
import google_play_eu from "$assets/branding/google-play/eu/badge.png";
import google_play_fr from "$assets/branding/google-play/fr/badge.png";
import google_play_hi from "$assets/branding/google-play/hi/badge.png";
import google_play_it from "$assets/branding/google-play/it/badge.png";
import google_play_ja from "$assets/branding/google-play/ja/badge.png";
import google_play_ko from "$assets/branding/google-play/ko/badge.png";
import google_play_nl from "$assets/branding/google-play/nl/badge.png";
import google_play_no from "$assets/branding/google-play/no/badge.png";
import google_play_pl from "$assets/branding/google-play/pl/badge.png";
import google_play_pt from "$assets/branding/google-play/pt/badge.png";
import google_play_ru from "$assets/branding/google-play/ru/badge.png";
import google_play_sv from "$assets/branding/google-play/sv/badge.png";
import google_play_tr from "$assets/branding/google-play/tr/badge.png";
import google_play_zh from "$assets/branding/google-play/zh/badge.png";

export const app_store = {
  da: {black: app_store_da_black, white: app_store_da_white},
  de: {black: app_store_de_black, white: app_store_de_white},
  en: {black: app_store_en_black, white: app_store_en_white},
  es: {black: app_store_es_black, white: app_store_es_white},
  eu: {black: app_store_eu_black, white: app_store_eu_white},
  fr: {black: app_store_fr_black, white: app_store_fr_white},
  it: {black: app_store_it_black, white: app_store_it_white},
  ja: {black: app_store_ja_black, white: app_store_ja_white},
  ko: {black: app_store_ko_black, white: app_store_ko_white},
  nl: {black: app_store_nl_black, white: app_store_nl_white},
  no: {black: app_store_no_black, white: app_store_no_white},
  pt: {black: app_store_pt_black, white: app_store_pt_white},
  ru: {black: app_store_ru_black, white: app_store_ru_white},
  sv: {black: app_store_sv_black, white: app_store_sv_white},
  tr: {black: app_store_tr_black, white: app_store_tr_white},
  zh: {black: app_store_zh_black, white: app_store_zh_white},
};
export type AppStoreKey = keyof typeof app_store;
export function is_app_store_key(language: string): language is AppStoreKey {
  return language in app_store;
}

export const mac_app_store = {
  da: {black: mac_app_store_da_black, white: mac_app_store_da_white},
  de: {black: mac_app_store_de_black, white: mac_app_store_de_white},
  en: {black: mac_app_store_en_black, white: mac_app_store_en_white},
  es: {black: mac_app_store_es_black, white: mac_app_store_es_white},
  eu: {black: mac_app_store_eu_black, white: mac_app_store_eu_white},
  fr: {black: mac_app_store_fr_black, white: mac_app_store_fr_white},
  it: {black: mac_app_store_it_black, white: mac_app_store_it_white},
  ja: {black: mac_app_store_ja_black, white: mac_app_store_ja_white},
  ko: {black: mac_app_store_ko_black, white: mac_app_store_ko_white},
  nl: {black: mac_app_store_nl_black, white: mac_app_store_nl_white},
  no: {black: mac_app_store_no_black, white: mac_app_store_no_white},
  pt: {black: mac_app_store_pt_black, white: mac_app_store_pt_white},
  ru: {black: mac_app_store_ru_black, white: mac_app_store_ru_white},
  sv: {black: mac_app_store_sv_black, white: mac_app_store_sv_white},
  tr: {black: mac_app_store_tr_black, white: mac_app_store_tr_white},
  zh: {black: mac_app_store_zh_black, white: mac_app_store_zh_white},
};
export type MacAppStoreKey = keyof typeof mac_app_store;
export function is_mac_app_store_key(language: string): language is MacAppStoreKey {
  return language in mac_app_store;
}

export const google_play = {
  ar: google_play_ar,
  da: google_play_da,
  de: google_play_de,
  en: google_play_en,
  es: google_play_es,
  eu: google_play_eu,
  fr: google_play_fr,
  hi: google_play_hi,
  it: google_play_it,
  ja: google_play_ja,
  ko: google_play_ko,
  nl: google_play_nl,
  no: google_play_no,
  pl: google_play_pl,
  pt: google_play_pt,
  ru: google_play_ru,
  sv: google_play_sv,
  tr: google_play_tr,
  zh: google_play_zh,
};
export type GooglePlayKey = keyof typeof google_play;
export function is_google_play_key(language: string): language is GooglePlayKey {
  return language in google_play;
}

export type AppStoreType = "app-store";
export type MacAppStoreType = "mac-app-store";
export type AppleStoreType = AppStoreType | MacAppStoreType;
export type GoogleStoreType = "google-play";
export type StoreType = GoogleStoreType | AppleStoreType;

export function get_store_image(store: StoreType, language?: string, white?: boolean): string {
  switch (store) {
    case "app-store":
      return (language && is_app_store_key(language) ? app_store[language] : app_store.en)[white ? "white" : "black"];
    case "mac-app-store":
      return (language && is_mac_app_store_key(language) ? mac_app_store[language] : mac_app_store.en)[
        white ? "white" : "black"
      ];
    case "google-play":
      return language && is_google_play_key(language) ? google_play[language] : google_play.en;
  }
}

export const default_badge_height = 60;
