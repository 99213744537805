<script context="module" lang="ts">
  import CustomHtml from "$lib/CustomHtml.svelte";
  import CustomText from "$lib/CustomText.svelte";
  import Errors from "$lib/Errors.svelte";
  import {_, Translate, TranslateParam} from "$lib/translate";
  import type {TranslateFn} from "$lib/translate";
  import type {Config, Test} from "./Verify.types";
  export * from "./Verify.types";

  interface Login {
    username: string | null;
    verification_code: string | null;
    error: string[] | string | null;
  }

  function init(config: Config) {
    const login: Login = {
      username: null,
      verification_code: null,
      error: null,
    };

    if (config.login) {
      if (config.login.username) {
        login.username = config.login.username;
      }
      if (config.login.verification_code) {
        login.verification_code = config.login.verification_code;
      }
    }

    return {login};
  }

  function config_update(config: Config, _: TranslateFn) {
    return {
      page_title: config.page.verify_title || _("Verify Account"),
      show_no_authentication: false, // TODO: REMOVE obsolete code ported from AngularJS
      verify_action_url:
        config.login && typeof config.login.username === "string" && config.login.username
          ? "verify?username=" + encodeURIComponent(config.login.username)
          : "verify",
    };
  }

  function login_update(login: Login) {
    return {
      enable_verify: typeof login.verification_code === "string" && login.verification_code !== "",
    };
  }
</script>

<script lang="ts">
  export let config: Config;
  export let test: Test | undefined = undefined;

  const {login} = init(config);

  $: ({page_title, show_no_authentication, verify_action_url} = config_update(config, $_));
  $: ({enable_verify} = login_update(login));
  $: hidden = test?.show_hidden_inputs ? "text" : "hidden";
</script>

<svelte:head>
  <title>{page_title}</title>
</svelte:head>

<div class="skin row limit-columns-1" id="cg-guest-verify">
  <div class="skin column">
    <div class="form-verify skin content">
      <form name="verify_form" method="POST" action={verify_action_url} class="skin controls narrow">
        {#if config.page.show_verify_title}
          <h2 class="form-verify-heading">{page_title}</h2>
        {/if}
        <input value={config.login.username_type} name="username_type" type={hidden} />

        {#if config.login.errors?.username?.length}
          <div>
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label class="control-label"><Translate>Username</Translate></label>
            <Errors errors={config.login.errors.username} id="verify_username_errors" />
            {#if config.page.register_url}
              <div>
                <p><Translate>You need to register for an account.</Translate></p>
                <div class="form-group">
                  <a href={config.page.register_url} class="btn btn-lg btn-primary btn-block">
                    <CustomText text={config.page.register_button_title}><Translate>Register</Translate></CustomText>
                    <span class="glyphicon glyphicon-chevron-right" />
                  </a>
                </div>
              </div>
            {/if}
          </div>
        {/if}

        {#if !config.login.errors?.username?.length}
          <div class="panel panel-info">
            <CustomHtml
              html={config.page.verify_account_message}
              class="panel-heading skin align"
              id="verify_account_message"
            >
              <Translate>Please enter the verification code that was sent to:</Translate>
            </CustomHtml>
            <div class="panel-body text-center">
              <strong>{login.username}</strong>
            </div>
          </div>
          <input value={login.username} name="username" type={hidden} />
        {/if}

        {#if !config.login.errors?.username?.length}
          <div class:has-error={!!config.login.errors?.verification_code?.length} class="form-group skin align">
            <label class="control-label" for="verification_code" id="verification_code_label">
              <CustomText text={config.page.verification_code_label}>
                <Translate>Verification code</Translate>
              </CustomText>
            </label>
            <Errors errors={config.login.errors?.verification_code} id="verify_verification_code_errors" />
            <!-- svelte-ignore a11y-autofocus -->
            <input
              bind:value={login.verification_code}
              placeholder={config.page.verification_code_placeholder || $_("Verification code")}
              id="verification_code"
              name="verification_code"
              type="text"
              class="form-control input-lg"
              required
              autocomplete="one-time-code"
              inputmode="numeric"
              autofocus
            />
          </div>
        {/if}

        {#if !config.login.errors?.username?.length}
          <div class="form-group">
            <button disabled={!enable_verify} class="btn btn-lg btn-primary btn-block" type="submit" id="verify_action">
              <CustomText text={config.page.verify_button_title}>
                <Translate>Verify</Translate>
              </CustomText>
            </button>
          </div>
        {/if}

        <div class="form-group">
          {#if config.page.register_url}
            <a href={config.page.register_url} class="btn btn-link" id="back_action">
              <span class="glyphicon glyphicon-chevron-left" />
              <span><CustomText text={config.page.back_button_title}><Translate>Back</Translate></CustomText></span>
            </a>
          {:else}
            <a href="login" class="btn btn-link" id="back_action">
              <span class="glyphicon glyphicon-chevron-left" />
              <span><CustomText text={config.page.back_button_title}><Translate>Back</Translate></CustomText></span>
            </a>
          {/if}
        </div>
      </form>

      <div class="skin controls narrow">
        {#if show_no_authentication}
          <div class="alert alert-danger" id="no_authentication">
            <strong><Translate>This page is currently disabled.</Translate></strong>
            <p><Translate>An administrator must enable authentication before this page can be used.</Translate></p>
          </div>
        {/if}
      </div>
    </div>
  </div>
</div>
