<script lang="ts">
  import {require_region_context} from "./context";

  let classes: string | undefined = undefined;
  export let tag: string | undefined = undefined;
  export {classes as class};

  const region = require_region_context();

  $: tag_name = tag || "div";

  function register(container: HTMLElement) {
    const deregister = region.register_container(container);
    return {
      destroy() {
        deregister();
      },
    };
  }
</script>

<svelte:element this={tag_name} class={classes} use:register>
  <slot />
</svelte:element>
