<script context="module" lang="ts">
  import CustomHtml from "$lib/CustomHtml.svelte";
  import {_, Translate, TranslateParam} from "$lib/translate";
  import type {Config, Test} from "./NetworkLogin.types";
  export * from "./NetworkLogin.types";
</script>

<script lang="ts">
  import {onDestroy} from "svelte";

  export let config: Config;
  export let test: Test | undefined = undefined;

  function submit_form(form: HTMLFormElement) {
    if (!test?.no_submit) {
      form.submit();
    }
    submitted = true;
  }

  function on_submit() {
    if (form) {
      clicked_login = true;
      clear_timer();
      submit_form(form);
    }
  }

  function start_timer(form: HTMLFormElement, seconds: number) {
    clear_timer();
    time_remaining = seconds;
    auto_login = true;
    Promise.resolve(time_remaining)
      .then(count_down)
      .then(() => {
        if (form) {
          submit_form(form);
        } else {
          throw "unexpected state: no form to submit";
        }
      })
      .finally(() => {
        time_remaining = 0;
        timeout_id = undefined;
      });
  }

  function clear_timer() {
    clear_timeout();
    time_remaining = 0;
    auto_login = false;
  }

  function clear_timeout() {
    if (timeout_id) {
      clearTimeout(timeout_id);
      timeout_id = undefined;
    }
  }

  function count_down(seconds: number): Promise<number> {
    time_remaining = seconds;
    if (seconds) {
      return new Promise<number>((resolve, reject) => {
        timeout_id = setTimeout(() => resolve(count_down(seconds - 1)), 1000);
      });
    } else {
      return Promise.resolve(0);
    }
  }

  // Initialise non-reactive variables
  let time_remaining = 0;
  let auto_login = false;
  let clicked_login = false;
  let submitted = false;
  let timeout_id: ReturnType<typeof setTimeout> | undefined = undefined;

  // Reactive statements
  $: trusted_action_url = config.network_login.action;
  $: page_title = config.page.network_login_title || $_("Signing in...");
  $: network_login_button_title = config.page.network_login_button_title || $_("Access Network");
  $: hidden = test?.show_hidden_inputs ? "text" : "hidden";
  $: if (form !== undefined && typeof config.network_login.auto_login_timeout === "number") {
    start_timer(form, config.network_login.auto_login_timeout);
  } else {
    clear_timer();
  }

  // Declare element bindings (valid from `onMount`)
  let form: HTMLFormElement | undefined = undefined;

  onDestroy(clear_timeout);
</script>

<svelte:head>
  <title>{page_title}</title>
</svelte:head>

<div class="skin row limit-columns-1" id="cg-guest-network-login">
  <div class="skin column">
    <div class="skin content">
      <form
        action={trusted_action_url}
        method={config.network_login.method}
        name="network_login_form"
        class="form-signin skin controls narrow"
        bind:this={form}
        on:submit|preventDefault={on_submit}
      >
        {#if config.page.show_network_login_title}
          <h2 class="form-signin-heading">
            {page_title}
          </h2>
        {/if}
        {#if submitted && test?.no_submit}
          <!-- test-only: do not translate -->
          <div class="alert alert-warning skin align" id="mock_form_submit">
            <strong>TEST:</strong> The form <strong>would</strong> have been submitted.
          </div>
        {/if}
        <CustomHtml
          html={config.page.network_login_message}
          class="alert alert-info skin align"
          id="network_login_message"
        >
          {#if auto_login && time_remaining > 0}
            <Translate>
              Signing in to the network in
              <TranslateParam name="number_of_seconds">
                <span class="label label-primary">{time_remaining}</span>
              </TranslateParam>
              seconds...
            </Translate>
          {:else if auto_login && time_remaining == 0}
            <Translate>Signing in to the network...</Translate>
          {:else}
            <Translate>
              Please click the
              <TranslateParam name="access_network">
                <b>{network_login_button_title}</b>
              </TranslateParam>
              button to complete your sign-in.
            </Translate>
          {/if}
        </CustomHtml>
        {#if auto_login && !clicked_login}
          <p class="text-muted skin align">
            <Translate>
              If you are not automatically signed in, click the
              <TranslateParam name="access_network">
                <b>{network_login_button_title}</b>
              </TranslateParam>
              button to complete your sign-in.
            </Translate>
          </p>
        {/if}
        <input type={hidden} value={config.network_login.username} name="user" />
        <input type={hidden} value={config.network_login.password} name="password" />
        <input type={hidden} value="authenticate" name="cmd" />
        <input type={hidden} value="Log In" name="Login" />
        <input type={hidden} value={config.network_login.continue_url} name="url" />
        <button
          type="submit"
          class="btn btn-lg btn-primary btn-block"
          name="portal_login_submit"
          value="Log In"
          id="login_action"
        >
          {network_login_button_title}
        </button>
      </form>
    </div>
  </div>
</div>
