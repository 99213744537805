<script context="module" lang="ts">
  import type {Config} from "./PreContent.types";
  import PlaceRegion from "$lib/place/PlaceRegion.svelte";
  import {redraw} from "$lib/redraw";

  interface PageSize {
    width: number;
    height: number;
  }

  interface ImageSize {
    width: number;
    natural_width: number;
    height: number;
    natural_height: number;
  }

  function get_page_size(): PageSize {
    return {
      width: document.body.clientWidth,
      height: document.body.clientHeight,
    };
  }

  function get_image_size(img: HTMLImageElement): ImageSize {
    return {
      width: img.width,
      natural_width: img.naturalWidth,
      height: img.height,
      natural_height: img.naturalHeight,
    };
  }

  function update(page_size: PageSize, img?: HTMLImageElement, triggers?: any) {
    let orientation = "unknown";

    if (img) {
      const size = get_image_size(img);
      const image_scale = size.natural_height > 0 ? size.natural_width / size.natural_height : null;
      const page_scale = page_size.height > 0 ? page_size.width / page_size.height : null;

      if (image_scale !== null && page_scale !== null) {
        orientation = image_scale >= page_scale ? "vertical" : "horizontal";
      }
    }

    return {horizontal: orientation === "horizontal", vertical: orientation === "vertical"};
  }
</script>

<script lang="ts">
  export let config: Config;

  let trigger = 0;
  let page_size = get_page_size();
  let img: HTMLImageElement | undefined = undefined;

  function update_size() {
    page_size = get_page_size();
  }

  function trigger_redraw(event: Event) {
    trigger = trigger + 1;
    redraw(event);
  }

  $: ({horizontal, vertical} = update(page_size, img, {trigger}));
</script>

<svelte:window on:resize={update_size} />

<PlaceRegion tag="div" class="skin pre-content" name="pre_content" self_contained={true} id="cg-guest-pre-content">
  {#if config.page.skin?.background_image_url}
    <!-- svelte-ignore a11y-missing-attribute -->
    <img
      bind:this={img}
      on:load={trigger_redraw}
      src={config.page.skin.background_image_url}
      class:horizontal
      class:vertical
      class="skin background"
      id="cg-guest-pre-content-background-image"
    />
  {/if}
</PlaceRegion>
