<script context="module" lang="ts">
  import type {BaseConfig} from "$lib/config/guest";
  import type {StoreType} from "./applinks/branding";

  // This component only requires a subset of the base portal configuration.
  export interface Config extends Pick<BaseConfig, "ua"> {}

  interface AppLink {
    store: StoreType | "macos-installer" | "windows-installer" | "ubuntu-installer";
    href: string;
    platforms: string[];
    rank: number;
    secondary: boolean;
    link_id: string;
    link_class?: string;
  }

  function update(
    config: Config,
    google_play?: string | null,
    app_store?: string | null,
    mac_app_store?: string | null,
    macos_installer?: string | null,
    windows_installer?: string | null,
    ubuntu_installer?: string | null
  ) {
    const app_links: AppLink[] = [];
    let primary: AppLink | null = null;
    let secondaries = false;

    if (google_play) {
      app_links.push({
        store: "google-play",
        href: google_play,
        platforms: ["Android", "ChromeOS"],
        rank: 101,
        secondary: false,
        link_id: "app_links_google_play_action",
      });
    }
    if (app_store) {
      app_links.push({
        store: "app-store",
        href: app_store,
        platforms: ["iOS"],
        rank: 102,
        secondary: false,
        link_id: "app_links_app_store_action",
      });
    }
    if (mac_app_store) {
      app_links.push({
        store: "mac-app-store",
        href: mac_app_store,
        platforms: ["Mac OS"],
        rank: 103,
        secondary: false,
        link_id: "app_links_mac_app_store_action",
      });
    }
    if (macos_installer) {
      app_links.push({
        store: "macos-installer",
        href: macos_installer,
        platforms: ["Mac OS"],
        rank: 104,
        secondary: false,
        link_id: "app_links_macos_installer_action",
        link_class: "btn btn-block btn-lg btn-primary",
      });
    }
    if (windows_installer) {
      app_links.push({
        store: "windows-installer",
        href: windows_installer,
        platforms: ["Windows"],
        rank: 105,
        secondary: false,
        link_id: "app_links_windows_installer_action",
        link_class: "btn btn-block btn-lg btn-primary",
      });
    }
    if (ubuntu_installer) {
      app_links.push({
        store: "ubuntu-installer",
        href: ubuntu_installer,
        platforms: ["Ubuntu"],
        rank: 106,
        secondary: false,
        link_id: "app_links_ubuntu_installer_action",
        link_class: "btn btn-block btn-lg btn-primary",
      });
    }

    if (config.ua) {
      app_links.forEach(function (app_link) {
        if (!primary && app_link.platforms.indexOf(config.ua.platform.name) !== -1) {
          app_link.rank = 0;
          primary = app_link;
        }
      });

      app_links.forEach(function (app_link) {
        if (!primary && config.ua.dist?.name && app_link.platforms.indexOf(config.ua.dist?.name) !== -1) {
          app_link.rank = 0;
          primary = app_link;
        } else if (
          !primary &&
          config.ua.platform.name &&
          config.ua.platform.name.toLowerCase() === "linux" &&
          app_link.platforms.indexOf("Ubuntu") !== -1
        ) {
          // Ubuntu is our best match for an unknown Linux distro.
          app_link.rank = 0;
          primary = app_link;
        }
      });

      app_links.forEach(function (app_link) {
        if (primary && app_link !== primary) {
          app_link.secondary = true;
          secondaries = true;
          if (primary.platforms.indexOf("Mac OS") !== -1 && app_link.platforms.indexOf("iOS") !== -1) {
            // Since Mac OS is primary, treat iOS as the next most
            // important "secondary" option.
            app_link.rank -= 50;
          } else if (primary.platforms.indexOf("iOS") !== -1 && app_link.platforms.indexOf("Mac OS") !== -1) {
            // Since iOS is primary, treat Mac OS as the next most
            // important "secondary" option.
            app_link.rank -= 50;
          }
        }
      });
    }

    app_links.sort((a, b) => a.rank - b.rank);

    return {secondaries, app_links};
  }
</script>

<script lang="ts">
  import {language as language_store, Translate} from "./translate";
  import AppStore from "./applinks/AppStore.svelte";
  import GooglePlay from "./applinks/GooglePlay.svelte";

  let language_override: string | undefined = undefined;

  export let config: Config;
  export {language_override as language};
  export let badge_height: number | undefined = undefined;
  export let google_play: string | undefined | null = undefined;
  export let app_store: string | undefined | null = undefined;
  export let mac_app_store: string | undefined | null = undefined;
  export let macos_installer: string | undefined | null = undefined;
  export let windows_installer: string | undefined | null = undefined;
  export let ubuntu_installer: string | undefined | null = undefined;

  let show_secondaries = false;
  $: ({secondaries, app_links} = update(
    config,
    google_play,
    app_store,
    mac_app_store,
    macos_installer,
    windows_installer,
    ubuntu_installer
  ));
  $: more_options = secondaries && !show_secondaries;
  $: language = language_override ? language_override : $language_store;
</script>

<div class="text-center">
  {#each app_links as app_link}
    {#if !app_link.secondary || show_secondaries}
      <a
        href={app_link.href}
        class={app_link.link_class}
        id={app_link.link_id}
        target="_blank"
        rel="noreferrer"
        style="text-decoration: none"
      >
        {#if app_link.store == "google-play"}
          <GooglePlay {language} {badge_height} />
        {:else if app_link.store == "app-store"}
          <AppStore {language} {badge_height} store="app-store" />
        {:else if app_link.store == "mac-app-store"}
          <AppStore {language} {badge_height} store="mac-app-store" />
        {:else if app_link.store == "macos-installer"}
          <span>
            <span class="glyphicon glyphicon-cloud-download" />
            <span><Translate>Download for macOS</Translate></span>
          </span>
        {:else if app_link.store == "windows-installer"}
          <span>
            <span class="glyphicon glyphicon-cloud-download" />
            <span><Translate>Download for Windows</Translate></span>
          </span>
        {:else if app_link.store == "ubuntu-installer"}
          <span>
            <span class="glyphicon glyphicon-cloud-download" />
            <span><Translate>Download for Ubuntu</Translate></span>
          </span>
        {/if}
      </a>
    {/if}
  {/each}
  {#if more_options}
    <button
      on:click={() => {
        show_secondaries = true;
      }}
      class="btn btn-link btn-block"
      id="app_links_more_options_action"
    >
      <Translate>More Options...</Translate>
    </button>
  {/if}
</div>
