<script context="module" lang="ts">
  import {_, Translate} from "$lib/translate";
  import type {Config} from "./FacebookWifiCheckin.types";
  export * from "./FacebookWifiCheckin.types";
</script>

<script lang="ts">
  export let config: Config;

  $: page_title = $_("Facebook WiFi");
</script>

<svelte:head>
  <title>{page_title}</title>
</svelte:head>

<div class="skin row limit-columns-1">
  <div class="skin column">
    <div class="form-signin skin content">
      <div class="skin controls narrow">
        <h2>{page_title}</h2>
        <p class="alert alert-info">
          <Translate>To use our free WiFi network, please log in using your Facebook account.</Translate>
        </p>
        <p>
          <a class="btn btn-primary btn-lg btn-block" href={config.fbwifi.checkin_url}>
            <span class="socialicon socialicon-md socialicon-circlefacebook" />
            <span><Translate>Login using Facebook</Translate></span>
          </a>
        </p>
      </div>
    </div>
  </div>
</div>
