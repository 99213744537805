<script context="module" lang="ts">
  import {createEventDispatcher} from "svelte";

  export type ChipType = "static" | "button";
  export interface Props {
    content?: string;
    type?: ChipType;
    noclose?: boolean;
  }
</script>

<script lang="ts">
  export let content: Props["content"] = undefined;
  export let type: Props["type"] = undefined;
  export let noclose: Props["noclose"] = undefined;

  const dispatch = createEventDispatcher<{close: void}>();

  function close(event: Event) {
    dispatch("close");
  }
</script>

<!--

IMPORTANT: The static/button and close elements are deliberately NOT indented as
white space between them affects presentation.

-->
<span class="chip" class:chip-closable={!noclose}>
  {#if type === "button"}<button type="button" class="btn btn-default chip-button" on:click>
      <slot name="content">
        {#if content}
          {content}
        {:else}
          &nbsp;
        {/if}
      </slot>
    </button>{:else}<span class="chip-static">
      <slot name="content">
        {#if content}
          {content}
        {:else}
          &nbsp;
        {/if}
      </slot>
    </span>{/if}{#if !noclose}<button type="button" class="btn btn-default chip-close" on:click={close}>
      <span class="text-muted">
        <slot name="close">&times;</slot>
      </span>
    </button>{/if}
</span>

<style>
  .chip {
    display: inline-flex;
  }
  .chip-static {
    padding: 6px 12px;
    color: #333;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .chip.chip-closable > button.btn.chip-button,
  .chip.chip-closable > .chip-static {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .chip > button.btn.chip-close {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left-width: 0px;
  }
</style>
