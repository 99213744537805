import {getContext, setContext} from "svelte";

import type {PlaceContext, RegionContext} from "./types";

const PLACE = Symbol("A symbolic key to uniquely identify the place context");
const REGION = Symbol("A symbolic key to uniquely identify the region context");

export function get_place_context(): PlaceContext | undefined {
  return getContext<PlaceContext>(PLACE);
}

export function require_place_context(): PlaceContext {
  const context = get_place_context();
  if (context) {
    return context;
  } else {
    throw "required place context is not available";
  }
}

export function set_place_context(context: PlaceContext): void {
  setContext(PLACE, context);
}

export function get_region_context(): RegionContext | undefined {
  return getContext<RegionContext>(REGION);
}

export function require_region_context(): RegionContext {
  const context = get_region_context();
  if (context) {
    return context;
  } else {
    throw "required region context is not available";
  }
}

export function set_region_context(context: RegionContext): void {
  setContext(REGION, context);
}
