import facebook from "$assets/social/facebook-128x128.png";
import facebook_white from "$assets/social/facebook-white-128x128.png";
import google from "$assets/social/google-128x128.png";
import google_white from "$assets/social/google-white-128x128.png";
import google_whitebg from "$assets/social/google-whitebg-128x128.png";
import linkedin from "$assets/social/linkedin-128x128.png";
import twitter from "$assets/social/twitter-128x128.png";
import twitter_white from "$assets/social/twitter-white-128x128.png";

export {facebook, facebook_white, google, google_white, google_whitebg, linkedin, twitter, twitter_white};

export const social = {
  light: {
    facebook,
    google,
    linkedin,
    twitter,
  },
  dark: {
    facebook: facebook_white,
    google: google_whitebg,
    linkedin,
    twitter: twitter_white,
  },
};
