<script context="module" lang="ts">
  import CustomHtml from "$lib/CustomHtml.svelte";
  import CustomText from "$lib/CustomText.svelte";
  import {_, Translate} from "$lib/translate";
  import type {TranslateFn} from "$lib/translate";
  import type {Config} from "./Terms.types";
  export * from "./Terms.types";

  // Reactive update function, as in: `$: ... update(config, ...)`
  function update(config: Config, _: TranslateFn) {
    return {
      page_title: config.page.terms_title || _("Terms and Conditions"),
      container_classes: "skin container max-columns-2", // DO_NOT_MERGE: How to set this on the container element?
    };
  }
</script>

<script lang="ts">
  export let config: Config;

  $: ({page_title, container_classes} = update(config, $_));
</script>

<svelte:head>
  <title>{page_title}</title>
</svelte:head>

<div class="skin row" id="cg-guest-terms">
  <div class="skin column wide">
    <div class="skin content">
      {#if config.page.back}
        <div>
          <a href={config.page.back} class="btn btn-link back-link back-link-top" id="back_top_action">
            <span class="glyphicon glyphicon-chevron-left" />
            <CustomText text={config.page.back_button_title}><Translate>Back</Translate></CustomText>
          </a>
        </div>
      {/if}
      {#if config.page.terms_message}
        <div class="terms-message">
          <h2 class="terms-message-title">{page_title}</h2>
          <CustomHtml html={config.page.terms_message} class="skin align terms-message-body" />
        </div>
      {/if}
      {#if config.page.back}
        <div>
          <a href={config.page.back} class="btn btn-link back-link back-link-bottom" id="back_action">
            <span class="glyphicon glyphicon-chevron-left" />
            <CustomText text={config.page.back_button_title}><Translate>Back</Translate></CustomText>
          </a>
        </div>
      {/if}
    </div>
  </div>
</div>
