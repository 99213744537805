<script context="module" lang="ts">
  import CustomHtml from "$lib/CustomHtml.svelte";
  import {_, Translate} from "$lib/translate";
  import type {Config} from "./Approved.types";
  export * from "./Approved.types";
</script>

<script lang="ts">
  export let config: Config;

  // TRANSLATORS: The title of the "approval request granted" page.
  let page_title = config.page.sponsor_approved_title || $_("Request approved");
</script>

<svelte:head>
  <title>{page_title}</title>
</svelte:head>

<div class="skin row limit-columns-1" id="cg-guest-approved">
  <div class="skin column">
    <div class="form-approved skin content">
      <div class="skin controls narrow">
        <h2 class="form-approved-heading">{page_title}</h2>
        <div class="panel panel-info">
          <CustomHtml
            html={config.page.sponsor_approved_message}
            class="panel-heading skin align"
            id="sponsor_approved_message"
          >
            <Translate>The request for access has been <strong>approved</strong>.</Translate>
            <Translate>You may now close this window.</Translate>
            <Translate>Access has been granted to:</Translate>
          </CustomHtml>
          <div class="panel-body text-center">
            <strong>{config.login.username}</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
