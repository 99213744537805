<script lang="ts">
  import {set_place_context} from "./context";
  import {PlaceContext} from "./types";

  const context = new PlaceContext();

  set_place_context(context);
</script>

<slot />
