import type {Config} from "$lib/config/guest";
import type {LocaleTranslations, AvailableLocales} from "$lib/translate";

export async function available_translations(config?: Config): Promise<AvailableLocales> {
  if (config) {
    return available_server_translations(config);
  } else {
    return await available_test_translations();
  }
}

function available_server_translations(config: Config): AvailableLocales {
  function translation_loader(code: string) {
    async function async_translation_loader(): Promise<LocaleTranslations> {
      const installed = config.language.install[code];
      if (installed) {
        return installed.strings;
      }

      const url = config.language.available[code].url;
      if (url) {
        const response = await fetch(url);
        if (!response.ok) {
          throw `failed to load translations: response not okay: status=${response.status} ${response.statusText} language_code=${code} url=${url}`;
        }
        // See the i18n.py for the format of the response data.
        type ResponseData = {language: string; language_name: string; strings: LocaleTranslations};
        const data: ResponseData = await response.json();
        if (data && data.strings) {
          return data.strings;
        } else {
          throw `invalid response from server for translations: no strings: language_code=${code} url=${url}`;
        }
      } else if (code === "en") {
        return {};
      } else {
        throw `unknown language code: ${code}`;
      }
    }

    return async_translation_loader;
  }

  const available: AvailableLocales = {en: {loader: translation_loader("en")}};
  for (const code in config.language.available) {
    available[code] = {loader: translation_loader(code)};
  }

  return available;
}

async function available_test_translations(): Promise<AvailableLocales> {
  const module = await import("$lib/translate/test");
  const TRANSLATIONS = module.TRANSLATIONS;

  function translation_loader(code: string) {
    async function async_translation_loader(): Promise<LocaleTranslations> {
      const locale: LocaleTranslations | undefined = TRANSLATIONS[code];
      if (locale) {
        return locale;
      } else if (code === "en") {
        return {};
      } else {
        return Promise.reject();
      }
    }

    return async_translation_loader;
  }

  const available: AvailableLocales = {en: {loader: translation_loader("en")}};
  for (const code in TRANSLATIONS) {
    available[code] = {loader: translation_loader(code)};
  }
  return available;
}

export function make_use_language_handler() {
  let remember_language_selection = false;

  function on_use_language(language: string, config?: Config): void {
    if (!config) {
      return;
    }

    if (!remember_language_selection && language != config.language.initial) {
      // Start remembering the user's language selection.
      remember_language_selection = true;
    }

    if (
      remember_language_selection &&
      config.language.available &&
      config.language.available[language] &&
      config.language.available[language].remember_url
    ) {
      fetch(config.language.available[language].remember_url)
        .then((response) => {
          if (!response.ok) {
            return Promise.reject(`${response.status} ${response.statusText}`);
          }
        })
        .then(
          () => {},
          (reason) => {
            console.log("failed to remember language selection:", reason);
          }
        );
    }
  }

  return on_use_language;
}
