<script lang="ts">
  let classes: string | undefined = undefined;
  export {classes as class};
  export let errors: string | string[] | null | undefined;
  export let id: string;
</script>

<div class={classes} {id}>
  {#if errors && errors.length > 0}
    <div class="alert alert-danger">
      <ul class="list-unstyled">
        {#if typeof errors === "string"}
          <li>{errors}</li>
        {:else}
          {#each errors as error}
            <li>{error}</li>
          {/each}
        {/if}
      </ul>
    </div>
  {/if}
</div>
