<script context="module" lang="ts">
  import type {Config} from "$lib/config/guest";
  import PlaceContext from "$lib/place/PlaceContext.svelte";
  import PlaceRegion from "$lib/place/PlaceRegion.svelte";
  import {available, language, active_language} from "$lib/translate";
  import Header from "./page/Header.svelte";
  import PostContent from "./page/PostContent.svelte";
  import PreContent from "./page/PreContent.svelte";
  import * as route from "./route";
  import Skin from "./page/Skin.svelte";
  import {available_translations, make_use_language_handler} from "./api";
  import "./globals";

  type DefaultProps = {
    [prop: string]: any;
  };

  type DefaultPropsFn = () => DefaultProps;

  interface Route {
    component: any;
  }

  async function default_props_for(route: string): Promise<DefaultProps> {
    // IMPORTANT: Use a **dynamic import** for test data as we do not want it
    // bloating production code.
    const test = await import("./route/test");

    const route_props: {[route: string]: DefaultPropsFn} = {
      "accept-terms": test.accept_terms.default_props,
      "after-network-login": test.access_allow.default_props,
      "access-denied": test.access_denied.default_props,
      "approval-granted": test.approval_granted.default_props,
      "approval-pending": test.approval_pending.default_props,
      approve: test.approve.default_props,
      approved: test.approved.default_props,
      "fbwifi-checkin": test.facebook_wifi_checkin.default_props,
      login: test.login.default_props,
      "network-login": test.network_login.default_props,
      passpoint: test.passpoint.default_props,
      "passpoint-profile": test.passpoint_profile.default_props,
      register: test.register.default_props,
      "self-service": test.self_service.default_props,
      terms: test.terms.default_props,
      "verified-login": test.verified_login.default_props,
      verify: test.verify.default_props,
      "verify-login": test.verify_login.default_props,
      "verify-pending": test.verify_pending.default_props,
      "wifi-password": test.wifi_password.default_props,
    };

    const default_props = route_props[route];
    if (default_props) {
      return default_props();
    } else {
      throw `cannot create default properties for unknown route: ${route}`;
    }
  }
</script>

<script lang="ts">
  let route_components: {[key: string]: Route} = {
    // IMPORTANT: If adding to this route mapping, you must add test properties
    // to the `default_props_for` function above.
    "accept-terms": {component: route.AcceptTerms},
    "after-network-login": {component: route.AccessAllow},
    "access-denied": {component: route.AccessDenied},
    "approval-granted": {component: route.ApprovalGranted},
    "approval-pending": {component: route.ApprovalPending},
    approve: {component: route.Approve},
    approved: {component: route.Approved},
    "fbwifi-checkin": {component: route.FacebookWifiCheckin},
    login: {component: route.Login},
    "network-login": {component: route.NetworkLogin},
    passpoint: {component: route.Passpoint},
    "passpoint-profile": {component: route.PasspointProfile},
    register: {component: route.Register},
    "self-service": {component: route.SelfService},
    terms: {component: route.Terms},
    "verified-login": {component: route.VerifiedLogin},
    verify: {component: route.Verify},
    "verify-login": {component: route.VerifyLogin},
    "verify-pending": {component: route.VerifyPending},
    "wifi-password": {component: route.WifiPassword},
  };
  let routes = Object.keys(route_components);
  routes.sort();
  let current_route: Route;
  let route_component: any = undefined;
  let current_path = "";
  let route_props: {config?: Config} = {};
  let open = false;

  const on_use_language = make_use_language_handler();

  const route_re = new RegExp("^(/portal/(?:scope|cnx)[^/]*/[^/]+/|.*/app/guest/)(.*)$");
  const route_match = route_re.exec(window.location.pathname);

  if (route_match && route_match[2] === "") {
    window.location.replace(
      `${window.location.origin}${window.location.pathname}login${window.location.search}${window.location.hash}`
    );
  } else if (route_match) {
    const route = route_match[2];
    current_route = route_components[route];
    route_component = current_route?.component;
    if (current_route) {
      if (window.portal_login_page_config) {
        route_props = {config: window.portal_login_page_config};
      } else if (current_route) {
        default_props_for(route).then(
          (props) => {
            route_props = props;
          },
          (reason) => {
            console.log(reason);
            route_props = {};
          }
        );
      }
    }
    current_path = route;
  }

  available_translations(window.portal_login_page_config).then(
    (available_locales) => {
      $available = available_locales;
      if (window.portal_login_page_config?.language.initial) {
        $language = window.portal_login_page_config.language.initial;
      }
    },
    (reason) => {
      console.log(reason);
    }
  );

  $: config = route_props["config"];
  $: max_columns = config?.page.skin?.max_columns ?? 1;
  $: on_use_language($active_language, config);
</script>

{#if config && !window.portal_login_page_config}
  <Skin {config} />
{/if}

<PlaceContext>
  {#if config}
    <Header {config} />
    <PreContent {config} />
    {#if config.page.skin?.wifi4eu_network_identifier}
      <!-- svelte-ignore a11y-missing-attribute -->
      <img id="wifi4eubanner" width="100%" />
    {/if}
  {/if}
  <main>
    {#if !window.portal_login_page_config}
      <nav class="navbar navbar-inverse navbar-static-top">
        <div class="container-fluid">
          <ul class="nav navbar-nav">
            <li class="dropdown open" class:open>
              <a
                on:click|preventDefault={() => {
                  open = !open;
                }}
                href="#toggle"
                class="dropdown-toggle"
                role="button"
                aria-haspopup="true"
                aria-expanded={open ? "true" : "false"}
              >
                {current_path} <span class="caret" />
              </a>

              <ul class="dropdown-menu">
                {#each routes as path}
                  <li class:active={current_path === path}><a href={path}>{path}</a></li>
                {/each}
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    {/if}
    {#if route_component}
      {#if config}
        <PlaceRegion
          tag="div"
          name="content"
          class={`skin container max-columns-${max_columns}`}
          self_contained={false}
        >
          <svelte:component this={route_component} {...route_props} />
        </PlaceRegion>
      {:else}
        <div class="container" />
      {/if}
    {:else}
      <div class="container">
        <h1>Unknown route</h1>
        <div class="alert alert-danger">Unknown route provided!</div>
        <p>Update the route components with the appropriate route handler.</p>
      </div>
    {/if}
  </main>
  <PostContent />
</PlaceContext>

<style>
  .navbar-static-top {
    z-index: unset;
  }
</style>
