<script context="module" lang="ts">
  import CustomHtml from "$lib/CustomHtml.svelte";
  import CustomText from "$lib/CustomText.svelte";
  import Errors from "$lib/Errors.svelte";
  import {_, Translate, TranslateParam} from "$lib/translate";
  import type {Config, Test} from "./Approve.types";
  export * from "./Approve.types";
</script>

<script lang="ts">
  export let config: Config;
  export let test: Test | undefined = undefined;

  // TRANSLATORS: The title of the "approval requested" page.
  let page_title = config.page.sponsor_approve_title || $_("Request for access");
  let hidden = test?.show_hidden_inputs ? "text" : "hidden";
</script>

<svelte:head>
  <title>{page_title}</title>
</svelte:head>

<div class="skin row limit-columns-1" id="cg-guest-approve">
  <div class="skin column">
    <div class="form-approve skin content">
      <form name="approve_form" method="POST" action="approve" class="skin controls narrow">
        <h2 class="form-approval-heading">{page_title}</h2>
        <input value={config.login.username} name="username" type={hidden} />
        <input value={config.login.token} name="token" type={hidden} />

        <Errors errors={config.login.errors.token} class="form-group" id="approve_token_errors" />

        {#if !config.login.errors.token?.length}
          <div class="panel panel-info">
            <CustomHtml
              html={config.page.sponsor_approve_message}
              class="panel-heading skin align"
              id="sponsor_approve_message"
            >
              <p>
                <Translate>
                  A request for access is currently <strong>waiting for your approval</strong>.
                </Translate>
              </p>
              <p>
                <Translate>
                  To <strong>approve</strong> this request, <strong>press</strong> the
                  <TranslateParam name="approve">
                    <strong>
                      <CustomText text={config.page.sponsor_approve_button_title}>
                        <Translate>Approve</Translate>
                      </CustomText>
                    </strong>
                  </TranslateParam>
                  button below.
                </Translate>
              </p>
              <p>
                <Translate>
                  If you <strong>do not approve</strong> this request, you can ignore it and close this window.
                </Translate>
              </p>
              <p>
                <Translate>The user requesting access is:</Translate>
              </p>
            </CustomHtml>
            <div class="panel-body text-center">
              <strong>{config.login.username}</strong>
            </div>
          </div>

          <div class="form-group">
            <button type="submit" class="btn btn-lg btn-primary btn-block btn-approve" id="approve_action">
              <CustomText text={config.page.sponsor_approve_button_title}>
                <Translate>Approve</Translate>
              </CustomText>
            </button>
          </div>
        {/if}
      </form>
    </div>
  </div>
</div>
