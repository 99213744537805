<script context="module" lang="ts">
  import type {Config} from "./AccessAllow.types";
  export * from "./AccessAllow.types";
</script>

<script lang="ts">
  import {_, Translate} from "$lib/translate";
  import CustomHtml from "$lib/CustomHtml.svelte";

  export let config: Config;

  $: page_title = config.page.logged_in_title || $_("Logged in");
</script>

<svelte:head>
  <title>{page_title}</title>
</svelte:head>

<div class="skin row limit-columns-1" id="cg-guest-access-allow">
  <div class="skin column">
    <div class="form-signin skin content">
      <div class="skin controls narrow">
        <h2 class="form-signin-heading">{page_title}</h2>
        <CustomHtml html={config.page.logged_in_message} class="skin align">
          <p><Translate>You are now logged into the network.</Translate></p>
        </CustomHtml>
      </div>
    </div>
  </div>
</div>
