<script context="module" lang="ts">
  import CustomHtml from "$lib/CustomHtml.svelte";
  import CustomText from "$lib/CustomText.svelte";
  import InjectHref from "$lib/InjectHref.svelte";
  import MoreBox from "$lib/MoreBox.svelte";
  import UntrustedHtml from "$lib/UntrustedHtml.svelte";
  import {_, Translate} from "$lib/translate";
  import type {TranslateFn} from "$lib/translate";
  import Errors from "$lib/Errors.svelte";
  import Phone from "$lib/Phone.svelte";
  import type {Config, Test} from "./Register.types";
  export * from "./Register.types";

  interface Login {
    last: {email: string | null; phone: string | null; [username_type: string]: string | null};
    username_type: string | null;
    username: string | null;
    sponsor: string | null;
    accept_terms: boolean | null;
    error: string | null;
  }

  function init(config: Config, _: TranslateFn) {
    const {multiple_username_types} = config_update(config, _);
    const login: Login = {
      last: {email: null, phone: null},
      username_type: null,
      username: null,
      sponsor: null,
      accept_terms: null,
      error: null,
    };

    if (config.login) {
      if (config.login.username_type) {
        login.username_type = config.login.username_type;
      }

      if (config.login.username) {
        login.username = config.login.username;
      }

      if (config.login.sponsor) {
        login.sponsor = config.login.sponsor;
      }

      if (config.login.accept_terms) {
        login.accept_terms = true;
      }
    }

    if (!multiple_username_types) {
      if (config.page.register_accept_email) {
        login.username_type = "email";
      }

      if (config.page.register_accept_phone) {
        login.username_type = "phone";
      }
    }

    return {login, last_username_type: login.username_type};
  }

  function config_update(config: Config, _: TranslateFn) {
    // Derived function block scope.

    function email_address_placeholder(default_text: string) {
      return config.page.email_address_placeholder || default_text;
    }

    function phone_number_placeholder(default_text: string) {
      return config.page.phone_number_placeholder || default_text;
    }

    function sponsor_placeholder(default_text: string) {
      return config.page.sponsor_placeholder || default_text;
    }

    // Derived variable block scope.
    {
      // TRANSLATORS: The title of the "register" page.
      const page_title = config.page.register_title || _("Register");
      const hidden_values: {name: string; value: string}[] = []; // TODO: REMOVE: obsolete binding from AngularJS
      const num_username_types =
        0 + (config.page.register_accept_email ? 1 : 0) + (config.page.register_accept_phone ? 1 : 0);

      return {
        page_title,
        hidden_values,
        show_accept_terms: config.page.require_accept_terms,
        num_username_types,
        multiple_username_types: num_username_types > 1,
        show_no_authentication: false, // TODO: REMOVE: Obsolete binding from AngularJS
        email_address_placeholder,
        sponsor_placeholder,
      };
    }
  }

  function login_update(
    login: Login,
    register_form: HTMLFormElement | undefined,
    {show_accept_terms}: {show_accept_terms: boolean}
  ) {
    const accept_terms_ok = !show_accept_terms || login.accept_terms;
    // TODO - Implement AngularJS equivalent of form valid/invalid.
    //
    // !register_form.$invalid && accept_terms_ok() && login.username_type !== null;
    const enable_register = accept_terms_ok && login.username_type !== null;

    return {enable_register};
  }
</script>

<script lang="ts">
  export let config: Config;
  export let test: Test | undefined = undefined;

  let {login, last_username_type} = init(config, $_);

  $: ({
    page_title,
    hidden_values,
    email_address_placeholder,
    sponsor_placeholder,
    show_accept_terms,
    num_username_types,
    multiple_username_types,
    show_no_authentication,
  } = config_update(config, $_));
  $: ({enable_register} = login_update(login, register_form, {show_accept_terms}));
  $: if (login.username_type !== last_username_type) {
    if (last_username_type) {
      login.last[last_username_type] = login.username;
    }
    if (login.username_type) {
      login.username = login.last[login.username_type];
    }
    last_username_type = login.username_type;
  }
  $: hidden = test?.show_hidden_inputs ? "text" : "hidden";

  let register_form: HTMLFormElement | undefined = undefined;
</script>

<svelte:head>
  <title>{page_title}</title>
</svelte:head>

<div
  class:limit-columns-1={!config.page.show_register_title &&
    !(config.page.terms_message && config.page.terms_location == "internal")}
  class="skin row"
  id="cg-guest-register"
>
  {#if config.page.show_register_title || (config.page.terms_message && config.page.terms_location == "internal")}
    <div class="skin column">
      <div class="form-register skin content">
        {#if config.page.show_register_title}
          <h2 class="form-register-heading">{page_title}</h2>
        {/if}
        {#if config.page.terms_message && config.page.terms_location == "internal"}
          <MoreBox class="terms-message">
            <div class="skin align terms-message-body">
              <UntrustedHtml html={config.page.terms_message} />
            </div>
          </MoreBox>
        {/if}
      </div>
    </div>
  {/if}
  <div class="skin column">
    <div class="form-register skin content">
      <form bind:this={register_form} name="register_form" method="POST" action="register" class="skin controls narrow">
        {#each hidden_values as hidden_value}
          <input value={hidden_value.value} name={hidden_value.name} type={hidden} />
        {/each}

        <div class="form-group skin align">
          {#if config.page.register_message}
            <CustomHtml html={config.page.register_message} class="skin align" id="register_message" />
          {/if}

          <!-- svelte-ignore a11y-label-has-associated-control -->
          <label id="username_label">
            <CustomText text={config.page.username_label}><Translate>Username</Translate></CustomText>
          </label>
          <div>
            {#if multiple_username_types}
              <div class="radio-inline">
                <label for="register_email_input" id="email_label">
                  <input
                    bind:group={login.username_type}
                    name="username_type"
                    type="radio"
                    id="register_email_input"
                    value="email"
                  />
                  <span class="glyphicon glyphicon-envelope" />
                  <span><CustomText text={config.page.email_label}><Translate>Email</Translate></CustomText></span>
                </label>
              </div>

              <div class="radio-inline">
                <label for="register_phone_input" id="phone_label">
                  <input
                    bind:group={login.username_type}
                    name="username_type"
                    type="radio"
                    id="register_phone_input"
                    value="phone"
                  />
                  <span class="glyphicon glyphicon-phone" />
                  <span><CustomText text={config.page.phone_label}><Translate>Phone</Translate></CustomText></span>
                </label>
              </div>
            {/if}
          </div>

          {#if !multiple_username_types}
            <input name="username_type" value={login.username_type} type={hidden} />
          {/if}

          {#if num_username_types === 0}
            <div class="alert alert-info">
              <Translate>Registration is missing user registration type.</Translate>
            </div>
          {/if}

          <Errors errors={config.login.errors?.general} class="skin align" id="register_general_errors" />

          {#if !config.page.register_message && !login.username_type && num_username_types !== 0}
            <div class="alert alert-info skin align">
              <Translate>Register using your email address or phone number.</Translate>
            </div>
          {/if}

          {#if login.username_type == "email"}
            <!-- Email address -->
            <div
              class:has-error={(config.login.errors?.username || []).length > 0 &&
                config.login.username_type == "email"}
            >
              {#if config.login.username_type == "email"}
                <Errors errors={config.login.errors?.username} id="register_username_errors" />
              {/if}
              <input
                bind:value={login.username}
                placeholder={email_address_placeholder($_("Email address"))}
                id="email_input"
                name="username"
                type="text"
                inputmode="email"
                class="form-control"
                required
              />
            </div>
          {/if}

          {#if login.username_type == "phone"}
            <!-- Phone number -->
            <div
              class:has-error={(config.login.errors?.username || []).length > 0 &&
                config.login.username_type == "phone"}
            >
              {#if config.login.username_type == "phone"}
                <Errors errors={config.login.errors?.username} id="register_username_errors" />
              {/if}
              <input value={login.username} name="username" type={hidden} />
              <Phone
                bind:international_number={login.username}
                initial_country={config.page.country_code}
                initial_country_url={config.page.country_code_url}
                placeholder={config.page.phone_number_placeholder}
                id="phone_input"
                class="form-control"
                required={true}
              />
              <CustomHtml html={config.page.phone_help_message} id="phone_help_message" />
            </div>
          {/if}
        </div>

        {#if config.page.require_sponsor_approval}
          <!-- Sponsor email address -->
          <div class:has-error={(config.login.errors?.sponsor || []).length > 0} class="form-group skin align">
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label id="sponsor_label">
              <CustomText text={config.page.sponsor_label}><Translate>Sponsor</Translate></CustomText>
            </label>
            <CustomHtml html={config.page.sponsor_message} id="sponsor_message">
              <p>
                <Translate>
                  Enter the email address of an authorized sponsor who will approve your registration:
                </Translate>
              </p>
            </CustomHtml>
            <Errors errors={config.login.errors?.sponsor} id="register_sponsor_errors" />
            <input
              bind:value={login.sponsor}
              placeholder={sponsor_placeholder($_("Sponsor email address"))}
              id="sponsor_input"
              name="sponsor"
              type="text"
              inputmode="email"
              class="form-control"
              required
            />
          </div>
        {/if}

        {#if !show_accept_terms && config.page.terms_location == "external"}
          <div class:has-error={(config.login.errors?.accept_terms || []).length > 0} class="form-group">
            <InjectHref href={config.page.terms_url} class="terms-link">
              <CustomHtml html={config.page.terms_accept_message}>
                <!-- svelte-ignore a11y-missing-attribute (href will be injected) -->
                <a><Translate>Terms and conditions</Translate></a>
              </CustomHtml>
            </InjectHref>
          </div>
        {/if}

        {#if show_accept_terms}
          <div class="checkbox form-group">
            <label for="accept_terms_input">
              <input
                bind:checked={login.accept_terms}
                name="accept_terms"
                value="true"
                type="checkbox"
                id="accept_terms_input"
              />
              <InjectHref href={config.page.terms_url} class="terms-link">
                <CustomHtml html={config.page.terms_accept_message}>
                  <!-- svelte-ignore a11y-missing-attribute (href will be injected) -->
                  <Translate>I accept the <a>terms and conditions</a></Translate>
                </CustomHtml>
              </InjectHref>
            </label>
          </div>
        {/if}

        <Errors errors={config.login.errors?.accept_terms} id="register_accept_terms_errors" />

        <div class="form-group">
          {#if !config.page.require_sponsor_approval && config.page.verify_registration && login.username_type && login.username}
            <div class="panel panel-info">
              <div class="panel-heading skin align">
                {#if login.username_type == "email"}
                  <CustomHtml html={config.page.verification_email_notice_message}>
                    <Translate>
                      A <strong>verification code</strong> will be sent to your email address:
                    </Translate>
                  </CustomHtml>
                {/if}
                {#if login.username_type == "phone"}
                  <CustomHtml html={config.page.verification_phone_notice_message}>
                    <Translate>
                      A <strong>verification code</strong> will be sent to your phone:
                    </Translate>
                  </CustomHtml>
                {/if}
              </div>
              <div class="panel-body text-center"><strong>{login.username}</strong></div>
            </div>
          {/if}
          <button
            disabled={!enable_register}
            class="btn btn-lg btn-primary btn-block btn-register"
            type="submit"
            id="register_action"
          >
            <CustomText text={config.page.register_button_title}>
              <Translate>Register</Translate>
            </CustomText>
          </button>
        </div>

        <div>
          <a href="login" class="btn btn-link back-link back-link-bottom" id="back_action">
            <span class="glyphicon glyphicon-chevron-left" />
            <span><CustomText text={config.page.back_button_title}><Translate>Back</Translate></CustomText></span>
          </a>
        </div>
      </form>

      <div class="skin controls narrow">
        {#if show_no_authentication}
          <div class="alert alert-danger" id="no_authentication">
            <strong><Translate>This page is currently disabled.</Translate></strong>
            <p><Translate>An administrator must enable authentication before this page can be used.</Translate></p>
          </div>
        {/if}
      </div>
    </div>
  </div>
</div>
