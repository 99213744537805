<script context="module" lang="ts">
  import {_, Translate} from "$lib/translate";
  import type {TranslateFn} from "$lib/translate";
  import {cg_session} from "$lib/session";
  import {targets} from "./SelfService.types";
  import type {ApiMethod, ApiResponse, Config, Target, Test} from "./SelfService.types";
  import CredentialsList from "./credentials/CredentialsList.svelte";
  import DevicesList from "./devices/DevicesList.svelte";
  export * from "./SelfService.types";

  function update(config: Config, _: TranslateFn, test?: Test) {
    async function cg_self_service_api<T = any, U = any>(
      method: ApiMethod,
      url: string,
      params?: {[name: string]: string | number},
      data?: U
    ): Promise<ApiResponse<T>> {
      if (test?.api) {
        return await test.api<T>(method, url, params, data);
      }

      const api_key = cg_session<string>("api_key");
      const options: RequestInit = {};
      const headers: Required<RequestInit>["headers"] = {};
      if (api_key) {
        headers["X-Vesta-API-Key"] = api_key;
      }
      if (data) {
        headers["Content-Type"] = "application/json";
        options.body = JSON.stringify(data);
      }

      if (url.indexOf("/api") !== 0) {
        url = config.self_service.base_url + url;
      }

      const request_url = new URL(url, window.location.href);

      if (params) {
        for (const [name, value] of Object.entries(params)) {
          request_url.searchParams.append(name, `${value}`);
        }
      }

      const response = await fetch(request_url, {method, headers, ...options});

      return {
        status: response.status,
        data: response.status === 204 ? undefined : ((await response.json()) as T),
      };
    }

    {
      let initial_target =
        config.self_service.initial_target && targets.indexOf(config.self_service.initial_target) !== -1
          ? config.self_service.initial_target
          : targets[0];

      if (initial_target === "devices" && !config.self_service.with_devices_and_sharing) {
        initial_target = "credentials";
      }

      return {
        page_title: _("Self-Service"),
        with_devices_and_sharing: !!config.self_service.with_devices_and_sharing,
        initial_target,
        cg_self_service_api,
      };
    }
  }
</script>

<script lang="ts">
  export let config: Config;
  export let test: Test | undefined = undefined;

  let target: Target | undefined = undefined;
  let last_initial_target: Target | undefined = undefined;

  $: ({page_title, with_devices_and_sharing, initial_target, cg_self_service_api} = update(config, $_, test));
  $: if (initial_target !== last_initial_target) {
    target = last_initial_target = initial_target;
  }
</script>

<svelte:head>
  <title>{page_title}</title>
</svelte:head>

<div class="skin row limit-columns-1" id="cg-guest-self-service">
  <div class="skin column">
    <div class="skin content">
      <h2 class="form-signin-heading" id="self_service_title">{page_title}</h2>

      {#if with_devices_and_sharing}
        <div>
          <ul class="nav nav-tabs">
            <li class:active={target === "credentials"}>
              <a
                href="#credentials"
                on:click|preventDefault={() => {
                  target = "credentials";
                }}
              >
                <span class="glyphicon glyphicon-certificate" />
                <span class="visible-xs-inline"><Translate>Credentials</Translate></span>
                <span class="hidden-xs"><Translate>Network Credentials</Translate></span>
              </a>
            </li>
            <li class:active={target === "devices"}>
              <a
                href="#devices"
                on:click|preventDefault={() => {
                  target = "devices";
                }}
              >
                <span class="glyphicon glyphicon-phone" />
                <span class="visible-xs-inline"><Translate>Devices</Translate></span>
                <span class="hidden-xs"><Translate>Devices &amp; Sharing</Translate></span>
              </a>
            </li>
          </ul>
        </div>
      {/if}

      {#if !target || target === "credentials"}
        <CredentialsList {cg_self_service_api} />
      {:else if target === "devices"}
        <DevicesList {config} {cg_self_service_api} />
      {/if}
    </div>
  </div>
</div>
