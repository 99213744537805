<script context="module" lang="ts">
  import {stringify_style} from "$lib/style";
  import type {TranslateFn} from "$lib/translate";
  import {default_badge_height, get_store_image} from "./branding";
  import type {AppleStoreType} from "./branding";

  function update(_: TranslateFn, language: string, store: AppleStoreType, badge_height?: number) {
    // badge_height = badge_height ?? default_badge_height;

    // App Store badges need to be padded with a quarter height padding so
    // that they appear to be the same size (height) as other app stores
    // (i.e. as Google Play).
    return {
      image_height: Math.ceil(1.5 * Number(badge_height ?? default_badge_height)),
      image_path: get_store_image(store, language),
      alt: _("Download on the {app_store}").replace("{app_store}", "App Store"),
      style: stringify_style({
        padding: Math.ceil(0.25 * Number(badge_height ?? default_badge_height)) + "px",
      }),
    };
  }
</script>

<script lang="ts">
  import {_} from "$lib/translate";

  export let language: string;
  export let store: AppleStoreType;
  export let badge_height: number | undefined = undefined;

  // Get reactive state
  // $: ({image_height, image_path, alt, style} = update(config, language, store, badge_height));
  $: ({image_height, image_path, alt, style} = update($_, language, store, badge_height));
</script>

<span>
  <img src={image_path} {alt} height={image_height} {style} />
</span>
