<script context="module" lang="ts">
  import {afterUpdate} from "svelte";

  function update_anchor(anchor: HTMLAnchorElement, href: string, class_name?: string) {
    anchor.href = href || "";

    const classes = (class_name || "").split(" ");
    for (const cls of classes) {
      if (cls) {
        anchor.classList.add(cls);
      }
    }
  }

  function update_element(element: Element, href: string, class_name?: string) {
    let num_anchors = 0;

    if (element instanceof HTMLAnchorElement) {
      if (element.classList.contains("cg-inject-href-wrapped")) {
        // Classes are injected into the wrap anchor by Svelte. No need to try
        // here. Also, do not count this anchor as one from slot content.
        update_anchor(element, href);
      } else {
        update_anchor(element, href, class_name);
        num_anchors++;
      }
    }

    for (const anchor of element.getElementsByTagName("a")) {
      update_anchor(anchor, href, class_name);
      num_anchors++;
    }

    return num_anchors;
  }

  function update(href: string, class_name?: string, first?: HTMLElement, last?: HTMLElement): boolean | undefined {
    if (!first || !last) {
      return undefined;
    }

    let num_anchors = 0;
    for (let next: Element | null = first.nextElementSibling; next && next !== last; next = next.nextElementSibling) {
      num_anchors += update_element(next, href, class_name);
    }

    return num_anchors > 0 ? false : true;
  }
</script>

<script lang="ts">
  // Classes to add to each anchor element.
  let class_name: string | undefined = undefined;
  export let href: string;
  export {class_name as class};

  $: wrap = update(href, class_name, first, last);

  let first: HTMLElement | undefined = undefined;
  let last: HTMLElement | undefined = undefined;

  afterUpdate(() => {
    wrap = update(href, class_name, first, last);
  });
</script>

<span bind:this={first} class="cg-inject-href-first" />
{#if wrap}
  <!-- svelte-ignore a11y-missing-attribute -->
  <a {href} class="cg-inject-href-wrapped {class_name || ''}">
    <slot />
  </a>
{:else}
  <slot />
{/if}
<span bind:this={last} class="cg-inject-href-last" />

<style>
  .cg-inject-href-first,
  .cg-inject-href-last {
    display: none;
  }
</style>
