<!--
@component A text search UI control with which users can select matching items,
or provide new values.

## Examples

### Selecting from a database of users

> ```svelte
> <Search
>   search={search_users}
>   key={user_key}
>   accept="item"
>   on:select={on_selected}
>   on:error={on_error}
> >
>  <svelte:fragment slot="accept">
>    <span class="glyphicon glyphicon-plus" />
>  </svelte:fragment>
>  <svelte:fragment slot="invalid">
>    <span class="glyphicon glyphicon-ban-circle" />
>  </svelte:fragment>
>   <svelte:fragment slot="match" let:item let:active>
>     <div><strong>{item.display_name}</strong></div>
>     <div><small>{item.username}</small></div>
>   <svelte:fragment/>
>   <svelte:fragment slot="next">
>     Load more users...
>   <svelte:fragment/>
> </Search>
> ```


### Selecting/creating tags

> ```svelte
> <Search search={search_tag_names} on:select={on_selected} />
> ```


## Features

* Aribtrary `search` function returning matching items, and optional `prev`
  and/or `next` _cursors_.
* Cursors can be configured to `append` matched items (i.e. load more), or
  `replace` the current matched items (i.e. _next page_/_previous page_).
* Optional `delay` to _debounce_ search requests on user input.
* Automatic `drop` direction detection (can be overridden to `downup`, `updown`,
  `down` or `up`).
* Option to `accept` matched items only, input value only, or both.
* Configurable `name`, `id`, `placeholder`, `inputmode`, `enterkeyhint`, and
  `disabled` input attributes.
* Optional input auto-clear on user selection (see `autoclear` property).
* Optional list auto-close on user selection (see `noautoclose` property).
* Optional activity indicator (see `noindicator` property).


## Properties

@param search - A **required** search function that must return a
`Promise<SearchResult<T>>`.

The resolved `SearchResult<T>` contains an array of matched `items`, and
optional `prev` and `next` cursor functions, where the search supports them.

> ```ts
> async function search(value: string): Promise<SearchResult<MyItem>> {
>   return {
>     items: [...],
>     prev: () => {...},
>     next: () => {...},
>   }
> }
> ```

The `next` and `prev` cursor functions must also return a
`Promise<SearchResult<T>>` with the resolved result also containing an array of
`items`, and optional `prev` and `next` cursor functions, where supported.

> ```ts
> async function prev_or_next(): Promise<SearchResult<MyItem>> {
>   return {
>     items: [...],
>     prev: () => {...},
>     next: () => {...},
>   }
> }
> ```


@param key - A _conditionally_ required/optional **key** function that **must
return a UNIQUE KEY string value** for a given item.

> ```ts
> function key(item: MyItem): string {
>   return item.username  
> }
> ```

The default `key` function simply formats the item as a string.

**IMPORTANT:** For any `search` function that returns `object` items, this
property should be considered **required**.


@param keycase - An optional case modification to apply to the `value` and item
keys returned by `key`. This can be used to ensure correct key comparison for
items, and to normalize values.

* `"sensitive"` (default) - use case sensitive key comparison, and do not change
  the case of the `value` or the `key` of an item.
* `"insensitive"` - use case insensitive key comparison, and do not change the
  case of the `value` or the `key` of an item.
* `"lower"` - use lower case for key comparison, and change the case of the
  `value` or the `key` of an item to lower case.
* `"upper"` - use upper case for key comparison, and change the case of the
  `value` or the `key` of an item to upper case.


@param value - An optional value to present in the search `<input>` control.

This value updates whenever the user types in the `<input>` control.

Updating the `value` property programmatically **does not** initiate a `search`.

**NOTE:** Depending on the use case, it may be useful to use a `bind:value` to
monitor changes.

See also the _events_ section for supported component events.


@param delay - An optional value (milliseconds) to delay after user input,
before starting a `search`.

This property can be used to _debounce_ user input. If set to a number of zero
or greater, then the search will only initiate once the user has stopped typing
for the specified debounce delay duration.


@param cursor - An optional cursor mode that determines if list of matched items
is paged or appended when a `prev`/`next` cursor function is used, or if cursors
are to be ignored entirely.

* `"append"` (default) - `next` cursor items will be _appended_ to the current
  matched items. The `prev` cursor is **not** supported in this mode.
* `"replace"` - `next` and `prev` cursor items will _replace_ the current
  matched items, implementing a form of paging.
* `"none"` - the `next` and `prev` cursors returned by `search`, or prior
  `next`/`prev` cursors, will be ignored.


@param drop - An optional drop direction that determines whether the list of
matched items is shown above or below the input control.

* `"auto"` (default) - the control will try to detect which direction is best to
  use.
* `"downup"` - prefer to position the list below the `<input>` control.
* `"updown"` - prefer to position the list above the `<input>` control.
* `"down"` - always position the list below the `<input>` control.
* `"up"` - always position the list above the `<input>` control.


@param accept - An optional accept value, or array of values, that determines
whether `select` events (see _events_ below) will provide a matched `item`, an
entered `value`, both, or neither.

* `undefined` (default) - the search control will emit `select` events with
  either `ItemSelection<T>` or `ValueSelection` detail.
* `"item"` - the search control will only emit `select` events with
  `ItemSelection<T>` detail.
* `"value"` - the search control will only emit `select` events with
  `ValueSelection` detail.
* `[...]` - the search control will only emit `select` events for the types
  listed in the array (`"item"` and/or `"value"`). An empty array indicates
  _none_.


@param autoclear - An optional boolean that determines whether the input control
will be automatically cleared after dispatch of a `select` event. The default is
`false` (do not automatically clear).

**NOTE:** If the `select` event is _cancelled_, via `event.preventDefault()`,
the input control will not be automatically cleared.


@param noautoclose - An optional boolean that determines whether the list of
matched items will be automatically closed after dispatch of a `select` event.
The default is `false` (automatically close).

**NOTE:** If the `select` event is _cancelled_, via `event.preventDefault()`,
the list of matched items will not be automatically closed.


@param noindicator - An optional boolean that determines whether an activity
indicator will be shown while waiting for `search` (and `next`/`prev` cursor)
functions to complete (i.e. for the returned promises to resolve/reject). The
default is `false` (show the activity indicator).


@param name - An optional `name` attribute to apply to the search `<input>`
control.


@param id - An optional `id` attribute to apply to the search `<input>` control.


@param placeholder - An optional `placeholder` attribute to apply to the search
`<input>` control.


@param inputmode - An optional standard `inputmode` attribute to apply to the
`<input>` control.


@param enterkeyhint - An optional standard `enterkeyhint` attribute to apply to
the `<input>` control.


@param disabled - An optional standard `disabled` attribute to apply to the
`<input>` control.


## Events

The search control supports the following events:

* `select` - the user has selected an _item_ from the list, or an arbitrary
  _value_ with the input control (by pressing `ENTER`).
* `error` - a `search`, or `next`/`prev` cursor function failed.


### select event

If the `accept` property (see above) allows for `"item"` selection, the `select`
event will be dispatched when the user selects an item from the list of matched
items.

If the `accept` property (see above) allows for `"value"` selection, and if the
`value` property is not empty, the `select` event will be dispatched when the
user presses `ENTER` in the `<input>` control.

The `event.detail` will contain either an `ItemSelection<T>` or
`ValueSelection`, accordingly.

These types are exported by the search control.

> ```ts
> export interface ItemSelection<T = any> {
>   type: "item";
>   item: T;
> }
> export interface ValueSelection {
>   type: "value";
>   value: string;
> }
> export type Selection<T = any> = ItemSelection<T> | ValueSelection;
> ```

**NOTE:** The `select` event may be _cancelled_, via `event.preventDefault()`.
If _cancelled_, auto-clear and auto-close will not be applied.


### error event

The search control will dispatch an `error` event when a `search`, or
`next`/`prev` cursor function fails. That is, when the returned promise is
_rejected_.

The `event.detail` will contain the `reason` that was passed to the promise's
_reject_ handler.


## Slots

The search control provides a number of _slots_ for altering presentation of the
list of matched items.

* `"match"` - the content to display for a given matched item. Use `let:item` to
  bind to the matched item. Use `let:active` to bind to the list item _active_
  state (`true` when the entered `value` matches the `key` of the matched item).
* `"prev"` - the content to display when presenting a control to fetch the
  _previous page_ of matched items.
* `"next"` - the content to display when presenting a control to fetch the _next
  page_ of matched items (whether operating in `append` or `replace` cursor
  mode).

For correct presentation of the dropdown/dropup items, these slots are located
within outer `<a>` elements. If it necessary to replace these default outer
`<a>` elements, you can use any of the `"match-outer"`, `"prev-outer"`, and
`"next-outer"` slots instead.

The search control also provides some _slots_ that affect the content of the
`<input>` control _addons_.

* `"accept"` - an optional slot to show as an addon button when the `value` can
  be accepted (i.e. `accept` allows for values, or the value matches an item).
  Clicking this will `select` the current `value`.
* `"invalid"` - an optional slot to show in place of the `"accept"` slot when
  the `value` cannot be accepted (e.g. when `accept` is item-only and the
  `value` does not match).


## Keyboard behaviour

The search control supports a number of specialised keyboard inputs:

* `UP` and `DOWN` arrow keys cycle focus through the `<input>` control and the
  list of matched items. These keys will also reopen a closed list, if there are
  matched items to display.
* `TAB` will follow standard tab-key navigation (forwards and backwards) through
  the `<input>` control and the list of matched items, if it is open.
* `ESCAPE` closes the list of matched items.

-->
<script context="module" lang="ts">
  import {afterUpdate, createEventDispatcher, onDestroy} from "svelte";
  import type {HTMLAttributes} from "svelte/elements";
  import Spinner from "./spinkit/Spinner.svelte";

  export interface SearchResult<T = any> {
    items: T[];
    prev?: CursorFn<T>;
    next?: CursorFn<T>;
  }
  interface LockedSearchResult<T = any> extends SearchResult<T> {
    prev?: LockedCursorFn<T>;
    next?: LockedCursorFn<T>;
    value: string;
  }
  export type SearchFn<T = any> = (value: string) => Promise<SearchResult<T>>;
  export type CursorFn<T = any> = () => Promise<SearchResult<T>>;
  type LockedCursorFn<T = any> = () => Promise<LockedSearchResult<T>>;
  export type KeyFn<T = any> = (item: T) => string;
  type CasedEqFn = (a: string, b: string) => boolean;
  export type KeyCase = "sensitive" | "insensitive" | "lower" | "upper";
  export type Accept = "item" | "value";
  export type DropDirection = "auto" | "downup" | "updown" | "down" | "up";
  export type CursorMode = "append" | "replace" | "none";
  export type InputMode = HTMLAttributes<HTMLInputElement>["inputmode"];
  export type EnterKeyHint = HTMLAttributes<HTMLInputElement>["enterkeyhint"];

  export interface ItemSelection<T = any> {
    type: "item";
    item: T;
  }
  export interface ValueSelection {
    type: "value";
    value: string;
  }
  export type Selection<T = any> = ItemSelection<T> | ValueSelection;

  export interface Props {
    search: SearchFn;
    key?: KeyFn;
    keycase?: KeyCase;
    value?: string | null;
    delay?: number;
    cursor?: CursorMode;
    drop?: DropDirection;
    accept?: Accept | Accept[];
    autoclear?: boolean;
    noautoclose?: boolean;
    noindicator?: boolean;
    name?: string;
    id?: string;
    placeholder?: string;
    inputmode?: InputMode;
    enterkeyhint?: EnterKeyHint;
    disabled?: boolean;
  }

  // Recommended delays.
  export const default_network_api_delay = 400;

  function update_drop(
    drop?: DropDirection,
    dropdown_element?: HTMLElement,
    list_element?: HTMLElement,
    viewport_height?: number,
    triggers?: any
  ): {dropdown: boolean; dropup: boolean} {
    if (!drop || drop === "auto") {
      drop = "downup";
    }

    if (drop === "downup" || drop === "updown") {
      const body = document.body;
      const html = document.documentElement;
      const document_height = Math.max(
        body.clientHeight,
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      if (dropdown_element && list_element && viewport_height && document_height) {
        const dropr = dropdown_element.getBoundingClientRect();
        const listr = list_element.getBoundingClientRect();
        const pad = 10;
        const total_height = dropr.height + listr.height + pad;
        const viewport_above = new DOMRect(dropr.x, dropr.bottom - total_height, dropr.width, total_height);
        const viewport_below = new DOMRect(dropr.x, dropr.top, dropr.width, total_height);
        const document_above = DOMRect.fromRect(viewport_above);
        const document_below = DOMRect.fromRect(viewport_below);

        // Move the document above/below rectangles to their "document" positions.
        document_below.y += window.scrollY;
        document_above.y += window.scrollY;

        // See whether the control will fit in the viewport/document when
        // arrange with list above/below the input control.
        const fits_viewport_above = viewport_above.top > 0 && viewport_above.bottom < viewport_height;
        const fits_viewport_below = viewport_below.top > 0 && viewport_below.bottom < viewport_height;
        const fits_document_above = document_above.top > 0 && document_above.bottom < document_height;
        const fits_document_below = document_below.top > 0 && document_below.bottom < document_height;

        // Set to preferred drop direction.
        drop = drop === "downup" ? "down" : "up";

        // Check to see the input and list will fit within the viewport, and if
        // not, then within the document, giving priority to the preferred drop
        // direction.
        if (
          drop === "down" &&
          !fits_viewport_below &&
          (fits_viewport_above || (!fits_document_below && fits_document_above))
        ) {
          drop = "up";
        } else if (
          drop === "up" &&
          !fits_viewport_above &&
          (fits_viewport_below || (!fits_document_above && fits_document_below))
        ) {
          drop = "down";
        }
      } else {
        drop = drop === "downup" ? "down" : "up";
      }
    }

    // The drop is "down" or "up" at this stage.
    const dropdown = drop === "down" ? true : false;

    return {
      dropdown,
      dropup: !dropdown,
    };
  }

  function update({key: key_fn, keycase, cursor, accept}: Partial<Props>) {
    function key(item: any): string {
      return cased_key(key_fn ? key_fn(item) : typeof item === "string" ? item : `${item}`);
    }

    function cased_key(value: string): string {
      switch (keycase) {
        case "lower":
          return value.toLowerCase();
        case "upper":
          return value.toUpperCase();
        case "insensitive":
        case "sensitive":
        default:
          return value;
      }
    }

    function cased_eq(a: string, b: string): boolean {
      switch (keycase) {
        case "insensitive":
        case "lower":
          return a.toLowerCase() === b.toLowerCase();
        case "upper":
          return a.toUpperCase() === b.toUpperCase();
        case "sensitive":
        default:
          return a === b;
      }
    }

    let accept_item = true;
    let accept_value = true;
    if (accept) {
      if (typeof accept === "string") {
        accept_item = accept === "item";
        accept_value = accept === "value";
      } else {
        accept_item = false;
        accept_value = false;
        for (const option of accept) {
          if (option === "item") {
            accept_item = true;
          } else if (option === "value") {
            accept_value = true;
          }
        }
      }
    }

    {
      return {
        key,
        cased_key,
        cased_eq,
        cursor: cursor ?? "append",
        accept_item,
        accept_value,
      };
    }
  }

  interface ListEntryBase {
    type: "prev" | "next" | "separator" | "item";
    id: string;
  }
  interface ListControl extends ListEntryBase {
    type: "prev" | "next" | "separator";
  }
  interface ListItem extends ListEntryBase {
    type: "item";
    index: number;
    key: string;
    item: any;
  }
  type ListEntry = ListControl | ListItem;

  function update_entries(
    items: any[],
    key: KeyFn,
    dropdown: boolean,
    cursor: CursorMode,
    prev: LockedCursorFn | undefined,
    next: LockedCursorFn | undefined
  ): ListEntry[] {
    const head: ListEntry[] = [];
    const entries: ListEntry[] = [];
    const tail: ListEntry[] = [];
    const hsep: ListEntry = {type: "separator", id: "hsep"};
    const tsep: ListEntry = {type: "separator", id: "tsep"};

    prev = cursor === "replace" ? prev : undefined;
    next = cursor !== "none" ? next : undefined;

    if (prev) {
      (dropdown ? head : tail).push({type: "prev", id: "prev"});
    }
    if (next) {
      (dropdown ? tail : head).push({type: "next", id: "next"});
    }
    for (let index = 0; index < items.length; index++) {
      const item = items[index];
      const item_key = key(item);
      entries.push({type: "item", id: `item:${item_key}`, index, key: item_key, item});
    }
    if (!dropdown) {
      entries.reverse();
    }

    return [...head, ...(head.length ? [hsep] : []), ...entries, ...(tail.length ? [tsep] : []), ...tail];
  }

  function matchable(items: any[], key: KeyFn, cased_eq: CasedEqFn, value?: Props["value"]) {
    if (value) {
      for (const item of items) {
        if (cased_eq(key(item), value)) {
          return true;
        }
      }
    }
    return false;
  }

  function update_addon({
    items,
    key,
    cased_eq,
    value,
    accept_slot,
    accept_value,
    accept_item,
    loading,
    noindicator,
    disabled,
  }: {
    items: any[];
    key: KeyFn;
    cased_eq: CasedEqFn;
    value: Props["value"];
    accept_slot: boolean;
    accept_value: boolean;
    accept_item: boolean;
    loading: boolean;
    noindicator: Props["noindicator"];
    disabled: Props["disabled"];
  }) {
    const can_indicate = loading && !noindicator;
    const value_matches = matchable(items, key, cased_eq, value);
    let accept_invalid = false;
    let enable_accept = false;
    let show_addon: "accept" | "indicator" | undefined = undefined;

    if (can_indicate && !value_matches) {
      show_addon = "indicator";
    } else if (accept_slot) {
      show_addon = "accept";
      if (value) {
        accept_invalid = !accept_value && !value_matches;
        enable_accept = (accept_value || (accept_item && value_matches)) && !disabled;
      }
    } else if (can_indicate) {
      show_addon = "indicator";
    }

    return {show_addon, enable_accept, accept_invalid};
  }
</script>

<script lang="ts">
  export let search: Props["search"];
  let key_fn: Props["key"] = undefined;
  export {key_fn as key};
  export let keycase: Props["keycase"] = undefined;
  export let value: Props["value"] = undefined;
  export let delay: Props["delay"] = undefined;
  let cursor_mode: Props["cursor"] = undefined;
  export {cursor_mode as cursor};
  export let drop: Props["drop"] = undefined;
  export let accept: Props["accept"] = undefined;
  export let autoclear: Props["autoclear"] = undefined;
  export let noautoclose: Props["noautoclose"] = undefined;
  export let noindicator: Props["noindicator"] = undefined;
  export let name: Props["name"] = undefined;
  export let id: Props["id"] = undefined;
  export let placeholder: Props["placeholder"] = undefined;
  export let inputmode: Props["inputmode"] = undefined;
  export let enterkeyhint: Props["enterkeyhint"] = undefined;
  export let disabled: Props["disabled"] = undefined;

  const dispatch = createEventDispatcher<{select: Selection; error: any}>();

  let items: any[] = [];
  let prev: LockedCursorFn | undefined = undefined;
  let next: LockedCursorFn | undefined = undefined;
  let open = false;
  let timer: ReturnType<typeof setTimeout> | undefined = undefined;
  let loading = false;
  let pending = false;
  let item_focus_after_update: "first" | "last" | undefined = undefined;
  let viewport_height: number | undefined = undefined;
  let dropdown_element: HTMLElement | undefined = undefined;
  let input_element: HTMLInputElement | undefined = undefined;
  let list_element: HTMLUListElement | undefined = undefined;
  let prev_element: HTMLLIElement | undefined = undefined;
  let next_element: HTMLLIElement | undefined = undefined;
  let dropdown = true;
  let dropup = !dropdown;
  let defer_update_drop: (() => ReturnType<typeof update_drop>) | undefined = undefined;

  function input() {
    // Only update the search based on actual user input.
    update_search();
  }

  function update_search() {
    if (value) {
      if (timer) {
        clearTimeout(timer);
        timer = undefined;
      }
      if (delay && delay >= 0) {
        timer = setTimeout(initiate_search, delay);
      } else {
        initiate_search();
      }
    } else {
      items = [];
      prev = undefined;
      next = undefined;
      open = false;
      clearTimeout(timer);
      timer = undefined;
      pending = false;
      item_focus_after_update = undefined;
    }
  }

  function initiate_search(use_cursor?: "prev" | "next") {
    if (loading) {
      if (!use_cursor) {
        pending = true;
      }
      return;
    }

    function locked(value: string) {
      function lock({items, prev, next}: SearchResult): Promise<LockedSearchResult> {
        return Promise.resolve({
          items,
          prev: prev ? () => prev().then(locked(value)) : undefined,
          next: next ? () => next().then(locked(value)) : undefined,
          value,
        });
      }
      return lock;
    }

    function unique(items: any[], key: KeyFn) {
      const keys: {[key: string]: true | undefined} = {};
      const unique: any[] = [];
      for (const item of items) {
        const k = key(item);
        if (!keys[k]) {
          unique.push(item);
        }
      }
      return unique;
    }

    let promise: Promise<LockedSearchResult> | undefined = undefined;

    if (use_cursor === "prev" && prev && cursor === "replace") {
      // Previous only makes sense for "replace" cursors.
      promise = prev();
    } else if (use_cursor === "next" && next) {
      // Next can be used to load the next page for "replace" cursors OR to
      // append to the current items for "append" cursors.
      promise = next();
    } else if (!use_cursor && value) {
      // Start a fresh search from the beginning.
      promise = search(value).then(locked(value));
    }

    if (promise) {
      pending = false;
      loading = true;
      promise
        .then((result) => {
          if (result.value !== value) {
            // The search value was updated in the meantime. Do not update the list.
            return;
          }

          items = unique(
            use_cursor === "next" && cursor === "append" ? [...items, ...result.items] : result.items,
            key
          );
          prev = result.prev;
          next = result.next;

          if (use_cursor === "prev" && !prev && prev_element && prev_element === document.activeElement) {
            item_focus_after_update = "first";
          } else if (use_cursor === "next" && !next && next_element && next_element === document.activeElement) {
            item_focus_after_update = "last";
          }
        }, dispatch_error)
        .then(() => {
          open = items.length > 0 && !disabled;
          loading = false;
          if (pending) {
            initiate_search();
          }
        });
    }
  }

  function keydown(event: KeyboardEvent) {
    const up = event.key === "ArrowUp" || event.key === "Up";
    const down = event.key === "ArrowDown" || event.key === "Down";
    if (event.key === "Escape" || event.key === "Cancel") {
      open = false;
      if (input_element) {
        input_element.focus();
      }
      event.preventDefault();
    } else if (up || down) {
      // Cycle backwards through the items and controls.
      if (!open && event.currentTarget === input_element && items.length > 0) {
        open = true;
        event.preventDefault();
      } else if (open && event.currentTarget) {
        set_focus(up ? "before" : "after", event.currentTarget);
        event.preventDefault();
      }
    } else if (event.key === "Enter") {
      if (event.currentTarget === input_element) {
        if (value && !accept_selection({type: "value", value})) {
          event.preventDefault();
        }
      } else if (event.currentTarget instanceof HTMLLIElement) {
        event.currentTarget.click();
        event.preventDefault();
      }
    }
  }

  function set_focus(rel: "before" | "after", element: EventTarget): void;
  function set_focus(rel: "item", item: number): void;
  function set_focus(rel: "before" | "after" | "item", arg2: EventTarget | number): void {
    const element: EventTarget | undefined = typeof arg2 === "number" ? undefined : arg2;
    const item: number | undefined = typeof arg2 === "number" ? arg2 : undefined;

    if (input_element && dropdown_element && !!dropdown_element.querySelectorAll) {
      const targets: HTMLElement[] = [];
      if (dropdown) {
        targets.push(input_element);
      }
      if (dropdown && prev_element) {
        targets.push(prev_element);
      } else if (dropup && next_element) {
        targets.push(next_element);
      }
      const item_begin_index = targets.length;
      dropdown_element.querySelectorAll<HTMLElement>("li.match-item[tabindex]").forEach((elem) => targets.push(elem));
      const item_end_index = targets.length;
      if (dropdown && next_element) {
        targets.push(next_element);
      } else if (dropup && prev_element) {
        targets.push(prev_element);
      }
      if (dropup) {
        targets.push(input_element);
      }

      let index = -1;
      if ((rel === "before" || rel === "after") && element instanceof HTMLElement) {
        index = targets.indexOf(element);
        if (index !== -1) {
          index = (index + targets.length + (rel === "before" ? -1 : 1)) % targets.length;
        }
      } else if (rel === "item" && item !== undefined) {
        if (dropdown) {
          index = Math.max(0, Math.min(item_begin_index + item, targets.length - 1));
        } else if (dropup) {
          index = Math.max(0, Math.min(item_end_index - item - 1, targets.length - 1));
        }
      }

      if (0 <= index && index < targets.length) {
        targets[index].focus();
        return;
      }
    }
  }

  function accept_selection(selection: Selection): boolean {
    if (selection.type === "item") {
      const item_key = key(selection.item);
      if (value !== item_key) {
        value = item_key;
      }
      if (!accept_item) {
        // Fallback from "item" to "value" for the selection.
        selection = {type: "value", value};
      }
    }
    if (selection.type === "value") {
      selection.value = cased_key(selection.value);
      if (value !== selection.value) {
        value = selection.value;
      }
      if (accept_item) {
        // Try to upgrade from "value" to "item" for the selection.
        for (const item of items) {
          if (cased_eq(selection.value, key(item))) {
            selection = {type: "item", item};
            break;
          }
        }
      }
    }
    if ((selection.type === "item" && !accept_item) || (selection.type === "value" && !accept_value)) {
      // Cancel the selection as it is not "accepted".
      return false;
    }
    if (dispatch("select", selection, {cancelable: true})) {
      // The selection has been accepted, so apply the post-select UI updates.
      if (autoclear) {
        value = undefined;
      }
      if (!noautoclose) {
        // Close on selection accept.
        open = false;
        if (input_element) {
          if (enterkeyhint === "done") {
            // Drop focus to ensure that any mobile virtual keyboard is closed
            // (as per HTML standard
            // <https://html.spec.whatwg.org/multipage/interaction.html#input-modalities:-the-enterkeyhint-attribute>):
            //
            // * done - The user agent should present a cue for the operation
            //          'done', typically meaning there is nothing more to input
            //          and the input method editor (IME) will be closed.
            input_element.blur();
          } else {
            // Ensure that we don't lose focus when closing the dropdown.
            input_element.focus();
          }
        }
      }
      return true;
    } else {
      // The selection was cancelled via preventDefault().
      return false;
    }
  }

  function dispatch_error(reason: any): void {
    dispatch("error", reason);
  }

  function monitor_clicks(event: Event) {
    if (open && list_element && event.target instanceof Node && !list_element.contains(event.target)) {
      // The user clicked somewhere outside this dropdown while it was open.
      open = false;
    }
  }

  $: if (open && disabled) {
    open = false;
  }
  $: ({key, cased_key, cased_eq, cursor, accept_item, accept_value} = update({
    key: key_fn,
    keycase,
    cursor: cursor_mode,
    accept,
  }));
  // The update to the drop direction needs to occur AFTER the next DOM update
  // because we need to know the NEW size of the list element.
  $: defer_update_drop = () => update_drop(drop, dropdown_element, list_element, viewport_height, {value, open, items});
  $: entries = update_entries(items, key, dropdown, cursor, prev, next);
  $: ({show_addon, enable_accept, accept_invalid} = update_addon({
    items,
    key,
    cased_eq,
    value,
    accept_slot: $$slots.accept,
    accept_value,
    accept_item,
    loading,
    noindicator,
    disabled,
  }));
  $: if (open) {
    document.addEventListener("click", monitor_clicks);
  } else {
    document.removeEventListener("click", monitor_clicks);
  }

  afterUpdate(() => {
    if (defer_update_drop) {
      const updates = defer_update_drop();
      dropdown = updates.dropdown;
      dropup = updates.dropup;
      defer_update_drop = undefined;
    }
    if (item_focus_after_update) {
      set_focus("item", item_focus_after_update === "first" ? 0 : Math.max(0, items.length - 1));
      item_focus_after_update = undefined;
    }
  });

  onDestroy(() => {
    document.removeEventListener("click", monitor_clicks);
    clearTimeout(timer);
  });
</script>

<svelte:window bind:innerHeight={viewport_height} />

<div bind:this={dropdown_element} class:dropdown class:dropup class:open>
  <div class:input-group={!!show_addon}>
    <input
      bind:this={input_element}
      type="text"
      class="form-control"
      tabindex="0"
      {placeholder}
      {name}
      {id}
      {inputmode}
      {enterkeyhint}
      {disabled}
      bind:value
      on:input={input}
      on:keydown={keydown}
    />
    {#if show_addon}
      <span class="input-group-btn">
        {#if show_addon === "indicator"}
          <button type="button" class="btn btn-default" disabled id={id ? `${id}__search_indicator` : undefined}>
            <Spinner type="circle-fade" />
          </button>
        {:else if show_addon === "accept"}
          <button
            type="button"
            class="btn btn-default"
            id={id ? `${id}__search_accept` : undefined}
            disabled={!enable_accept}
            on:click={() => (value ? accept_selection({type: "value", value}) : undefined)}
          >
            {#if accept_invalid}
              <slot name="invalid">
                <slot name="accept" />
              </slot>
            {:else}
              <slot name="accept" />
            {/if}
          </button>
        {/if}
      </span>
    {/if}
  </div>
  <ul bind:this={list_element} class="dropdown-menu">
    {#each entries as entry, _ignored_entry_index (entry.id)}
      {#if entry.type === "prev"}
        <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
        <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
        <li
          bind:this={prev_element}
          tabindex="0"
          id={id ? `${id}__search_prev` : undefined}
          on:keydown={keydown}
          on:click|stopPropagation={() => initiate_search("prev")}
        >
          <slot name="prev-outer">
            <!-- svelte-ignore a11y-missing-attribute -->
            <a>
              <slot name="prev">Previous</slot>
            </a>
          </slot>
        </li>
      {:else if entry.type === "separator"}
        <li role="separator" class="divider" />
      {:else if entry.type === "item"}
        {@const item = entry.item}
        {@const active = value && cased_eq(entry.key, value) ? true : false}
        <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
        <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
        <li
          class="match-item"
          class:active
          id={id ? `${id}__search_item_${entry.index}` : undefined}
          tabindex="0"
          on:keydown={keydown}
          on:click|stopPropagation={() => accept_selection({type: "item", item})}
        >
          <slot name="match-outer" {item} {active}>
            <!-- svelte-ignore a11y-missing-attribute -->
            <a>
              <slot name="match" {item} {active}>
                {key(item)}
              </slot>
            </a>
          </slot>
        </li>
      {:else if entry.type === "next"}
        <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
        <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
        <li
          bind:this={next_element}
          tabindex="0"
          id={id ? `${id}__search_next` : undefined}
          on:keydown={keydown}
          on:click|stopPropagation={() => initiate_search("next")}
        >
          <slot name="next-outer">
            <!-- svelte-ignore a11y-missing-attribute -->
            <a>
              <slot name="next">
                {#if cursor === "replace"}
                  Next
                {:else}
                  More
                {/if}
              </slot>
            </a>
          </slot>
        </li>
      {/if}
    {/each}
  </ul>
</div>
