export class PlaceContext {
  regions: RegionContext[];
  positions: NamedPosition[];
  placements: Placement[];
  promise?: Promise<void>;

  constructor() {
    this.regions = [];
    this.positions = [];
    this.placements = [];
  }

  apply(): void {
    delete this.promise;

    // Ensure that placements have been "rank" sorted.
    this.placements.sort((a, b) => (a.parameters?.rank || 0) - (b.parameters?.rank || 0));

    // Apply region start/end placements.
    for (const region of this.regions) {
      for (const relation of ["start", "end"]) {
        let after: HTMLElement | undefined = undefined;
        for (const placement of this.placements) {
          if (
            placement.element &&
            placement.parameters?.type === "region" &&
            placement.parameters?.ref === region.name &&
            placement.parameters?.rel === relation
          ) {
            if (!after) {
              const container = region.container_for(relation);
              if (container) {
                if (relation === "start") {
                  // Insert the element as the first child of the container.
                  container.insertBefore(placement.element, container.firstChild);
                } else {
                  // Insert the element as the last child of the container.
                  container.appendChild(placement.element);
                }
                after = placement.element;
              }
            } else {
              // Insert the element after the last element we moved so as to
              // respect the placement rank.
              const parent = after.parentElement;
              if (parent) {
                parent.insertBefore(placement.element, after.nextSibling);
                after = placement.element;
              }
            }
          }
        }
      }
    }

    // Apply position before/after placements.
    for (const position of this.positions) {
      if (!position.element) {
        continue;
      }

      const parent = position.element.parentElement;
      if (!parent) {
        continue;
      }

      for (const relation of ["before", "after"]) {
        let after: HTMLElement | undefined = undefined;
        for (const placement of this.placements) {
          if (
            placement.element &&
            placement.parameters?.type === "position" &&
            placement.parameters?.ref === position.name &&
            placement.parameters?.rel === relation
          ) {
            if (!after) {
              if (relation === "before") {
                // Insert the element before the reference element.
                parent.insertBefore(placement.element, position.element);
              } else {
                // Insert the element after the reference element.
                parent.insertBefore(placement.element, position.element.nextSibling);
              }
            } else {
              // Insert the element after the last element we moved so as to
              // respect the placement rank.
              parent.insertBefore(placement.element, after.nextSibling);
            }
            after = placement.element;
          }
        }
      }
    }
  }

  update(): void {
    if (!this.promise) {
      this.promise = Promise.resolve().then(() => this.apply());
    }
  }

  register_region(region: RegionContext): () => void {
    return this.#register(this.regions, region);
  }

  register_position(position: NamedPosition): () => void {
    return this.#register(this.positions, position);
  }

  register_placement(placement: Placement): () => void {
    return this.#register(this.placements, placement);
  }

  #register<T>(items: T[], item: T): () => void {
    items.push(item);
    this.update();
    return () => {
      const index = items.lastIndexOf(item);
      if (index !== -1) {
        items.splice(index, 1);
        this.update();
      }
    };
  }
}

export class RegionContext {
  place: PlaceContext;
  name?: string;
  element?: HTMLElement;
  containers: HTMLElement[];

  constructor(place: PlaceContext) {
    this.place = place;
    this.containers = [];
  }

  register(): () => void {
    return this.place.register_region(this);
  }

  update(name?: string, element?: HTMLElement) {
    this.name = name;
    this.element = element;
    this.place.update();
  }

  register_container(container: HTMLElement): () => void {
    this.containers.push(container);
    this.place.update();
    return () => {
      const index = this.containers.lastIndexOf(container);
      if (index !== -1) {
        this.containers.splice(index, 1);
        this.place.update();
      }
    };
  }

  container_for(relation: string): HTMLElement | undefined {
    if (this.containers.length > 0) {
      if (relation === "start") {
        return this.containers[0];
      } else if (relation === "end") {
        return this.containers[this.containers.length - 1];
      }
    } else {
      return this.element;
    }
  }
}

export class NamedPosition {
  place: PlaceContext;
  name?: string;
  element?: HTMLElement;

  constructor(place: PlaceContext) {
    this.place = place;
  }

  register(): () => void {
    return this.place.register_position(this);
  }

  update(name?: string, element?: HTMLElement) {
    this.name = name;
    this.element = element;
    this.place.update();
  }
}

// See also `ElementPlacement` in `$lib/guest/types.ts` for guest specific
// parameters.
export interface PlacementParameters {
  type: "region" | "position";
  ref: string;
  rel: "start" | "end" | "before" | "after";
  rank: number | null;
}

export class Placement {
  place: PlaceContext;
  parameters?: PlacementParameters;
  element?: HTMLElement;

  constructor(place: PlaceContext) {
    this.place = place;
  }

  register(): () => void {
    return this.place.register_placement(this);
  }

  update(parameters?: PlacementParameters, element?: HTMLElement) {
    this.parameters = parameters;
    this.element = element;
    this.place.update();
  }
}
