<script context="module" lang="ts">
  import CustomHtml from "$lib/CustomHtml.svelte";
  import CustomText from "$lib/CustomText.svelte";
  import InjectHref from "$lib/InjectHref.svelte";
  import MoreBox from "$lib/MoreBox.svelte";
  import UntrustedHtml from "$lib/UntrustedHtml.svelte";
  import {_, Translate} from "$lib/translate";
  import type {TranslateFn} from "$lib/translate";
  import type {Config} from "./AcceptTerms.types";
  export * from "./AcceptTerms.types";

  type Login = {
    error?: string | null;
    accept_terms: boolean;
  };

  // Non-reactive initialisation function.
  function init(config: Config) {
    const login: Login = {error: null, accept_terms: false};

    if (config.login && config.login.accept_terms) {
      login.accept_terms = true;
    }

    return {login};
  }

  // Reactive update function, as in: `$: ... update(config, ...)`
  function update(config: Config, _: TranslateFn, accept_terms_form?: HTMLFormElement) {
    // Convenience values:
    // N/A

    // Derived functions block scope
    {
      function show_accept_terms(): boolean {
        return config.page.require_accept_terms;
      }

      function accept_terms_ok(login: Login): boolean {
        return !show_accept_terms() || login.accept_terms;
      }

      function enable_accept_terms(login: Login): boolean {
        // DO_NOT_MERGE(form/validation): How to check that the form is valid as
        // per old Angular behavior.
        return accept_terms_form !== undefined && /* !accept_terms_form.$invalid && */ accept_terms_ok(login);
      }

      // Derive variables block scope
      {
        let error: string | null = null;
        // DO_NOT_MERGE(obsolete): Old code hangover. Should be removed.
        const hidden_values: {name: string; value: string}[] = [];

        if (config.login) {
          if (config.login.errors) {
            if (config.login.errors.accept_terms) {
              error = config.login.errors.accept_terms[0];
            } else if (config.login.errors.general) {
              error = config.login.errors.general[0];
            }
          }
        }

        return {
          error,
          hidden_values,
          page_title: config.page.terms_title || _("Terms and Conditions"),
          show_accept_terms: show_accept_terms(),
          accept_terms_ok,
          enable_accept_terms,
        };
      }
    }
  }
</script>

<script lang="ts">
  export let config: Config;

  const {login} = init(config);

  $: ({error, page_title, hidden_values, show_accept_terms, enable_accept_terms} = update(
    config,
    $_,
    accept_terms_form
  ));
  $: login.error = error;

  let accept_terms_form: HTMLFormElement | undefined = undefined;
</script>

<svelte:head>
  <title>{page_title}</title>
</svelte:head>

<div class="skin row" id="cg-guest-accept-terms">
  <div class="skin column">
    <div class="form-signin skin content">
      <h2 class="form-signin-heading terms-message-title">
        {page_title}
      </h2>
      {#if config.page.terms_message && config.page.terms_location == "internal"}
        <MoreBox class="terms-message">
          <div class="skin align terms-message-body">
            <UntrustedHtml html={config.page.terms_message} />
          </div>
        </MoreBox>
      {/if}
    </div>
  </div>
  <div class="skin column">
    <div class="form-signin skin content">
      <form
        bind:this={accept_terms_form}
        name="accept_terms_form"
        method="POST"
        action={config.page.action}
        class="skin controls narrow"
      >
        <div>
          <!-- DO_NOT_MERGE(obsolete): Old code hangover. Should be removed. -->
          {#each hidden_values as hidden}
            <input value={hidden.value} name={hidden.name} type="hidden" />
          {/each}

          {#if !show_accept_terms && config.page.terms_location == "external"}
            <div class="form-group skin align">
              <InjectHref href={config.page.terms_url} class="terms-link">
                <CustomHtml html={config.page.terms_accept_message}>
                  <!-- svelte-ignore a11y-missing-attribute (href is injected at runtime) -->
                  <a><Translate>Terms and conditions</Translate></a>
                </CustomHtml>
              </InjectHref>
            </div>
          {/if}

          {#if show_accept_terms}
            <div class="checkbox form-group">
              <label for="accept_terms_input">
                <input bind:checked={login.accept_terms} name="accept_terms" type="checkbox" id="accept_terms_input" />
                <InjectHref href={config.page.terms_url} class="terms-link">
                  <CustomHtml html={config.page.terms_accept_message}>
                    <!-- svelte-ignore a11y-missing-attribute (href is injected at runtime) -->
                    <Translate>I accept the <a>terms and conditions</a></Translate>
                  </CustomHtml>
                </InjectHref>
              </label>
            </div>
          {/if}

          {#if login.error}
            <div class="form-group alert alert-danger">
              <strong>{login.error}</strong>
            </div>
          {/if}

          <div class="form-group">
            <button
              disabled={!enable_accept_terms(login)}
              class="btn btn-lg btn-primary btn-block btn-sign-in"
              type="submit"
              id="accept_action"
            >
              <CustomText text={config.page.accept_button_title}><Translate>Accept</Translate></CustomText>
            </button>
          </div>
          <div>
            <a href={config.page.back} class="btn btn-link back-link back-link-bottom" id="back_action">
              <span class="glyphicon glyphicon-chevron-left" />
              <CustomText text={config.page.back_button_title}><Translate>Back</Translate></CustomText>
            </a>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
