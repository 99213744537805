<script context="module" lang="ts">
  import CustomHtml from "$lib/CustomHtml.svelte";
  import CustomText from "$lib/CustomText.svelte";
  import InjectHref from "$lib/InjectHref.svelte";
  import MoreBox from "$lib/MoreBox.svelte";
  import PlaceContainer from "$lib/place/PlaceContainer.svelte";
  import Placement from "$lib/place/Placement.svelte";
  import UntrustedHtml from "$lib/UntrustedHtml.svelte";
  import {_, Translate} from "$lib/translate";
  import type {TranslateFn} from "$lib/translate";
  import Errors from "$lib/Errors.svelte";
  import type {AuthSource, Config, Test} from "./Login.types";
  import {social} from "$lib/social";
  export * from "./Login.types";

  interface Login {
    username: string | null;
    password: string | null;
    accept_terms: boolean | null;
    error: string[] | string | null;
    signed_out: boolean | null;
  }

  function auth_source_link_classes(auth_source: AuthSource, social_login_branding?: string | null): string {
    var classes = "auth-source auth-source-link btn btn-block btn-lg btn-wrap ";
    if (social_login_branding === "dark") {
      switch (auth_source.type) {
        case "facebook":
        case "facebookwifi":
          return classes + "auth-source-button btn-facebook-dark";
        case "google":
        case "googleplus":
          return classes + "auth-source-button btn-google-dark";
        case "linkedin":
          return classes + "auth-source-button btn-linkedin-dark";
        case "twitter":
          return classes + "auth-source-button btn-twitter-dark";
        default:
          return classes + "btn-primary";
      }
    } else if (social_login_branding === "light") {
      switch (auth_source.type) {
        case "facebook":
        case "facebookwifi":
          return classes + "auth-source-button btn-facebook-light";
        case "google":
        case "googleplus":
          return classes + "auth-source-button btn-google-light";
        case "linkedin":
          return classes + "auth-source-button btn-linkedin-light";
        case "twitter":
          return classes + "auth-source-button btn-twitter-light";
        default:
          return classes + "btn-primary";
      }
    } else {
      if (auth_source.ui === "icon") {
        // Auth source with icon needs less padding
        return classes + "auth-source-button btn-primary";
      } else {
        return classes + "btn-primary";
      }
    }
  }

  function auth_source_icon_url(auth_source: AuthSource, social_login_branding: string): string | null | undefined {
    if (auth_source.ui != "icon") {
      return null;
    }

    if (social_login_branding == "dark") {
      switch (auth_source.type) {
        case "facebook":
        case "facebookwifi":
          return social.dark.facebook;
        case "google":
        case "googleplus":
          return social.dark.google;
        case "linkedin":
          return social.dark.linkedin;
        case "twitter":
          return social.dark.twitter;
      }
    } else if (social_login_branding == "light") {
      switch (auth_source.type) {
        case "facebook":
        case "facebookwifi":
          return social.light.facebook;
        case "google":
        case "googleplus":
          return social.light.google;
        case "linkedin":
          return social.light.linkedin;
        case "twitter":
          return social.light.twitter;
      }
    }
  }

  function auth_source_icon_classes(auth_source: AuthSource): string | null | undefined {
    if (auth_source.ui != "icon") {
      return null;
    }

    switch (auth_source.type) {
      case "facebook":
      case "facebookwifi":
        return "auth-source-icon socialicon socialicon-circlefacebook";
      case "google":
      case "googleplus":
        return "auth-source-icon socialicon socialicon-circlegoogleplus";
      case "linkedin":
        return "auth-source-icon socialicon socialicon-circlelinkedin";
      case "twitter":
        return "auth-source-icon socialicon socialicon-circletwitterbird";
    }
  }

  function init(config: Config) {
    const login: Login = {
      username: null,
      password: null,
      accept_terms: null,
      error: null,
      signed_out: null,
    };

    if (config.login) {
      if (config.login.username) {
        login.username = config.login.username;
      }
      if (config.login.accept_terms) {
        login.accept_terms = true;
      }
      if (config.login.errors) {
        if (config.login.errors.username) {
          login.error = config.login.errors.username;
        } else if (config.login.errors.password) {
          login.error = config.login.errors.password;
        } else if (config.login.errors.accept_terms) {
          login.error = config.login.errors.accept_terms;
        } else if (config.login.errors.general) {
          login.error = config.login.errors.general;
        }
      }
      if (config.login.signed_out) {
        login.signed_out = true;
      }
    }

    return {login};
  }

  function config_update(config: Config, _: TranslateFn) {
    // Convenience variables
    const alternative_logins = config.auth_sources.filter((auth_source) => {
      return auth_source && typeof auth_source === "object" && auth_source.path && auth_source.title;
    });

    // Derived functions block scope
    {
      function username_placeholder(default_text: string) {
        return config.page.username_placeholder || default_text;
      }

      function password_placeholder(default_text: string) {
        return config.page.password_placeholder || default_text;
      }

      function auth_source_title(auth_source: AuthSource): string | null {
        if (auth_source.display_name) {
          return _("Sign in with {name}").replace("{name}", auth_source.display_name);
        }

        if (auth_source.alt) {
          return auth_source.alt;
        }

        switch (auth_source.type) {
          case "facebook":
            return _("Sign in with Facebook");
          case "facebookwifi":
            return _("Sign in with Facebook Wi-Fi");
          case "google":
            return _("Sign in with Google");
          case "googleplus":
            return _("Sign in with Google+");
          case "linkedin":
            return _("Sign in with LinkedIn");
          case "openidconnect":
            return _("Sign in with OpenID Connect");
          case "twitter":
            return _("Sign in with Twitter");
        }

        if (auth_source.title) {
          return auth_source.title;
        }

        return null;
      }

      function auth_source_link_id(auth_source: AuthSource): string | undefined {
        let number = 1;
        const n = alternative_logins.length;

        for (let i = 0; i < n; i++) {
          if (alternative_logins[i] === auth_source) {
            return `login_${auth_source.type}_${number}_action`;
          } else if (alternative_logins[i].type === auth_source.type) {
            number++;
          }
        }
      }

      // Derived variables block scope
      {
        const hidden_values: {name: string; value: string}[] = []; // TODO: REMOVE: obsolete binding from AngularJS
        const can_authenticate =
          config.page.accept_credentials || config.page.allow_anonymous || alternative_logins.length > 0;

        return {
          alternative_logins,
          can_authenticate,
          page_title: config.page.login_title || _("Sign in"),
          hidden_values,
          show_title: can_authenticate,
          show_accept_terms: config.page.require_accept_terms,
          show_login_form: config.page.accept_credentials || config.page.allow_anonymous,
          show_credentials_form: config.page.accept_credentials,
          show_password_field:
            config.page.accept_credentials || (config.page.allow_anonymous && config.page.anonymous_password_required),
          // TODO: How to test whether a form is invalid in Svelte? (AngularJS
          // used to have a form.$invalid property)
          show_registration: typeof config.page.register_url === "string",
          show_alternative_logins: alternative_logins.length > 0,
          show_no_authentication: !can_authenticate,
          username_placeholder,
          password_placeholder,
          auth_source_title,
          auth_source_link_id,
        };
      }
    }
  }

  function login_update(
    login: Login,
    {
      show_accept_terms,
      show_credentials_form,
      show_password_field,
    }: {
      show_accept_terms: boolean;
      show_credentials_form: boolean;
      show_password_field: boolean;
    }
  ) {
    // TODO: How to test whether a form is invalid in Svelte? (AngularJS used to
    // have a form.$invalid property)
    const accept_terms_ok = !show_accept_terms || !!login.accept_terms;
    const accept_terms_invalid = !accept_terms_ok;
    const username_invalid = show_credentials_form && !login.username;
    const password_invalid = show_credentials_form && show_password_field && !login.password;
    const login_form_invalid = accept_terms_invalid || username_invalid || password_invalid;
    const enable_login = !login_form_invalid && accept_terms_ok;

    return {accept_terms_ok, enable_login};
  }
</script>

<script lang="ts">
  export let config: Config;
  export let test: Test | undefined = undefined;

  // Initialise non-reactive variables
  const {login} = init(config);

  // Reactive state
  $: ({
    alternative_logins,
    can_authenticate,
    page_title,
    hidden_values,
    show_title,
    show_accept_terms,
    show_login_form,
    show_credentials_form,
    show_password_field,
    show_registration,
    show_alternative_logins,
    show_no_authentication,
    username_placeholder,
    password_placeholder,
    auth_source_title,
    auth_source_link_id,
  } = config_update(config, $_));
  $: ({accept_terms_ok, enable_login} = login_update(login, {
    show_accept_terms,
    show_credentials_form,
    show_password_field,
  }));
  $: hidden = test?.show_hidden_inputs ? "text" : "hidden";
</script>

<svelte:head>
  <title>{page_title}</title>
</svelte:head>

<div class="skin row" id="cg-guest-login">
  <div class="skin column">
    <PlaceContainer tag="div" class="form-signin skin content">
      {#if login.signed_out}
        <div class="alert alert-info">
          <Translate>
            You have been <strong>signed out</strong>.
          </Translate>
        </div>
      {/if}

      {#if show_title && config.page.show_login_title}
        <h2 class="form-signin-heading">{page_title}</h2>
      {/if}

      {#if login.error && config.page.login_failed_message}
        <div class="skin align signin-failed-message" id="login_failed_message">
          <UntrustedHtml html={config.page.login_failed_message} />
        </div>
      {/if}

      {#if config.page.login_message}
        <div class="skin align signin-message" id="login_message">
          <UntrustedHtml html={config.page.login_message} />
        </div>
      {/if}

      {#if config.page.login_image_url && config.page.login_image_link_url}
        <Placement tag="div" parameters={config.page.login_image_placement} class="skin align">
          <a href={config.page.login_image_link_url} class="login-image-link">
            <!-- svelte-ignore a11y-missing-attribute (alt) -->
            <img src={config.page.login_image_url} class="login-image" />
          </a>
        </Placement>
      {/if}

      {#if config.page.login_image_url && !config.page.login_image_link_url}
        <Placement tag="div" parameters={config.page.login_image_placement} class="skin align">
          <!-- svelte-ignore a11y-missing-attribute (alt) -->
          <img src={config.page.login_image_url} class="login-image" />
        </Placement>
      {/if}

      {#if config.page.terms_message && config.page.terms_location == "internal"}
        <MoreBox class="terms-message">
          <div class="skin align terms-message-body">
            <UntrustedHtml html={config.page.terms_message} />
          </div>
        </MoreBox>
      {/if}
    </PlaceContainer>
  </div>
  <div class="skin column">
    <PlaceContainer tag="div" class="form-signin skin content">
      <form name="login_form" method="POST" action={config.page.action} class="skin controls narrow">
        <Errors errors={login.error} class="form-group" id="login_errors" />

        {#if show_login_form}
          {#each hidden_values as hidden_value}
            <input value={hidden_value.value} name={hidden_value.name} type={hidden} />
          {/each}
          <input name="capture" value={config.login.capture} type={hidden} />

          {#if show_credentials_form}
            <!-- svelte-ignore a11y-autofocus -->
            <input
              bind:value={login.username}
              placeholder={username_placeholder($_("Username"))}
              name="username"
              id="username_input"
              type="text"
              inputmode="email"
              class="form-control input-stack-top"
              required
              autofocus
            />
          {/if}

          {#if show_password_field}
            <input
              bind:value={login.password}
              placeholder={password_placeholder($_("Password"))}
              name="password"
              id="password_input"
              type="password"
              class="form-control"
              class:input-stack-bottom={show_credentials_form}
              required
            />
          {/if}

          {#if !show_accept_terms && config.page.terms_location == "external"}
            <div class="form-group skin align">
              <InjectHref href={config.page.terms_url} class="terms-link">
                <CustomHtml html={config.page.terms_accept_message}>
                  <!-- svelte-ignore a11y-missing-attribute (href is injected at runtime) -->
                  <a>
                    <Translate>Terms and conditions</Translate>
                  </a>
                </CustomHtml>
              </InjectHref>
            </div>
          {/if}

          {#if show_accept_terms}
            <div class="checkbox form-group">
              <label for="accept_terms_input">
                <input bind:checked={login.accept_terms} name="accept_terms" type="checkbox" id="accept_terms_input" />
                <InjectHref href={config.page.terms_url} class="terms-link">
                  <CustomHtml html={config.page.terms_accept_message}>
                    <!-- svelte-ignore a11y-missing-attribute (href is injected at runtime) -->
                    <Translate>I accept the <a>terms and conditions</a></Translate>
                  </CustomHtml>
                </InjectHref>
              </label>
            </div>
          {/if}

          <div class="form-group">
            <button
              disabled={!enable_login}
              class="btn btn-lg btn-primary btn-block btn-wrap btn-sign-in"
              type="submit"
              id="login_action"
            >
              <CustomText text={config.page.login_button_title}><Translate>Sign in</Translate></CustomText>
            </button>
          </div>
        {/if}

        {#if show_registration}
          <hr class="skin" />
        {/if}

        {#if show_registration}
          <div class="form-group">
            <a
              href={config.page.register_url}
              class="btn btn-lg btn-primary btn-block btn-wrap btn-register"
              id="register_action"
            >
              <CustomText text={config.page.register_button_title}><Translate>Register</Translate></CustomText>
              <span class="glyphicon glyphicon-chevron-right" />
            </a>
          </div>
        {/if}

        {#if show_login_form && show_alternative_logins}
          <hr class="skin" />
        {/if}

        {#if show_alternative_logins}
          <div class="auth-source-links">
            {#each alternative_logins as auth_source}
              <a
                class={auth_source_link_classes(auth_source, config.page.skin?.social_login_branding)}
                href={auth_source.path}
                title={auth_source_title(auth_source)}
                id={auth_source_link_id(auth_source)}
              >
                {#if !config.page.skin?.social_login_branding && auth_source_icon_classes(auth_source)}
                  <span class={auth_source_icon_classes(auth_source)} />
                {/if}
                {#if config.page.skin?.social_login_branding}
                  <!-- svelte-ignore a11y-missing-attribute (alt) -->
                  <img
                    class="auth-source-img"
                    src={auth_source_icon_url(auth_source, config.page.skin.social_login_branding)}
                  />
                {/if}
                <span class="auth-source-title">{auth_source_title(auth_source)}</span>
              </a>
            {/each}
          </div>
        {/if}

        {#if show_no_authentication}
          <div class="form-group alert alert-danger" id="no_authentication">
            <strong><Translate>This page is currently disabled.</Translate></strong>
            <p><Translate>An administrator must enable authentication before this page can be used.</Translate></p>
          </div>
        {/if}
      </form>
    </PlaceContainer>
  </div>
</div>
