<script context="module" lang="ts">
  import CustomHtml from "$lib/CustomHtml.svelte";
  import {_, Translate} from "$lib/translate";
  import type {TranslateFn} from "$lib/translate";
  import type {Config} from "./VerifyPending.types";
  export * from "./VerifyPending.types";

  // Reactive update function, as in: `$: ... update(config, ...)`
  function update(config: Config, _: TranslateFn) {
    return {
      page_title: config.page.verify_title || _("Verify Account"),
    };
  }
</script>

<script lang="ts">
  export let config: Config;

  $: ({page_title} = update(config, $_));
</script>

<svelte:head>
  <title>{page_title}</title>
</svelte:head>

<div class="skin row limit-columns-1" id="cg-guest-verify-pending">
  <div class="skin column">
    <div class="form-verify skin content">
      <div class="skin controls narrow">
        <h2 class="form-verify-heading">{page_title}</h2>
        <div class="panel panel-info">
          <CustomHtml
            html={config.page.verification_email_sent_message}
            class="panel-heading skin align"
            id="verification_email_sent_message"
          >
            <Translate>An account <strong>verification email</strong> has been sent to your email address:</Translate>
          </CustomHtml>
          <div class="panel-body text-center"><strong>{config.login.email}</strong></div>
        </div>
        <CustomHtml
          html={config.page.verification_email_check_message}
          class="skin align"
          id="verification_email_check_message"
        >
          <p>
            <Translate>Check your email and follow the instructions to complete the registration process.</Translate>
          </p>
          <p><Translate>You may now close this browser window.</Translate></p>
        </CustomHtml>
      </div>
    </div>
  </div>
</div>
