<script context="module" lang="ts">
  import type {Config, Skin} from "$lib/config/guest";

  function update_css(style_element: HTMLStyleElement, skin?: Skin) {
    const sheet = style_element.sheet;
    if (!sheet) {
      return;
    }

    while (sheet.cssRules.length) {
      sheet.deleteRule(0);
    }

    function style(selector: string, set: (rule: CSSStyleRule) => void): void {
      if (sheet) {
        const index = sheet.insertRule("* {}", sheet.cssRules.length);
        const rule = sheet.cssRules[index] as CSSStyleRule;
        rule.selectorText = selector.trim();
        set(rule);
      }
    }

    if (skin?.text_color) {
      style(".skin.body", (rule) => {
        rule.style.color = skin.text_color!;
      });
    }

    if (skin?.text_alignment && skin.text_alignment !== "left") {
      style(
        `
.skin.content h2,
.skin.content input,
.skin.content textarea,
.skin.content .checkbox,
.skin.content .skin.align
`,
        (rule) => {
          rule.style.textAlign = skin.text_alignment!;
        }
      );

      style(`.skin .checkbox input[type="checkbox"]`, (rule) => {
        rule.style.float = "none";
      });

      // NOTE: Do not change the aligment of button text.
    }

    if (skin?.background_color) {
      style(".skin.body", (rule) => {
        rule.style.backgroundColor = skin.background_color!;
      });
    }

    if (skin?.header_background_color) {
      style(".skin.header", (rule) => {
        rule.style.backgroundColor = skin.header_background_color!;
      });
    }

    if (skin?.content_background_color) {
      style(".skin.content", (rule) => {
        rule.style.backgroundColor = skin.content_background_color!;
      });
    }

    if (skin?.primary_color) {
      style(
        `
a,
a:hover,
a:active,
a:focus,
.btn-link,
.btn-link:hover,
.btn-link:active,
.btn-link:focus
`,
        (rule) => {
          rule.style.color = skin.primary_color!;
        }
      );
      style(".btn-primary", (rule) => {
        // TODO: Set a primary text "color"
        rule.style.backgroundColor = skin.primary_color!;
        // Darken the border color 5% of primary
        rule.style.borderColor = skin.primary_color!;
      });
      style(
        `      
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus
`,
        (rule) => {
          // TODO: Darken 8% of primary
          rule.style.backgroundColor = skin.primary_color!;
          // TODO: Darken the border color 12% of primary
          rule.style.borderColor = skin.primary_color!;
        }
      );
      style(
        `
.btn-primary[disabled],
.btn-primary[disabled]:hover,
.btn-primary[disabled]:active,
.btn-primary[disabled]:focus
`,
        (rule) => {
          // Let opacity take effect. Just base off primary.
          rule.style.backgroundColor = skin.primary_color!;
          // TODO: Darken the border color 5% of primary
          rule.style.borderColor = skin.primary_color!;
        }
      );
      style("hr.skin", (rule) => {
        rule.style.borderTopColor = skin.primary_color!;
      });
      style(".skin.divider-text", (rule) => {
        rule.style.color = skin.primary_color!;
      });
      style(".skin.more-box-collapsed .skin.more-box-buttons", (rule) => {
        rule.style.borderTopColor = skin.primary_color!;
      });
    }

    const debug: string[] = [];
    for (const rule of sheet.cssRules) {
      debug.push(rule.cssText);
    }
    console.log("DYNAMIC SKIN CSS:");
    console.log(debug.join("\n"));
  }

  function update(config?: Config, style?: HTMLStyleElement) {
    const skin = config?.page.skin ?? undefined;

    if (style) {
      update_css(style, skin);
    }

    return {skin};
  }
</script>

<script lang="ts">
  // TODO: Skin CSS should be rendered via SSR (even if only via a simple string
  // search and replace)
  export let config: Config | undefined = undefined;

  let style: HTMLStyleElement | undefined = undefined;

  $: ({skin} = update(config, style));
  // See also the skin.styl file
</script>

<svelte:head>
  {#if skin}
    {#if skin.favicon_image_url}
      <link rel="shortcut icon" href={skin.favicon_image_url} />
    {/if}
  {/if}
  <style bind:this={style}></style>
</svelte:head>
