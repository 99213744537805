<script context="module" lang="ts">
  import CustomHtml from "$lib/CustomHtml.svelte";
  import CustomText from "$lib/CustomText.svelte";
  import {_, Translate, TranslateParam} from "$lib/translate";
  import type {Config} from "./ApprovalPending.types";
  export * from "./ApprovalPending.types";
</script>

<script lang="ts">
  export let config: Config;

  let page_title = config.page.sponsor_approval_pending_title || $_("Waiting for approval");
</script>

<svelte:head>
  <title>{page_title}</title>
</svelte:head>

<div class="skin row limit-columns-1" id="cg-guest-approval-pending">
  <div class="skin column">
    <div class="form-verify skin content">
      <form name="approval_pending_form" method="GET" action="approval-pending" class="skin controls narrow">
        <h2 class="form-approval-heading">{page_title}</h2>
        <input value={config.login.username} name="username" type="hidden" />
        <input value={config.login.username_type} name="username_type" type="hidden" />
        <input value={config.login.sponsor} name="sponsor" type="hidden" />

        <div class="panel panel-info">
          <CustomHtml
            html={config.page.sponsor_approval_pending_message}
            class="panel-heading skin align"
            id="sponsor_approval_pending_message"
          >
            <p>
              <Translate>
                Your request for access is currently <strong>waiting for approval</strong> from your
                <strong>nominated sponsor</strong>.
              </Translate>
            </p>
            <p>
              <Translate>
                To <strong>check</strong> if your request has been approved, <strong>press</strong> the
                <TranslateParam name="continue">
                  <strong>
                    <CustomText text={config.page.continue_button_title}>
                      <Translate>Continue</Translate>
                    </CustomText>
                  </strong>
                </TranslateParam>
                button below.
              </Translate>
            </p>
            <p><Translate>Your nominated sponsor is:</Translate></p>
          </CustomHtml>
          <div class="panel-body text-center"><strong>{config.login.sponsor}</strong></div>
        </div>

        {#if !config.login.errors.username?.length}
          <div class="form-group">
            <button type="submit" class="btn btn-lg btn-primary btn-block btn-continue" id="continue_action">
              <CustomText text={config.page.continue_button_title}>
                <Translate>Continue</Translate>
              </CustomText>
            </button>
          </div>
        {/if}

        <div class="form-group">
          {#if config.page.register_url}
            <a href={config.page.register_url} class="btn btn-link" id="back_action">
              <span class="glyphicon glyphicon-chevron-left" />
              <span>
                <CustomText text={config.page.back_button_title}>
                  <Translate>Back</Translate>
                </CustomText>
              </span>
            </a>
          {:else}
            <a href="login" class="btn btn-link" id="back_action">
              <span class="glyphicon glyphicon-chevron-left" />
              <span>
                <CustomText text={config.page.back_button_title}>
                  <Translate>Back</Translate>
                </CustomText>
              </span>
            </a>
          {/if}
        </div>
      </form>
    </div>
  </div>
</div>
