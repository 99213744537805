import type {BaseConfig} from "$lib/config/guest";

export type Target = "credentials" | "devices";
export const targets: Target[] = ["credentials", "devices"];
export type UserSearch = "none" | "optional" | "required";
export const user_search: UserSearch[] = ["none", "optional", "required"];

export interface SelfService {
  base_url: string;
  airgroup_username?: string;
  with_devices_and_sharing?: boolean;
  max_shared_users?: number;
  user_search?: UserSearch;
  initial_target?: Target;
}

export interface Config extends Pick<BaseConfig, "page"> {
  self_service: SelfService;
}

export interface Certificate {
  device_identifier: string | null;
  device_description: string | null;
  valid_from: string;
  valid_to: string;
  revoked_at: string | null;
  serial: string;
  subject: string;
  subject_der: string;
  issuer: string;
  issuer_der: string;
}

export interface Device {
  uuid: string;
  host_name?: string | null;
  mac_address?: string | null;
  shared_with_users?: string[] | null;
}

export interface DeviceList {
  items: Device[];
}

export interface UserMatch {
  display_name?: string | null;
  username: string;
}

export interface UserMatchList {
  items: UserMatch[];
  cursor?: string | null;
}

export type ApiParams = {[name: string]: string | number};

export interface ApiResponse<T = any> {
  status: number;
  data?: T;
}

export type ApiMethod = "GET" | "POST";

export type ApiFn = <T = any, U = any>(
  method: ApiMethod,
  url: string,
  params?: ApiParams,
  data?: U
) => Promise<ApiResponse<T>>;

export interface Test {
  api?: ApiFn;
}

export interface Props {
  config: Config;
  test?: Test;
}
