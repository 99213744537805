<script lang="ts">
  import {afterUpdate} from "svelte";
  import Translate from "./translate/Translate.svelte";

  let class_name: string | undefined = undefined;
  export {class_name as class};

  let collapse = true;
  let oversized = false;
  let overflowed = false;
  let content: HTMLElement;
  let client_width: number | undefined = undefined;
  let client_height: number | undefined = undefined;

  const more = () => {
    collapse = false;
  };
  const less = () => {
    collapse = true;
  };
  const get_overflowed = () => content.scrollHeight > content.clientHeight || content.scrollWidth > content.clientWidth;
  const get_oversized = () => content.clientHeight > 300;

  function resized(a?: number, b?: number) {
    overflowed = get_overflowed();
    oversized = get_oversized();
  }

  // DO_NOT_MERGE: How to react to changes in the scroll height and width (they do not seem to be supported by Svelte)?
  $: content && resized(client_height, client_width);

  afterUpdate(resized);
</script>

<div class={class_name}>
  <div class:more-box-collapsed={collapse} class="skin more-box">
    <div
      bind:this={content}
      on:resize={() => resized(client_width, client_height)}
      bind:clientWidth={client_width}
      bind:clientHeight={client_height}
      class="skin more-box-inner"
    >
      <slot />
    </div>
    {#if oversized || overflowed}
      <p class="skin more-box-buttons text-right">
        {#if !oversized && overflowed}
          <!-- svelte-ignore a11y-missing-attribute (href) a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <a on:click={more} class="btn btn-xs btn-primary skin more-box-button"><Translate>More</Translate></a>
        {:else if oversized}
          <!-- svelte-ignore a11y-missing-attribute (href) a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <a on:click={less} class="btn btn-xs btn-primary skin more-box-button"><Translate>Less</Translate></a>
        {/if}
      </p>
    {/if}
  </div>
</div>
