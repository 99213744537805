<script context="module" lang="ts">
  import CustomHtml from "$lib/CustomHtml.svelte";
  import CustomText from "$lib/CustomText.svelte";
  import {_, Translate, TranslateParam} from "$lib/translate";
  import type {TranslateFn} from "$lib/translate";
  import type {Config, Test} from "./VerifyLogin.types";
  export * from "./VerifyLogin.types";

  // Reactive update function, as in: `$: ... update(config, ...)`
  function update(config: Config, _: TranslateFn) {
    return {
      page_title: config.page.verify_title || _("Verify Account"),
    };
  }
</script>

<script lang="ts">
  export let config: Config;
  export let test: Test | undefined = undefined;

  $: ({page_title} = update(config, $_));
  $: hidden = test?.show_hidden_inputs ? "text" : "hidden";
</script>

<svelte:head>
  <title>{page_title}</title>
</svelte:head>

<div class="skin row limit-columns-1" id="cg-guest-verify-login">
  <div class="skin column">
    <div class="form-verify skin content">
      <div class="skin controls narrow">
        <h2 class="form-verify-heading">{page_title}</h2>
        <form name="approve_form" method="POST" action="verify-login">
          <input value={config.login.token} name="token" type={hidden} />
          <div class="panel panel-info">
            <div class="panel-heading skin align">
              <p>
                <Translate>
                  If you registered the email address shown below, <strong>press</strong> the
                  <TranslateParam name="verify">
                    <strong>
                      <CustomText text={config.page.verify_button_title}><Translate>Verify</Translate></CustomText>
                    </strong>
                  </TranslateParam>
                  button.
                </Translate>
              </p>
              <p>
                <Translate>
                  If you <strong>did not register</strong> the email address shown below, ignore this request and close the
                  browser window.
                </Translate>
              </p>
              <p><Translate>The email address being registered is:</Translate></p>
            </div>
            <div class="panel-body text-center"><strong>{config.login.username}</strong></div>
          </div>

          <div class="form-group">
            <button type="submit" class="btn btn-lg btn-primary btn-block" id="verify_action">
              <CustomText text={config.page.verify_button_title}><Translate>Verify</Translate></CustomText>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
