<script context="module" lang="ts">
  import {createEventDispatcher} from "svelte";
  import Chip from "./Chip.svelte";
  import type {Props as ChipProps} from "./Chip.svelte";

  export interface Props {
    items?: any[];
    type?: ChipProps["type"];
    noclose?: ChipProps["noclose"];
    list_tag?: string;
    list_class?: string;
    list_style?: string;
    item_tag?: string;
    item_class?: string;
    item_style?: string;
  }

  export interface ItemEvent<T = any> {
    item: T;
    index: number;
  }
</script>

<script lang="ts">
  export let items: Props["items"] = undefined;
  export let type: Props["type"] = undefined;
  export let noclose: Props["noclose"] = undefined;
  export let list_tag: Props["list_tag"] = undefined;
  export let list_class: Props["list_class"] = undefined;
  export let list_style: Props["list_style"] = undefined;
  export let item_tag: Props["item_tag"] = undefined;
  export let item_class: Props["item_class"] = undefined;
  export let item_style: Props["item_style"] = undefined;

  const dispatch = createEventDispatcher<{click: ItemEvent; close: ItemEvent}>();

  function handle(type: "click" | "close", item: any, index: number) {
    function handler(event: Event) {
      if (!dispatch(type, {item, index}, {cancelable: true})) {
        event.preventDefault();
      }
    }
    return handler;
  }
</script>

<svelte:element this={list_tag || "div"} class={list_class ?? "chip-list"} style={list_style}>
  {#if items}
    {#each items as item, index}
      <svelte:element this={item_tag || "span"} class={item_class ?? "chip-list-item"} style={item_style}>
        <Chip {type} {noclose} on:click={handle("click", item, index)} on:close={handle("close", item, index)}>
          <svelte:fragment slot="content">
            <slot name="content" {item} />
          </svelte:fragment>
        </Chip>
      </svelte:element>
    {/each}
  {/if}
</svelte:element>

<style>
  .chip-list {
    margin: -5px;
  }
  .chip-list-item {
    display: inline-block;
    margin: 5px;
    vertical-align: middle;
  }
</style>
