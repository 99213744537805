<script context="module" lang="ts">
  import ClipboardCopy from "$lib/ClipboardCopy.svelte";
  import Errors from "$lib/Errors.svelte";
  import {_, Translate, TranslateParam} from "$lib/translate";
  import type {Config} from "./WifiPassword.types";
  export * from "./WifiPassword.types";
</script>

<script lang="ts">
  import LightboxPanel from "$lib/LightboxPanel.svelte";

  export let config: Config;

  let show_confirm_regenerate_mpsk = false;

  function request_regenerate_mpsk() {
    show_confirm_regenerate_mpsk = true;
  }

  function cancel_regenerate_mpsk() {
    show_confirm_regenerate_mpsk = false;
  }

  $: page_title = $_("Wi-Fi Password");
</script>

<svelte:head>
  <title>{page_title}</title>
</svelte:head>

<div class="skin row limit-columns-1" id="cg-guest-wifi-password">
  <div class="skin column">
    <div class="form-signin skin content">
      <div class="skin controls narrow">
        <h2 class="form-signin-heading">{page_title}</h2>
        <Errors errors={config.login.errors?.general} id="wifi_password_general_errors" />
        {#if !config.login.errors?.general}
          {#if config.login.organization_name}
            <p>
              <Translate>
                The <TranslateParam name="network_name"><strong>{config.login.ssid}</strong></TranslateParam> Wi-Fi network
                is provided by
                <TranslateParam name="organization_name"
                  ><strong id="organization_name">{config.login.organization_name}</strong></TranslateParam
                >.
              </Translate>
            </p>
          {/if}

          <div class="panel panel-info">
            <div class="panel-heading">
              <Translate>
                Your password for the
                <TranslateParam name="network_name">
                  <strong id="wifi_ssid">{config.login.ssid}</strong>
                </TranslateParam> Wi-Fi network is:
              </Translate>
            </div>

            <div class="panel-body text-center">
              <p><strong id="wifi_mpsk">{config.login.mpsk}</strong></p>
              <p>
                <ClipboardCopy id="copy_button" data={config.login.mpsk}>
                  <Translate>Copy</Translate>
                </ClipboardCopy>
              </p>
            </div>

            <div class="panel-footer">
              <span class="glyphicon glyphicon-circle-arrow-right" />
              <span>
                <Translate>
                  Connect your device to the
                  <TranslateParam name="network_name">{config.login.ssid}</TranslateParam>
                  Wi-Fi network and enter this password when prompted.
                </Translate>
              </span>
            </div>
          </div>

          <div class="alert alert-warning">
            <span class="glyphicon glyphicon-exclamation-sign" />
            <span>
              <Translate>
                This Wi-Fi password uniquely identifies <strong>you</strong> on this network.
                <strong>Do not share it</strong> with anyone else.
              </Translate>
            </span>
          </div>

          <div class="panel panel-default">
            <div class="panel-body">
              <p>
                <span>
                  <Translate>
                    If your Wi-Fi password has been compromised, you should generate a new replacement password.
                  </Translate>
                </span>
              </p>
              <p>
                <span>
                  <Translate>
                    Once regenerated, all of your devices that used the old password will need to be updated to use the
                    new password.
                  </Translate>
                </span>
              </p>
              <p>
                <span class="glyphicon glyphicon-exclamation-sign" />
                <span><Translate>This action cannot be undone.</Translate></span>
              </p>

              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <!-- svelte-ignore a11y-no-static-element-interactions -->
              <span
                on:click|preventDefault={request_regenerate_mpsk}
                class="btn btn-lg btn-block btn-wrap btn-danger"
                id="regenerate_action"
              >
                <Translate>Regenerate Wi-Fi Password</Translate>
              </span>

              <!-- REGENERATE CONFIRMATION -->
              <form name="wifi_password_form" method="POST" action="wifi-password">
                {#if show_confirm_regenerate_mpsk}
                  <LightboxPanel alert_level="danger" id="regenerate_confirmation">
                    <svelte:fragment slot="heading">
                      <span class="glyphicon glyphicon-question-sign" />
                      <span><Translate><strong>WARNING:</strong> This action cannot be undone.</Translate></span>
                    </svelte:fragment>
                    <svelte:fragment>
                      <p>
                        <Translate>Are you sure that you want to revoke and regenerate the Wi-Fi Password?</Translate>
                      </p>
                      <div class="text-right">
                        <!-- svelte-ignore a11y-click-events-have-key-events -->
                        <!-- svelte-ignore a11y-no-static-element-interactions -->
                        <span
                          on:click|preventDefault={cancel_regenerate_mpsk}
                          class="btn btn-default"
                          id="regenerate_action_cancel"
                        >
                          <Translate>Cancel</Translate>
                        </span>

                        <button type="submit" class="btn btn-danger" id="regenerate_action_confirmation">
                          <span><Translate>Regenerate</Translate></span>
                        </button>
                      </div>
                    </svelte:fragment>
                  </LightboxPanel>
                {/if}
              </form>
            </div>
          </div>
        {/if}
        {#if config.login.show_self_service}
          <div class="form-group">
            <a
              href="self-service?target=devices"
              class="btn btn-md btn-link btn-block btn-wrap"
              id="self_service_action"
            >
              <Translate>Manage my devices</Translate>
            </a>
          </div>
        {/if}
      </div>
    </div>
  </div>
</div>
