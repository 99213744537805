<script context="module" lang="ts">
  import {onMount} from "svelte";
  import CgDate from "$lib/CgDate.svelte";
  import Dropdown from "$lib/Dropdown.svelte";
  import LightboxPanel from "$lib/LightboxPanel.svelte";
  import {defer} from "$lib/promise";
  import type {Deferrable} from "$lib/promise";
  import {_, Translate, type TranslateFn} from "$lib/translate";
  import type {ApiFn, Certificate} from "../SelfService.types";

  export * from "../SelfService.types";

  interface CertificateOptions {
    show_detail: boolean;
    selected: boolean;
  }

  interface FilterOption {
    checked: boolean;
  }
  interface FilterOptions {
    valid_or_pending: FilterOption;
    expired: FilterOption;
    revoked: FilterOption;
  }
  type FilterKey = keyof FilterOptions;
  const filter_options_keys: FilterKey[] = ["valid_or_pending", "expired", "revoked"];

  function as_filter_key(option: any): FilterKey {
    return option as FilterKey;
  }

  function filter_label(_: TranslateFn, key: FilterKey): string {
    switch (key) {
      case "valid_or_pending":
        return _("Show valid credentials");
      case "expired":
        return _("Show expired credentials");
      case "revoked":
        return _("Show revoked credentials");
    }
  }

  type CertificateOptionsBySerial = {[serial: string]: CertificateOptions};

  function certificate_status(cert: Certificate) {
    if (cert.revoked_at) {
      return "revoked";
    } else if (Date.now() >= Date.parse(cert.valid_to)) {
      return "expired";
    } else if (Date.now() <= Date.parse(cert.valid_from)) {
      return "pending";
    } else {
      return "valid";
    }
  }

  function show_revoke_certificate(cert: Certificate) {
    return !cert.revoked_at;
  }

  function update({
    certificates,
    certificate_options,
    refreshing_certs,
    revoking_certs,
    filter_options,
  }: {
    certificates: Certificate[];
    certificate_options: CertificateOptionsBySerial;
    refreshing_certs: boolean;
    revoking_certs: boolean;
    filter_options: FilterOptions;
  }) {
    // Convenience values used by derived functions
    const can_revoke_certificates = !refreshing_certs && !revoking_certs;

    // Derived functions block level scope
    {
      function can_revoke_certificate(cert: Certificate) {
        return can_revoke_certificates;
      }

      // Derived value block level scope
      {
        const num_certificates_selected = Object.values(certificate_options).filter(
          (options) => options.selected
        ).length;
        const num_certificates = certificates ? certificates.length : 0;
        const show_revoke_selected = num_certificates_selected > 0;
        const show_revoke_all = num_certificates > 0 && num_certificates_selected === 0;
        const filtered_certificates = certificates.filter((cert) => {
          const cert_status = certificate_status(cert);
          for (const key of filter_options_keys) {
            if (key.includes(cert_status) && filter_options[key].checked) {
              return true;
            }
          }
          return false;
        });

        return {
          num_certificates_selected,
          num_certificates,
          certificate_selection_state:
            num_certificates_selected === 0 ? "none" : num_certificates_selected === num_certificates ? "all" : "some",
          show_no_credentials: certificates && certificates.length === 0,
          can_refresh_certificates: !refreshing_certs && !revoking_certs,
          refreshing_certificates: refreshing_certs,
          can_revoke_certificates,
          can_revoke_certificate,
          show_revoke_selected,
          show_revoke_all,
          show_revoke_selectors: show_revoke_selected || show_revoke_all,
          disable_revoke_selectors: refreshing_certs || revoking_certs,
          filtered_certificates,
        };
      }
    }
  }
</script>

<script lang="ts">
  export let cg_self_service_api: ApiFn;

  // Initialise
  let certificates: Certificate[] = [];
  let certificate_options: CertificateOptionsBySerial = {};
  let refreshing_certs = false;
  let revoking_certs = false;
  let error: {code: "certificates" | "revoke_certificate"} | undefined = undefined;
  let select_all = false;
  let deferred_revocation: Deferrable<void> | undefined = undefined;
  let filter_options: FilterOptions = {
    valid_or_pending: {checked: true},
    expired: {checked: false},
    revoked: {checked: false},
  };

  function api_failure(code: "certificates" | "revoke_certificate") {
    error = {code};
    return Promise.reject(code);
  }

  function update_certificates(certs: Certificate[]) {
    // Order certificates here so that revoke selected operates in the order
    // as displayed in the UI.
    certs.sort((a, b) => {
      const av = a.valid_from;
      const bv = b.valid_from;
      return av < bv ? 1 : av === bv ? 0 : -1;
    });
    certificates = certs;

    // Copy across extra certificate UI settings for each previously seen
    // certificate.
    certificate_options = Object.fromEntries(
      certs.map((cert) => [
        cert.serial,
        certificate_options[cert.serial] || {
          show_detail: false,
          selected: false,
        },
      ])
    );
  }

  function refresh_certificates() {
    if (!refreshing_certs) {
      refreshing_certs = true;
      cg_self_service_api<{certificates: Certificate[]}>("GET", "certificates").then(
        // fulfilled
        function (response) {
          refreshing_certs = false;
          if (response.status == 200 && response.data) {
            update_certificates(response.data.certificates);
          } else {
            return api_failure("certificates");
          }
        },
        // rejected
        function (response) {
          refreshing_certs = false;
          return api_failure("certificates");
        }
      );
    }
  }

  function revoke_certificates(certs: Certificate[]) {
    function revoke_next_certificate(certs: Certificate[]): Promise<void> {
      if (certs.length === 0) {
        return Promise.resolve();
      }

      const cert = certs[0];
      certs = certs.slice(1);

      return cg_self_service_api("GET", "revoke_certificate", {
        issuer: cert.issuer_der,
        subject: cert.subject_der,
        serial: cert.serial,
      }).then(
        // fullfilled
        function (response) {
          update_certificate_options_for(cert, {selected: false});
          return revoke_next_certificate(certs);
        },
        // rejected
        function (response) {
          return api_failure("revoke_certificate");
        }
      );
    }

    function confirm_revoke_certificates(certs: Certificate[]): Promise<Certificate[]> {
      deferred_revocation = defer<void>();
      return deferred_revocation.promise.then(
        // resolved
        function () {
          deferred_revocation = undefined;
          return certs;
        },
        // rejected
        function (reason) {
          deferred_revocation = undefined;
          return Promise.reject(reason);
        }
      );
    }

    if (!revoking_certs) {
      revoking_certs = true;
      certs = certs.slice();
      confirm_revoke_certificates(certs)
        .then(revoke_next_certificate)
        .then(
          // fullfilled
          function () {
            revoking_certs = false;
          },
          // rejected
          function (reason) {
            revoking_certs = false;
            return reason;
          }
        )
        .then(
          // fullfilled (always called as both above promises resolve as
          // fulfilled)
          function (reason) {
            if (reason !== "cancel_revocation") {
              refresh_certificates();
            }
          }
        );
    }
  }

  function apply_select_all() {
    Object.values(certificate_options).map((options) => {
      options.selected = select_all;
    });
    certificate_options = certificate_options;
  }

  function dismiss_error() {
    error = undefined;
  }

  function toggle_select_all(event: Event) {
    select_all = !select_all;
    event.stopPropagation();

    apply_select_all();
  }

  function select_none() {
    select_all = false;

    apply_select_all();
  }

  function cancel_revocation() {
    if (deferred_revocation) {
      deferred_revocation.reject("cancel_revocation");
    }
  }

  function confirm_revocation() {
    if (deferred_revocation) {
      deferred_revocation.resolve();
    }
  }

  function toggle_certificate_detail(cert: Certificate) {
    certificate_options[cert.serial].show_detail = !certificate_options[cert.serial].show_detail;
  }

  function certificate_options_for(cert: Certificate) {
    return certificate_options[cert.serial];
  }

  function update_certificate_options_for(cert: Certificate, updates?: Partial<CertificateOptions>) {
    const options = certificate_options[cert.serial];
    if (options && updates) {
      if (typeof updates.selected === "boolean") {
        options.selected = updates.selected;
      }
      if (typeof updates.show_detail === "boolean") {
        options.show_detail = updates.show_detail;
      }
      certificate_options[cert.serial] = options;
    }
  }

  function change_certificate_selection(cert: Certificate) {
    select_all = num_certificates_selected === num_certificates;
  }

  function revoke_all_certificates() {
    revoke_certificates(certificates);
  }

  function revoke_selected_certificates() {
    const revoke = certificates.filter((cert) => certificate_options_for(cert).selected);

    revoke_certificates(revoke);
  }

  function revoke_certificate(cert: Certificate) {
    revoke_certificates([cert]);
  }

  // Reactivity
  $: ({
    num_certificates_selected,
    num_certificates,
    certificate_selection_state,
    show_no_credentials,
    can_refresh_certificates,
    refreshing_certificates,
    can_revoke_certificates,
    can_revoke_certificate,
    show_revoke_selected,
    show_revoke_all,
    show_revoke_selectors,
    disable_revoke_selectors,
    filtered_certificates,
  } = update({certificates, certificate_options, refreshing_certs, revoking_certs, filter_options}));
  $: show_error = !!error;
  $: show_confirm_revoke_certificates = !!deferred_revocation;
  $: if (!!cg_self_service_api) {
    // Also refresh certificates when the API function changes, which should
    // only occur when in Storybook.
    refresh_certificates();
  }

  onMount(refresh_certificates);
</script>

<h3 id="network_credentials_title"><Translate>Network credentials</Translate></h3>
<p>
  <Translate>
    Below is a list of network credentials that have been <strong>issued to you</strong> for use on
    <strong>your devices</strong>.
  </Translate>
</p>
<p>
  <Translate>
    If your device has been lost, or its security breached, you <strong>should</strong> revoke any credentials that have
    been issued to it.
  </Translate>
</p>
<div>
  <div class="btn-toolbar" style="margin-bottom: 0.5em">
    {#if show_revoke_selectors}
      <span class="btn-group btn-group-sm">
        <button
          on:click|preventDefault={toggle_select_all}
          disabled={disable_revoke_selectors}
          class:active={certificate_selection_state === "all"}
          class="btn btn-default"
          style="padding-left: 5px"
          id="select_all"
        >
          <input
            on:click={toggle_select_all}
            bind:checked={select_all}
            disabled={disable_revoke_selectors}
            style="margin: 0; margin-right: 5px; vertical-align: text-top"
            type="checkbox"
            id="select_all_checkbox"
          />
          <span><Translate>All</Translate></span>
        </button>
        <button
          on:click={() => select_none()}
          disabled={disable_revoke_selectors}
          class:active={certificate_selection_state === "none"}
          class="btn btn-default"
          id="select_none"
        >
          <Translate>None</Translate>
        </button>
      </span>
    {/if}
    <span id="filter_credentials" class="btn-group btn-group-sm pull-right">
      <Dropdown
        options={filter_options_keys}
        toggle_aria_label={$_("Filter by Status")}
        toggle_tabindex={0}
        mode="btn-group"
        menu_class="dropdown-menu-right"
      >
        <svelte:fragment slot="toggle">
          <span class="glyphicon glyphicon-option-vertical" />
        </svelte:fragment>
        <svelte:fragment slot="option" let:option>
          <!-- svelte-ignore a11y-missing-attribute -->
          <a>
            <label>
              <input
                id="cb-{option}"
                value={option}
                type="checkbox"
                bind:checked={filter_options[as_filter_key(option)].checked}
              />
              <span>{filter_label($_, option)}</span>
            </label>
          </a>
        </svelte:fragment>
      </Dropdown>
    </span>
    <span class="btn-group btn-group-sm pull-right">
      {#if show_revoke_selected}
        <button
          on:click={() => revoke_selected_certificates()}
          disabled={!can_revoke_certificates}
          class="btn btn-danger"
          id="revoke_selected"
        >
          <span class="glyphicon glyphicon-ban-circle" /> <span><Translate>Revoke Selected</Translate></span>
        </button>
      {/if}
      {#if show_revoke_all}
        <button
          on:click={() => revoke_all_certificates()}
          disabled={!can_revoke_certificates}
          class="btn btn-danger"
          id="revoke_all"
        >
          <span class="glyphicon glyphicon-ban-circle" /> <span><Translate>Revoke All</Translate></span>
        </button>
      {/if}
    </span>
    <span class="btn-group btn-group-sm pull-right">
      <button
        disabled={!can_refresh_certificates}
        on:click={() => refresh_certificates()}
        class="btn btn-default"
        id="refresh_certificates"
      >
        <span class="glyphicon glyphicon-refresh" />
        {#if !refreshing_certificates}<span><Translate>Refresh</Translate></span>{/if}
        {#if refreshing_certificates}<span><Translate>Loading…</Translate></span>{/if}
      </button>
    </span>
  </div>
  {#if show_error}
    <!-- ERROR -->
    <LightboxPanel alert_level="danger" id="error_alert">
      <svelte:fragment slot="heading">
        <span class="glyphicon glyphicon-exclamation-sign" />
        <span><Translate>Error</Translate></span>
      </svelte:fragment>
      <svelte:fragment>
        <div>
          {#if error?.code === "certificates"}
            <span id="error_certificates"><Translate>Failed to retrieve your network credentials.</Translate></span>
          {/if}
          {#if error?.code === "revoke_certificate"}
            <span id="error_revoke_certificate">
              <Translate>Failed to revoke your selected network credentials.</Translate>
            </span>
          {/if}
        </div>
        <div class="text-right">
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <span on:click={() => dismiss_error()} class="btn btn-danger" id="dismiss_error_action">
            <Translate>Dismiss</Translate>
          </span>
        </div>
      </svelte:fragment>
    </LightboxPanel>
  {/if}
  {#if show_confirm_revoke_certificates}
    <!-- REVOKE CONFIRMATION -->
    <LightboxPanel alert_level="danger" id="revoke_confirmation">
      <svelte:fragment slot="heading">
        <span class="glyphicon glyphicon-question-sign" />
        <span><Translate><strong>WARNING:</strong> This action cannot be undone.</Translate></span>
      </svelte:fragment>
      <svelte:fragment>
        <p><Translate>These network credentials will be <strong>revoked</strong>.</Translate></p>
        <p>
          <Translate>
            Any <strong>device</strong> using these credentials will be <strong>disconnected</strong> from the
            associated network and <strong>denied access</strong> in future.
          </Translate>
        </p>
        <p>
          <Translate>
            For an affected device to access to the network again, you will need to provision it with a new network
            profile.
          </Translate>
        </p>
        <p>
          <Translate>
            Are you sure that you want to revoke these network credentials? This action cannot be undone.
          </Translate>
        </p>
        <div class="text-right">
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <span on:click={() => cancel_revocation()} class="btn btn-default" id="revoke_cancel_action">
            <Translate>Cancel</Translate>
          </span>
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <span on:click={() => confirm_revocation()} class="btn btn-danger" id="revoke_confirm_action">
            <Translate>Revoke</Translate>
          </span>
        </div>
      </svelte:fragment>
    </LightboxPanel>
  {/if}
  <!-- NO CERTIFICATES -->
  {#if show_no_credentials}
    <div id="no_credentials">
      <div class="alert alert-info">
        <span>
          <span class="glyphicon glyphicon-info-sign" />
        </span>
        <span><Translate>You have not been issued any network credentials.</Translate></span>
      </div>
    </div>
  {/if}
  <!-- LIST OF CERTIFICATES -->
  {#if !show_no_credentials}
    <div class="list-group" id="network_credentials">
      {#each filtered_certificates as cert}
        {@const status = certificate_status(cert)}
        <div
          class:nc-selected={certificate_options_for(cert).selected}
          class="list-group-item network-credential nc-{certificate_status(cert)}"
          style="padding: 0"
        >
          <!-- header -->
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <div
            on:click={() => toggle_certificate_detail(cert)}
            class="clickable nc-header"
            style="display: flex; flex-wrap: wrap; padding: 10px 15px"
          >
            <span style="flex: initial; margin-right: 5px">
              <input
                on:click|stopPropagation={() => {}}
                bind:checked={certificate_options[cert.serial].selected}
                on:change={() => change_certificate_selection(cert)}
                disabled={disable_revoke_selectors}
                type="checkbox"
                class="nc-selector"
                style="margin: 0"
              />
              {#if !cert.device_description}
                <span class="nc-device-description"><Translate>Unknown OS</Translate></span>
              {:else}
                <strong class="nc-device-description">{cert.device_description}</strong>
              {/if}
            </span>
            <span class="text-right" style="flex: auto">
              <span><CgDate date={cert.valid_from} format={["mediumDate", "shortTime"]} /></span>
              <span class="nc-status">
                {#if status === "valid"}
                  <span class="label label-primary"><Translate>Valid</Translate></span>
                {:else if status === "pending"}
                  <span class="label label-default"><Translate>Pending</Translate></span>
                {:else if status === "expired"}
                  <span class="label label-warning"><Translate>Expired</Translate></span>
                {:else if status === "revoked"}
                  <span class="label label-danger"><Translate>Revoked</Translate></span>
                {/if}
              </span>
              <span class="label label-default clickable">
                <span
                  class:glyphicon-menu-up={certificate_options_for(cert).show_detail}
                  class:glyphicon-menu-down={!certificate_options_for(cert).show_detail}
                  class="glyphicon"
                />
              </span>
            </span>
          </div>
          <!-- body -->
          {#if certificate_options_for(cert).show_detail}
            <div class="nc-body">
              <table class="table table-condensed small" style="margin: 0">
                <tbody>
                  <tr>
                    <td class="text-muted text-right" style="padding-left: 15px"><Translate>Device</Translate></td>
                    <td style="padding-right: 15px">
                      {#if !cert.device_description}
                        <span class="nc-device-description">
                          <Translate>Unknown OS</Translate>
                        </span>
                      {:else}
                        <span class="nc-device-description">{cert.device_description}</span>
                      {/if}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-muted text-right" style="padding-left: 15px"><Translate>Status</Translate></td>
                    <td style="padding-right: 15px">
                      <span class="nc-status">
                        {#if status === "valid"}
                          <span class="label label-primary"><Translate>Valid</Translate></span>
                        {:else if status === "pending"}
                          <span class="label label-default"><Translate>Pending</Translate></span>
                        {:else if status === "expired"}
                          <span class="label label-warning"><Translate>Expired</Translate></span>
                        {:else if status === "revoked"}
                          <span class="label label-danger"><Translate>Revoked</Translate></span>
                        {/if}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-muted text-right" style="padding-left: 15px"><Translate>Issued at</Translate></td>
                    <td style="padding-right: 15px"
                      ><CgDate date={cert.valid_from} format={["fullDate", "shortTime"]} /></td
                    >
                  </tr>
                  <tr>
                    <td class="text-muted text-right" style="padding-left: 15px; border-bottom: 1px solid #ddd">
                      <Translate>Expires at</Translate>
                    </td>
                    <td style="padding-right: 15px; border-bottom: 1px solid #ddd">
                      <CgDate date={cert.valid_to} format={["fullDate", "shortTime"]} />
                    </td>
                  </tr>
                  {#if cert.revoked_at}
                    <tr>
                      <td class="text-muted text-right" style="padding-left: 15px; border-bottom: 1px solid #ddd">
                        <Translate>Revoked at</Translate>
                      </td>
                      <td style="padding-right: 15px; border-bottom: 1px solid #ddd">
                        <CgDate date={cert.revoked_at} format={["fullDate", "shortTime"]} />
                      </td>
                    </tr>
                  {/if}
                </tbody>
              </table>
              <!-- footer -->
              <div class="text-right" style="padding: 10px 15px">
                <button on:click={() => toggle_certificate_detail(cert)} class="btn btn-sm btn-default nc-close">
                  <Translate>Close</Translate>
                </button>
                {#if show_revoke_certificate(cert)}
                  <button
                    on:click={() => revoke_certificate(cert)}
                    disabled={!can_revoke_certificate(cert)}
                    class="btn btn-sm btn-danger nc-revoke"
                  >
                    <span class="glyphicon glyphicon-ban-circle" /> <span><Translate>Revoke</Translate></span>
                  </button>
                {/if}
              </div>
            </div>
          {/if}
        </div>
      {/each}
    </div>
  {/if}
</div>

<style>
  button:focus {
    outline: none !important;
  }
</style>
