<script lang="ts">
  // Chose 'dumpurify' over 'sanitize-html' due to size, and dompurify is specifically focused against XSS.
  //
  // - dompurify: ~9kb/22kB compressed/uncompressed
  // - sanitize-html: ~80kB/190kB compressed/uncompressed
  import {default as DOMPurify} from "dompurify";

  export let html: string;

  $: sanitized = DOMPurify.sanitize(html);
</script>

{@html sanitized}
