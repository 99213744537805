import "./css";
import App from "./App.svelte";

const id = "app";
const target = document.getElementById(id);
let app: App | undefined;

if (target) {
  app = new App({
    target,
  });
} else {
  throw `failed to find svelte app HTML element with id: ${id}`;
}

export default app;
