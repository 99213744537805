<script context="module" lang="ts">
  import Dropdown from "./Dropdown.svelte";
  import {_} from "$lib/translate";
  import type {TranslateFn} from "$lib/translate";
  import type {ProfileInfo} from "$lib/config/guest/types";

  interface ProfileMenuOptionBase {
    kind: "text" | "link" | "divider";
  }
  interface ProfileMenuTextOption extends ProfileMenuOptionBase {
    kind: "text";
    id: string;
    text: string;
  }
  interface ProfileMenuLinkOption extends ProfileMenuOptionBase {
    kind: "link";
    id: string;
    text: string;
    href: string;
  }
  interface ProfileMenuDividerOption extends ProfileMenuOptionBase {
    kind: "divider";
  }

  type ProfileMenuOption = ProfileMenuTextOption | ProfileMenuLinkOption | ProfileMenuDividerOption;

  function update(profile: ProfileInfo | undefined, _: TranslateFn) {
    const section1: ProfileMenuOption[] = [];
    const section2: ProfileMenuOption[] = [];
    let profile_menu: ProfileMenuOption[] = [];
    if (profile) {
      if (profile.is_anonymous === true) {
        section1.push({kind: "text", id: "username", text: _("Anonymous")});
      } else if (profile.username && profile.display_name) {
        if (profile.username === profile.display_name) {
          section1.push({kind: "text", id: "display_name", text: profile.display_name});
        } else {
          section1.push({kind: "text", id: "display_name", text: profile.display_name});
          section1.push({kind: "text", id: "username", text: profile.username});
        }
      } else if (profile.username) {
        section1.push({kind: "text", id: "username", text: profile.username});
      } else if (profile.display_name) {
        section1.push({kind: "text", id: "display_name", text: profile.display_name});
      }
      if (profile.signout_url) {
        if (section1.length) {
          section2.push({kind: "divider"});
        }
        section2.push({kind: "link", id: "signout", text: _("Sign out"), href: profile.signout_url});
      }
      profile_menu = [...section1, ...section2];
    }

    return {profile_menu};
  }
</script>

<script lang="ts">
  export let profile: ProfileInfo | undefined = undefined;
  let extra_classes: string | undefined = undefined;
  export {extra_classes as class};
  export let id: string;

  $: classes = "cg-profile" + (extra_classes ? ` ${extra_classes}` : "");

  $: ({profile_menu} = update(profile, $_));
</script>

{#if profile_menu.length}
  <span class={classes} {id}>
    <!-- svelte-ignore a11y-invalid-attribute -->
    <Dropdown
      options={profile_menu}
      toggle_aria_label={$_("Profile")}
      toggle_tabindex={1}
      menu_class="dropdown-menu-right"
    >
      <svelte:fragment slot="toggle">
        <span class="glyphicon glyphicon-user" />
      </svelte:fragment>
      <svelte:fragment slot="option" let:option>
        <!-- svelte-ignore a11y-missing-attribute -->
        {#if option.kind === "link"}
          <a id={id + "-signout"} href={option.href}>
            {option.text}
          </a>
        {:else if option.kind === "divider"}
          <li class="divider" />
        {:else}
          <a on:click|preventDefault id={id + "-" + option.id} href="#">
            {option.text}
          </a>
        {/if}
      </svelte:fragment>
    </Dropdown>
  </span>
{/if}
