<script context="module" lang="ts">
  import CustomHtml from "$lib/CustomHtml.svelte";
  import CustomText from "$lib/CustomText.svelte";
  import {_, Translate, TranslateParam} from "$lib/translate";
  import type {TranslateFn} from "$lib/translate";
  import type {Config} from "./VerifiedLogin.types";
  export * from "./VerifiedLogin.types";

  // Reactive update function, as in: `$: ... update(config, ...)`
  function update(config: Config, _: TranslateFn) {
    return {
      page_title: config.page.verify_title || _("Verify Account"),
    };
  }
</script>

<script lang="ts">
  export let config: Config;

  $: ({page_title} = update(config, $_));
</script>

<svelte:head>
  <title>{page_title}</title>
</svelte:head>

<div class="skin row limit-columns-1" id="cg-guest-verified-login">
  <div class="skin column">
    <div class="form-verify skin content">
      <div class="skin controls narrow">
        <h2 class="form-verify-heading">{page_title}</h2>
        <CustomHtml html={config.page.verified_account_message} class="skin align" id="verified_account_message">
          <p class="alert alert-info"><Translate>Your account has been verified.</Translate></p>
          <p>
            <Translate>
              Click the
              <TranslateParam name="continue">
                <b><CustomText text={config.page.continue_button_title}><Translate>Continue</Translate></CustomText></b>
              </TranslateParam>
              button to complete the login process.
            </Translate>
          </p>
        </CustomHtml>
        <p>
          <a href={config.login.next} class="btn btn-lg btn-primary btn-block" id="continue_action">
            <CustomText text={config.page.continue_button_title}><Translate>Continue</Translate></CustomText>
          </a>
        </p>
      </div>
    </div>
  </div>
</div>
