export function redraw(event: Event) {
  if (event.target instanceof HTMLElement) {
    const has_style = event.target.hasAttribute("style");
    const display = event.target.style.display;

    // Cause a redraw by temporarily set the element display style property to
    // none.
    //
    // This may create a style attribute on the element.
    event.target.style.display = "none";
    if (has_style) {
      // Reset the display property in the style attribute to its original
      // value, which may be the empty string.
      event.target.style.display = display;
    } else {
      // Remove the style attribute that was created by temporarily setting the
      // display property to none.
      event.target.removeAttribute("style");
    }
  }
}
