export interface Deferrable<T = any> {
  promise: Promise<T>;
  reject(reason?: any): void;
  resolve(value: T | PromiseLike<T>): void;
}

export function defer<T = any>(): Deferrable<T> {
  let resolve: Deferrable<T>["resolve"] | undefined = undefined;
  let reject: Deferrable<T>["reject"] | undefined = undefined;
  const promise = new Promise<T>((resolver, rejecter) => {
    resolve = resolver;
    reject = rejecter;
  });

  if (resolve && reject) {
    return {promise, resolve, reject};
  } else {
    throw "unexpected: promise executor was not called scynchronously";
  }
}

export function set_timeout(ms?: number): Promise<void>;
export function set_timeout<T = any>(callback: () => T | PromiseLike<T>, ms?: number): Promise<T>;
export function set_timeout<T = any>(arg1?: (() => T | PromiseLike<T>) | number, arg2?: number): Promise<T> {
  let callback: (() => T | PromiseLike<T>) | undefined = undefined;
  let ms: number | undefined = undefined;

  if (typeof arg1 === "number") {
    ms = arg1;
  } else {
    callback = arg1;
    ms = arg2;
  }

  const deferred = defer<T>();
  setTimeout(() => {
    if (callback) {
      deferred.resolve(callback());
    } else {
      (deferred as Deferrable<void>).resolve();
    }
  }, ms);

  return deferred.promise;
}
