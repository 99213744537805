<script context="module" lang="ts">
  import {Translate, TranslateParam, _} from "$lib/translate";
  import {onMount} from "svelte";
  import type {ApiFn, ApiResponse, Config, Device, DeviceList, UserMatch, UserMatchList} from "../SelfService.types";
  import {default as Search, default_network_api_delay} from "$lib/Search.svelte";
  import type {SearchResult, Selection} from "$lib/Search.svelte";
  import LightboxPanel from "$lib/LightboxPanel.svelte";
  import Data from "$lib/Data.svelte";
  import Spinner from "$lib/spinkit/Spinner.svelte";
  import {set_timeout} from "$lib/promise";
  import ChipList from "$lib/ChipList.svelte";

  interface DeviceOption {
    expanded?: boolean;
    editor?: "editing" | "saving" | "saved" | "error";
    updates?: Pick<Device, "shared_with_users">;
  }

  interface DeviceOptions {
    [uuid: string]: DeviceOption | undefined;
  }

  function title_for(device: Device) {
    return device.host_name ? device.host_name : device.mac_address ? device.mac_address : device.uuid;
  }

  function update(config: Config) {
    function is_own_username(username: string) {
      if (config.self_service.airgroup_username) {
        return username.toLowerCase() === config.self_service.airgroup_username.toLowerCase();
      } else {
        return false;
      }
    }

    {
      const user_search = config.self_service.user_search ?? "none";

      return {user_search, is_own_username};
    }
  }
</script>

<script lang="ts">
  export let config: Config;
  export let cg_self_service_api: ApiFn;

  let loading: "loading" | "loaded" | undefined = undefined;
  let devices: Device[] = [];
  let device_options: DeviceOptions = {};
  let error: {code: "list" | "save"} | undefined = undefined;

  function api_failure(code: Exclude<typeof error, undefined>["code"]) {
    error = {code};
    return Promise.reject(code);
  }

  function refresh() {
    if (loading) {
      return;
    }
    loading = "loading";
    cg_self_service_api<DeviceList>("GET", "devices")
      .then(
        (response) => {
          if (response.status === 200 && response.data) {
            loading = "loaded";
            devices = response.data.items;
          } else {
            loading = undefined;
            return api_failure("list");
          }
        },
        (reason) => {
          loading = undefined;
          return api_failure("list");
        }
      )
      .then(() => set_timeout(2000))
      .then(
        () => (loading = undefined),
        () => {}
      );
  }

  function dismiss_error() {
    error = undefined;
  }

  function option_for(device: Device) {
    return device_options[device.uuid] ?? {};
  }

  function set_option_for(device: Device, option: DeviceOption) {
    device_options[device.uuid] = option;
  }

  function update_option_for(device: Device, update: Partial<DeviceOption>) {
    const option = option_for(device);
    if ("expanded" in update && typeof update.expanded === "boolean") {
      option.expanded = update.expanded;
    }
    if ("editor" in update) {
      option.editor = update.editor;
    }
    if ("updates" in update) {
      if (update.updates) {
        if (!option.updates) {
          option.updates = {};
        }
        if ("shared_with_users" in update.updates) {
          option.updates.shared_with_users = update.updates.shared_with_users;
        }
      } else {
        option.updates = undefined;
      }
    }
    set_option_for(device, option);
  }

  function search_shared_users(search: string): Promise<SearchResult<UserMatch>> {
    if (user_search === "none") {
      return Promise.resolve({items: []});
    }

    function search_users(search: string, cursor?: string): Promise<SearchResult<UserMatch>> {
      return cg_self_service_api<UserMatchList>("GET", "users", {search, limit: 5, ...(cursor ? {cursor} : {})}).then(
        handle_response
      );
    }

    function handle_response(response: ApiResponse<UserMatchList>): Promise<SearchResult<UserMatch>> {
      if (response.status === 200 && response.data) {
        const {items, cursor} = response.data;
        const result: SearchResult<UserMatch> = {items};
        if (cursor) {
          result.next = () => search_users(search, cursor);
        }
        return Promise.resolve(result);
      } else {
        return Promise.reject(response);
      }
    }

    return search_users(search);
  }

  function user_match_key(user: UserMatch): string {
    return user.username;
  }

  function as_user(item: any): UserMatch {
    return item;
  }

  function select_shared_user_for(device: Device) {
    function select(event: CustomEvent<Selection<UserMatch>>) {
      const user = event.detail.type === "value" ? event.detail.value.trim() : event.detail.item.username;
      const option = option_for(device);
      if (!option.updates) {
        option.updates = {};
      }
      if (!option.updates.shared_with_users) {
        option.updates.shared_with_users = [];
      }
      let found = false;
      const user_lc = user.toLowerCase();
      for (const other of option.updates.shared_with_users) {
        if (user_lc === other.toLowerCase()) {
          found = true;
          break;
        }
      }
      const is_self = is_own_username(user);
      if (!found && !is_self) {
        option.updates.shared_with_users.push(user);
      }
      set_option_for(device, option);

      if (is_self) {
        event.preventDefault();
      }
    }

    return select;
  }

  function remove_shared_user(device: Device, index: number) {
    const option = option_for(device);
    if (option.updates?.shared_with_users && index < option.updates.shared_with_users.length) {
      option.updates.shared_with_users.splice(index, 1);
    }
    set_option_for(device, option);
  }

  function toggle_expand(device: Device, close_editor: boolean) {
    const option = option_for(device);
    if (option.editor && !close_editor) {
      return;
    }
    option.expanded = !option.expanded;
    option.editor = undefined;
    option.updates = undefined;
    set_option_for(device, option);
  }

  function cancel(device: Device) {
    update_option_for(device, {
      expanded: true,
      editor: undefined,
      updates: undefined,
    });
  }

  function edit(device: Device) {
    update_option_for(device, {
      expanded: true,
      editor: "editing",
      updates: {shared_with_users: device.shared_with_users ? [...device.shared_with_users] : []},
    });
  }

  function save(device: Device) {
    let updates = {...option_for(device).updates};
    if (updates.shared_with_users) {
      updates.shared_with_users = updates.shared_with_users.map((user) => user.trim()).filter((user) => !!user);
    }

    update_option_for(device, {editor: "saving"});
    cg_self_service_api<DeviceList>("POST", `devices/${device.uuid}`, undefined, updates)
      .then(
        (response) => {
          if (response.status >= 200 && response.status <= 299) {
            device.shared_with_users = updates.shared_with_users;
            devices = devices;
            update_option_for(device, {editor: "saved"});
          } else {
            update_option_for(device, {editor: "error"});
            return api_failure("save");
          }
        },
        (reason) => {
          update_option_for(device, {editor: "error"});
          return api_failure("save");
        }
      )
      // transition from "saved" back to "editing" after a brief delay.
      .then(() => set_timeout(2000))
      .then(
        () => {
          const option = option_for(device);
          if (option.editor !== undefined) {
            option.editor = undefined;
            option.updates = undefined;
            set_option_for(device, option);
          }
        },
        () => {}
      );
  }

  $: if (!!cg_self_service_api) {
    // Also refresh devices when the API function changes, which should only
    // occur when in Storybook.
    refresh();
  }
  $: ({user_search, is_own_username} = update(config));

  onMount(refresh);
</script>

<h3 id="devices_and_sharing_title"><Translate>Devices and Sharing</Translate></h3>
<p>
  <Translate>Below is a list of devices you have connected to our network.</Translate>
</p>
<p class="text-muted small">
  <Translate>
    If you want other people to be able to use to one of your devices, such as a printer, you can do so by changing the
    device’s <em>sharing</em> settings.
  </Translate>
</p>

{#if error}
  <!-- ERROR -->
  <LightboxPanel alert_level="danger" id="error_alert">
    <svelte:fragment slot="heading">
      <span class="glyphicon glyphicon-exclamation-sign" />
      <span><Translate>Error</Translate></span>
    </svelte:fragment>
    <svelte:fragment>
      <div>
        {#if error?.code === "list"}
          <span id="error_list"><Translate>Failed to retrieve your devices.</Translate></span>
        {:else if error?.code === "save"}
          <span id="error_save"><Translate>Failed to save your changes.</Translate></span>
        {/if}
      </div>
      <div class="text-right">
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <span on:click|preventDefault={dismiss_error} class="btn btn-danger" id="dismiss_error_action">
          <Translate>Close</Translate>
        </span>
      </div>
    </svelte:fragment>
  </LightboxPanel>
{/if}

<div class="btn-toolbar" style="margin-bottom: 0.5em">
  <span class="btn-group pull-right">
    <button
      disabled={loading === "loading"}
      on:click|preventDefault={refresh}
      class="btn btn-default"
      id="refresh_devices"
    >
      {#if !loading || loading === "loaded"}
        <span class="glyphicon" class:glyphicon-refresh={!loading} class:glyphicon-ok={loading === "loaded"} />
        <span><Translate>Refresh</Translate></span>
      {:else if loading === "loading"}
        <Spinner type="circle-fade" />
        <span><Translate>Loading…</Translate></span>
      {/if}
    </button>
  </span>
</div>

{#if devices.length}
  <div class="list-group" id="devices">
    {#each devices as device, device_index}
      {@const title = title_for(device)}
      {@const option = device_options[device.uuid] ?? {}}
      {@const editing = option.editor && option.editor !== "saved"}
      {@const shared_with_users = (editing ? option.updates?.shared_with_users : device.shared_with_users) ?? []}
      {#key device.uuid}
        <div class="list-group-item" id="device-{device_index}">
          <!-- header -->
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <div
            on:click|preventDefault|stopPropagation={() => toggle_expand(device, false)}
            class="device-header"
            class:clickable={!editing}
          >
            <a href="#toggle-expansion" on:click|preventDefault|stopPropagation={() => toggle_expand(device, false)}>
              {title}
            </a>
            {#if option.editor}
              <span
                class="small"
                class:text-muted={option.editor !== "error"}
                class:text-danger={option.editor === "error"}
              >
                {#if option.editor === "editing"}
                  <Translate>editing</Translate>
                {:else if option.editor === "saving"}
                  <Translate>saving</Translate>
                {:else if option.editor === "saved"}
                  <Translate>saved</Translate>
                {:else if option.editor === "error"}
                  <Translate>error</Translate>
                {/if}
              </span>
            {/if}
            {#if shared_with_users.length}
              <span class="badge pull-right">
                {#if shared_with_users.length < 4}
                  {#each shared_with_users as ignored}
                    <span>
                      <span class="glyphicon glyphicon-user" />
                    </span>
                  {/each}
                {:else}
                  {shared_with_users.length}
                  <span class="glyphicon glyphicon-user" />
                {/if}
              </span>
            {/if}
          </div>
          {#if option.expanded}
            <!-- body -->
            <table class="table table-condensed">
              <tbody>
                {#if device.host_name}
                  <tr>
                    <td class="label-cell text-muted text-right">Name</td>
                    <td class="value-cell">{device.host_name}</td>
                  </tr>
                {/if}
                {#if device.mac_address}
                  <tr>
                    <td class="label-cell text-muted text-right">MAC</td>
                    <td class="value-cell"><Data value={device.mac_address} /></td>
                  </tr>
                {/if}
                <tr>
                  <td class="label-cell text-muted text-right">Sharing</td>
                  <td class="value-cell">
                    {#if editing}
                      <div class="spacer-1">
                        <Search
                          search={search_shared_users}
                          key={user_match_key}
                          keycase="lower"
                          accept={user_search === "required" ? "item" : undefined}
                          delay={default_network_api_delay}
                          drop="updown"
                          autoclear
                          id="search_shared_users_device_{device_index}"
                          placeholder={user_search === "none" ? $_("Username") : $_("Search")}
                          inputmode="email"
                          enterkeyhint="done"
                          disabled={typeof config.self_service.max_shared_users === "number" &&
                          shared_with_users.length >= config.self_service.max_shared_users
                            ? true
                            : false}
                          on:select={select_shared_user_for(device)}
                        >
                          <svelte:fragment slot="accept">
                            <span class="glyphicon glyphicon-plus" />
                          </svelte:fragment>
                          <svelte:fragment slot="invalid">
                            <span class="glyphicon glyphicon-ban-circle" />
                          </svelte:fragment>
                          <svelte:fragment slot="match" let:item let:active>
                            {@const user = as_user(item)}
                            {@const is_me = is_own_username(user.username)}
                            <div class:text-muted={is_me && !active}>
                              <div>
                                <strong>{user.display_name || user.username}</strong>
                                {#if is_me}
                                  <span class:text-danger={!active}>(<Translate>me</Translate>)</span>
                                {/if}
                              </div>
                              {#if user.display_name}
                                <div>
                                  <small>{user.username}</small>
                                </div>
                              {/if}
                            </div>
                          </svelte:fragment>
                          <svelte:fragment slot="next">
                            <Translate>More</Translate>
                          </svelte:fragment>
                        </Search>
                      </div>
                      {#if user_search === "none"}
                        <p class="text-muted small">
                          <Translate>
                            Enter the username (typically an email address) of someone you want to share this device
                            with.
                          </Translate>
                        </p>
                      {:else}
                        <p class="text-muted small">
                          <Translate>
                            Start typing the name or username (typically an email address) of someone you want to share
                            this device with.
                          </Translate>
                        </p>
                      {/if}
                      {#if typeof config.self_service.max_shared_users === "number"}
                        <p class="text-muted small">
                          <Translate>
                            You may share this device with up to
                            <TranslateParam name="maximum" text={`${config.self_service.max_shared_users}`} />
                            other people.
                          </Translate>
                        </p>
                      {/if}
                    {/if}
                    {#if shared_with_users.length}
                      <p>
                        <Translate>These people will be able to see this device on our network.</Translate>
                      </p>
                      <p>
                        <ChipList
                          items={shared_with_users}
                          on:close={(event) => remove_shared_user(device, event.detail.index)}
                          noclose={!editing}
                        >
                          <span slot="content" style="overflow-wrap: anywhere;" let:item={user}>
                            {user}
                          </span>
                        </ChipList>
                      </p>
                    {:else}
                      <svelte:element this={editing ? "p" : "span"}>
                        <Translate>This device is not shared with anyone.</Translate>
                      </svelte:element>
                    {/if}
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- footer -->
            <div class="text-right device-footer">
              {#if editing}
                <button on:click|preventDefault={() => cancel(device)} type="button" class="btn btn-default dev-cancel">
                  <Translate>Cancel</Translate>
                </button>
                <button
                  on:click|preventDefault={() => save(device)}
                  type="button"
                  class="btn btn-save"
                  class:btn-primary={option.editor !== "error"}
                  class:btn-warning={option.editor === "error"}
                  disabled={option.editor !== "editing" && option.editor !== "error"}
                >
                  {#if option.editor === "editing"}
                    <Translate>Save</Translate>
                  {:else if option.editor === "saving"}
                    <Spinner type="circle-fade" />
                    <Translate>Saving</Translate>
                  {:else}
                    <Translate>Save Again</Translate>
                  {/if}
                </button>
              {:else}
                <button
                  on:click|preventDefault={() => toggle_expand(device, true)}
                  type="button"
                  class="btn btn-default dev-close"
                >
                  <Translate>Close</Translate>
                </button>
                <button
                  on:click|preventDefault={() => edit(device)}
                  type="button"
                  class="btn btn-edit"
                  class:btn-primary={option.editor !== "saved"}
                  class:btn-success={option.editor === "saved"}
                  disabled={option.editor === "saved"}
                >
                  {#if option.editor === "saved"}
                    <span class="glyphicon glyphicon-ok" />
                    <Translate>Saved</Translate>
                  {:else}
                    <Translate>Edit</Translate>
                  {/if}
                </button>
              {/if}
            </div>
          {/if}
        </div>
      {/key}
    {/each}
  </div>
{:else if loading === "loading"}
  <div class="alert alert-info" id="loading_devices_alert">
    <span class="glyphicon glyphicon-time" />
    <Translate>Retrieving your known devices from the server.</Translate>
  </div>
{:else}
  <div class="alert alert-info" id="no_devices_alert">
    <span class="glyphicon glyphicon-info-sign" />
    <Translate>No devices found.</Translate>
  </div>
{/if}

<style>
  table {
    margin: 0px;
  }

  .list-group-item {
    padding: 0px;
  }

  .device-header,
  .device-footer {
    padding: 10px 15px;
  }

  .label-cell {
    width: 80px;
    padding-left: 15px;
  }

  .value-cell {
    padding-right: 15px;
  }

  .label-cell,
  .value-cell {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  .spacer-1 {
    margin-bottom: 0.5em;
  }
</style>
