<script lang="ts">
  import {onDestroy} from "svelte";
  import {require_place_context, set_region_context} from "./context";
  import {RegionContext} from "./types";

  let classes: string | undefined = undefined;
  export let self_contained: boolean;
  export let name: string | undefined = undefined;
  export let tag: string | undefined = undefined;
  export let id: string | undefined = undefined;
  export {classes as class};

  let element: HTMLElement | undefined = undefined;

  const place = require_place_context();
  const region = new RegionContext(place);
  const deregister = region.register();
  set_region_context(region);

  $: tag_name = tag || "div";
  $: region.update(name, self_contained ? element : undefined);

  onDestroy(deregister);
</script>

<svelte:element this={tag_name} bind:this={element} class={classes} {id}>
  <slot />
</svelte:element>
