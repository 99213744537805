<script lang="ts">
  export let name: string;
  export let text: string | undefined = undefined;
</script>

<span data-cg-translate-param={name}>
  {#if text !== undefined}
    {text}
  {:else}
    <slot />
  {/if}
</span>
