<script context="module" lang="ts">
  import {_, Translate, TranslateParam} from "$lib/translate";
  import type {TranslateFn} from "$lib/translate";
  import type {Config, Installer, Test} from "./PasspointProfile.types";
  export * from "./PasspointProfile.types";

  function installer_link_id(choice: Installer): string {
    if (choice.platform === "CLIENT") {
      return "use_app_action";
    } else {
      return `install_${choice.platform}_profile_action`;
    }
  }

  function update(config: Config, _: TranslateFn) {
    function choice_label(choice: Installer) {
      switch (choice.platform) {
        case "CLIENT":
          return _("Install using {app_name} app").replace("{app_name}", config.passpoint.app_name);
        case "android":
          return _("Install on Android");
        case "apple":
          return _("Install on Apple");
        case "windows":
          return _("Install on Windows");
      }
    }

    {
      const page_title = config.page.passpoint_profile_title ?? _("Network Profile");

      return {page_title, choice_label};
    }
  }
</script>

<script lang="ts">
  export let config: Config;
  export let test: Test | undefined = undefined;

  // Init non-reactive
  let show_options = false;

  function display_options(): void {
    show_options = true;
  }

  // Reactive statements
  $: ({page_title, choice_label} = update(config, $_));
  $: if (test && typeof test.show_options === "boolean") {
    show_options = test.show_options;
  }
</script>

<svelte:head>
  <title>{page_title}</title>
</svelte:head>

<div class="skin row limit-columns-1" id="cg-guest-passpoint-profile">
  <div class="skin column">
    <div class="skin content">
      <div class="skin controls narrow">
        <h2 class="form-signin-heading">{page_title}</h2>
        <div class="form-group">
          {#if config.passpoint.ssid === config.passpoint.friendly_name}
            <p>
              <Translate>
                Install this network profile to connect to
                <TranslateParam name="network_ssid"><strong>{config.passpoint.ssid}</strong></TranslateParam>.
              </Translate>
            </p>
          {:else}
            <p>
              <Translate>
                Install this network profile to connect to
                <TranslateParam name="network_ssid"><strong>{config.passpoint.ssid}</strong></TranslateParam>
                (<TranslateParam name="network_name"><span>{config.passpoint.friendly_name}</span></TranslateParam>).
              </Translate>
            </p>
          {/if}
          {#if !config.passpoint.wired_only}
            <p>
              <Translate>
                Ensure your Wi-Fi network adapter is enabled before installing this network profile.
              </Translate>
            </p>
          {/if}
        </div>
        <div class="form-group">
          {#each config.passpoint.installers as choice, index}
            {#if index === 0 || show_options}
              <a
                href={choice.url}
                class:btn-primary={index === 0}
                class:btn-default={index !== 0}
                id={installer_link_id(choice)}
                class="btn btn-lg btn-block btn-wrap">{choice_label(choice)}</a
              >
            {/if}
          {/each}
        </div>

        {#if !show_options && (config.passpoint.installers.length > 1 || config.passpoint.skip_login_url)}
          <div class="form-group">
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <!-- svelte-ignore a11y-no-static-element-interactions -->
            <a
              on:click|preventDefault={display_options}
              class="btn btn-link btn-block btn-wrap"
              id="network_profile_more_options_action"
            >
              <Translate>More Options...</Translate>
            </a>
          </div>
        {/if}

        {#if show_options && config.passpoint.skip_login_url}
          <div class="form-group">
            <div class="alert alert-info">
              <span>
                <Translate>
                  If your device is not able to install a network profile, you may skip installation.
                </Translate>
              </span>
            </div>
          </div>
          <div class="form-group">
            <a
              href={config.passpoint.skip_login_url}
              class="btn btn-link btn-block btn-wrap"
              id="skip_network_profile_action"
            >
              <span><Translate>Skip Installation</Translate></span>
            </a>
          </div>
        {/if}
      </div>
    </div>
  </div>
</div>
