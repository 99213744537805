<script context="module" lang="ts">
  import type {TranslateFn} from "$lib/translate";
  import type {Config, AppLink, MinOs, MinSupported, Supported} from "./Passpoint.types";
  import app_logo_url from "$assets/branding/aruba/passpoint/app-icon.svg";
  export * from "./Passpoint.types";

  /*
   * Types and all "pure reactive" functions are declared here, in the "module"
   * context, to enforce the necessary separation required to ensure that no
   * "component" scope variables can be inadvertently referenced in any of the
   * pure reactive functions.
   */

  // UI state that depends ONLY on config.
  //
  // This defines a SAFE reactive "update" function. It is safe to use for
  // reactive updates because, by being defined in the "module" context block,
  // it cannot access any component "instance" variables (as they are simply not
  // in scope).
  function update(config: Config, _: TranslateFn) {
    // Convenience values (can be used by utility functions).
    //
    // These values MUST be derived from variables only, not from any functions,
    // to avoid any possibility of attempting to access an outer scope variable
    // that is undefined at the time of invocation.
    const passpoint = config.passpoint;
    const support = passpoint.support;
    const screen = passpoint.screen;

    // We use nested scoping blocks (convenience values -> utility functions ->
    // derived state values) because of this following type of possibility with
    // JavaScript/TypeScript:
    /*
    // Uncomment to see that this is considered legal code, but that is fails on
    // execution because `defined_later` is still `undefined` when
    // `references_something_defined_later()` is called.
    function references_something_defined_later() {
      return defined_later.value;
    }
    const this_is_legal_but_fails = references_something_defined_later();
    const defined_later = {value: "totally defined, dude"};
    */

    // Utility functions (can use `config` and the above "convenience values").
    //
    // Enter block scope to enforce the rule that the "convencience values"
    // above must not use any functions in their derivation.
    {
      function os_name(): string {
        const platform_name = config.ua.platform.name;
        const dist_name = config.ua.dist?.name;

        if (platform_name && platform_name.toLowerCase() === "linux" && dist_name) {
          return dist_name;
        } else {
          return platform_name;
        }
      }

      function oem_name() {
        return config.ua.oem ? config.ua.oem.name : os_name();
      }

      function os_supported(supported: Supported): boolean {
        return supported.os && supported.os_version;
      }

      function browser_supported(supported: Supported): boolean {
        return supported.browser && supported.browser_version;
      }

      function limited_browser_support(supported: MinSupported): boolean {
        return support.client.os &&
          supported.min_browser[support.client.os] &&
          supported.min_browser[support.client.os].length > 0
          ? true
          : false;
      }

      function warn_unsupported_using(supported: Supported, level: string): boolean {
        if (!passpoint.is_enabled) {
          return false;
        }

        // This structure is intended to warn at ONLY ONE level, from most
        // significant to least significant: OEM, OEM version, OS, OS version,
        // browser, or browser version.
        if (!supported.oem) {
          return level === "oem";
        } else if (!supported.oem_version) {
          return level === "oem_version";
        } else if (!supported.os) {
          return level === "os";
        } else if (!supported.os_version) {
          return level === "os_version";
        } else if (!supported.browser) {
          return level === "browser";
        } else if (!supported.browser_version) {
          return level === "browser_version";
        }
        return false;
      }

      function support_level_rank(supported: Supported): number {
        if (!passpoint.is_enabled) {
          return 0;
        } else if (!supported.oem) {
          return 1;
        } else if (!supported.oem_version) {
          return 2;
        } else if (!supported.os) {
          return 3;
        } else if (!supported.os_version) {
          return 4;
        } else if (!supported.browser) {
          return 5;
        } else if (!supported.browser_version) {
          return 6;
        } else {
          return 7;
        }
      }

      function compare_support_level_rank(a: Supported, b: Supported): number {
        return support_level_rank(a) - support_level_rank(b);
      }

      function warn_unsupported(level: string): boolean {
        if (!passpoint.is_enabled) {
          return false;
        }

        if (screen == "welcome") {
          // Only warn if BOTH app-based and web-based provisioning are not
          // supported at this level (OS, OS version, browser, or browser
          // version).
          return (
            (warn_unsupported_using(support.app, level) && compare_support_level_rank(support.app, support.web) >= 0) ||
            (warn_unsupported_using(support.web, level) && compare_support_level_rank(support.web, support.app) >= 0)
          );
        } else if (screen == "installers") {
          // Only warn if app-based provisioning is not supported at this level
          // (OS, OS version, browser, or browser version).
          return warn_unsupported_using(support.app, level);
        } else if (screen === "change_browser") {
          // Only warn if web-based provisioning is not supported at this level
          // (OS, OS version, browser, or browser version).
          return warn_unsupported_using(support.web, level);
        } else {
          return false;
        }
      }

      function screen_page_title(): string | null {
        switch (screen) {
          case "welcome":
            return _("Welcome");
          case "installers":
            return _("Install {app_name}").replace("{app_name}", passpoint.app_name);
          case "change_browser":
            return _("Unsupported Browser");
          default:
            return null;
        }
      }

      function warn_unsupported_oem() {
        return warn_unsupported("oem") || warn_unsupported("oem_version");
      }

      function warn_provisioning_unsupported() {
        if (!config.passpoint.is_enabled) {
          return false;
        }
        return (
          warn_all_provisioning_unsupported() ||
          warn_app_provisioning_unsupported() ||
          warn_web_provisioning_unsupported()
        );
      }

      function warn_all_provisioning_unsupported() {
        return !support.app.compatible && !support.web.compatible;
      }

      function warn_app_provisioning_unsupported() {
        return screen === "installers" && !support.app.compatible && support.web.compatible;
      }

      function warn_web_provisioning_unsupported() {
        return screen === "change_browser" && support.app.compatible && !support.web.compatible;
      }

      function show_min_os_requirements() {
        if (warn_unsupported_oem()) {
          return false;
        }

        if (warn_all_provisioning_unsupported()) {
          return !os_supported(support.app) && !os_supported(support.web);
        } else if (warn_app_provisioning_unsupported()) {
          return !os_supported(support.app);
        } else if (warn_web_provisioning_unsupported()) {
          return !os_supported(support.web);
        } else {
          return false;
        }
      }

      function min_os_requirements(): MinOs {
        if (warn_all_provisioning_unsupported()) {
          return config.passpoint.support.min.min_os;
        } else if (warn_app_provisioning_unsupported()) {
          return config.passpoint.support.app.min_os;
        } else if (warn_web_provisioning_unsupported()) {
          return config.passpoint.support.web.min_os;
        } else {
          return {};
        }
      }

      function min_os_description(key: string, version: string): string {
        switch (key) {
          case "android":
            return _("Android {version}").replace("{version}", version);
          case "chromeos":
            return _("ChromeOS {version} (requires {app_name})")
              .replace("{version}", version)
              .replace("{app_name}", config.passpoint.app_name);
          case "ios":
            return _("iOS/iPadOS {version}").replace("{version}", version);
          case "macos":
            return _("macOS {version}").replace("{version}", version);
          case "windows":
            return _("Windows {version}").replace("{version}", version);
          case "windows_server":
            return _("Windows Server {version}").replace("{version}", version);
          case "ubuntu":
            return _("Ubuntu {version} (requires {app_name})")
              .replace("{version}", version)
              .replace("{app_name}", passpoint.app_name);
          default:
            return key + " " + version;
        }
      }

      function show_min_browser_requirements() {
        if (warn_unsupported_oem()) {
          return false;
        }

        if (show_min_os_requirements()) {
          return false;
        }

        if (warn_all_provisioning_unsupported()) {
          return (
            !browser_supported(support.app) && !browser_supported(support.web) && limited_browser_support(support.min)
          );
        } else if (warn_app_provisioning_unsupported()) {
          return !browser_supported(support.app) && limited_browser_support(support.app);
        } else if (warn_web_provisioning_unsupported()) {
          return !browser_supported(support.web);
        } else {
          return false;
        }
      }

      function min_browser_requirements() {
        if (warn_all_provisioning_unsupported()) {
          return support.min.min_browser[support.client.os];
        } else if (warn_app_provisioning_unsupported()) {
          return support.app.min_browser[support.client.os];
        } else if (warn_web_provisioning_unsupported()) {
          if (limited_browser_support(support.web)) {
            return support.web.min_browser[support.client.os];
          } else {
            // This condition only occurs for an explicitly DENIED browser, such
            // as DuckDuckGo, which means that we also can't trust the OS
            // information in the User-Agent string. Because we don't actually
            // know what the OS of the client is in this case, we need to only
            // present options that are most likely to work, otherwise the user
            // will be bouncing around browsers trying to find one that actually
            // works.
            if (support.client.os === "macos" || support.client.os === "ios") {
              // DuckDuckGo at least only seems to falsify the OS as macOS when
              // running on iOS/iPadOS (in what appears to be an attempt to
              // provide the user the chance to choose between Desktop and
              // Mobile sites, although it seems to default to Desktop). Such
              // pain.
              return ["safari"];
            } else if (support.client.os === "android") {
              return ["chrome"];
            } else {
              return ["chrome", "safari"];
            }
          }
        }
      }

      function min_browser_description(key: string): string {
        switch (key) {
          case "chrome":
            return _("Chrome");
          case "safari":
            return _("Safari");
          default:
            // The above browsers are the only ones that a required for certain
            // operating systems.
            return key;
        }
      }

      // Derived state values
      //
      // Enter the final block scope for the derived configuration state (MUST
      // only define variables, no functions, again to avoid any possibility of
      // a function attempting to access a _still undefined_ outer scope
      // variable at the time of invocation).
      {
        const allow_app_provisioning = config.passpoint.is_enabled && config.passpoint.allow_app_provisioning;
        const allow_web_provisioning = config.passpoint.is_enabled && config.passpoint.allow_web_provisioning;
        const app_only_provisioning = allow_app_provisioning && !allow_web_provisioning;
        const web_only_provisioning = allow_web_provisioning && !allow_app_provisioning;
        const os = os_name();
        const unknown_os_distro = os.toLowerCase() === "linux";

        return {
          screen,
          app_links: passpoint.is_enabled ? support.app.links : ({} as AppLink),
          page_title: screen_page_title(),
          screen_id: screen + "_screen",
          os_name: os,
          oem_name: oem_name(),
          warn_unknown_os_distro: unknown_os_distro && warn_unsupported("os"),
          warn_unsupported_os: !unknown_os_distro && warn_unsupported("os"),
          warn_unknown_os_version: !support.client.os_version && warn_unsupported("os_version"),
          warn_unsupported_os_version: !!support.client.os_version && warn_unsupported("os_version"),
          warn_unsupported_browser: warn_unsupported("browser"),
          warn_unsupported_browser_version: warn_unsupported("browser_version"),
          warn_unsupported_oem: warn_unsupported_oem(),
          warn_all_provisioning_unsupported: warn_all_provisioning_unsupported(),
          warn_app_provisioning_unsupported: warn_app_provisioning_unsupported(),
          warn_web_provisioning_unsupported: warn_web_provisioning_unsupported(),
          warn_provisioning_unsupported: warn_provisioning_unsupported(),
          show_min_os_requirements: show_min_os_requirements(),
          min_os_requirements: min_os_requirements(),
          min_os_description,
          show_min_browser_requirements: show_min_browser_requirements(),
          min_browser_requirements: min_browser_requirements() ?? [],
          min_browser_description,
          allow_app_provisioning,
          allow_web_provisioning,
          app_only_provisioning,
          web_only_provisioning,
          show_disabled_notice: !allow_app_provisioning && !allow_web_provisioning && !warn_unsupported_oem(),
          show_welcome_notice: screen == "welcome" && (allow_app_provisioning || allow_web_provisioning),
          show_change_browser_notice: screen == "change_browser" && config.passpoint.is_enabled,
          show_workflow_choices: screen == "welcome" && !app_only_provisioning,
          show_self_service: screen == "welcome" && config.passpoint.is_enabled,
          show_privacy_links: screen == "welcome" && config.passpoint.is_enabled,
          show_app_logo: screen == "welcome" || screen == "installers",
          show_app_installers: (screen == "welcome" && app_only_provisioning) || screen == "installers",
          show_after_install: (screen == "welcome" && app_only_provisioning) || screen == "installers",
          show_prefer_browser: screen == "installers" && allow_web_provisioning,
          show_browser_only: screen == "welcome" && web_only_provisioning,
        };
      }
    }
  }
</script>

<script lang="ts">
  import {_, Translate, TranslateParam} from "$lib/translate";
  import AppLinks from "$lib/AppLinks.svelte";
  import ClipboardCopy from "$lib/ClipboardCopy.svelte";
  import InjectHref from "$lib/InjectHref.svelte";

  export let config: Config;

  // Update config dependent state.
  $: ({
    screen,
    app_links,
    page_title,
    screen_id,
    os_name,
    oem_name,
    warn_unknown_os_distro,
    warn_unsupported_os,
    warn_unknown_os_version,
    warn_unsupported_os_version,
    warn_unsupported_browser,
    warn_unsupported_browser_version,
    warn_unsupported_oem,
    warn_all_provisioning_unsupported,
    warn_app_provisioning_unsupported,
    warn_web_provisioning_unsupported,
    warn_provisioning_unsupported,
    show_min_os_requirements,
    min_os_requirements,
    min_os_description,
    show_min_browser_requirements,
    min_browser_requirements,
    min_browser_description,
    allow_app_provisioning,
    allow_web_provisioning,
    app_only_provisioning,
    web_only_provisioning,
    show_disabled_notice,
    show_welcome_notice,
    show_change_browser_notice,
    show_workflow_choices,
    show_self_service,
    show_privacy_links,
    show_app_logo,
    show_app_installers,
    show_after_install,
    show_prefer_browser,
    show_browser_only,
  } = update(config, $_));
</script>

<svelte:head>
  <title>{page_title}</title>
</svelte:head>

<div class="skin row limit-columns-1" id="cg-guest-passpoint">
  <div class="skin column">
    <div class="skin content" id={screen_id}>
      <div class="skin controls narrow">
        <h2 class="form-signin-heading">{page_title}</h2>
        {#if show_disabled_notice}
          <div class="alert alert-danger" id="disabled_alert">
            <strong><Translate>This page is currently disabled.</Translate></strong>
            <p>
              <Translate>
                An administrator must enable browser-based or app-based provisioning of Passpoint network profiles
                before you can use this page.
              </Translate>
            </p>
          </div>
        {/if}

        {#if warn_provisioning_unsupported}
          <div>
            {#if warn_unknown_os_distro}
              <div class="alert alert-warning" id="os_unknown_distro_alert">
                <p>
                  <span class="glyphicon glyphicon-alert" />
                  <span>
                    <Translate>
                      We could not detect which distribution of
                      <TranslateParam name="operating_system">
                        <strong>{os_name}</strong>
                      </TranslateParam>
                      you are using.
                    </Translate>
                  </span>
                </p>
                <p>
                  <Translate>
                    Before continuing, check your distribution against the operating system versions that we support.
                  </Translate>
                </p>
              </div>
            {/if}

            {#if warn_unsupported_oem}
              <div class="alert alert-danger" id="oem_unsupported_alert">
                <p>
                  <span class="glyphicon glyphicon-alert" />
                  <span>
                    <Translate>
                      Your
                      <TranslateParam name="manufacturer_name">
                        <strong>{oem_name}</strong>
                      </TranslateParam>
                      device running
                      <TranslateParam name="operating_system">
                        <strong>{os_name}</strong>
                      </TranslateParam>
                      is not supported.
                    </Translate>
                  </span>
                </p>
                <p>
                  <Translate>
                    We have blocked your device because your device’s manufacturer has a known fault in their software.
                  </Translate>
                </p>
                <p>
                  <Translate>
                    If we were to install a network profile, it could make your device unusable, or even cause damage to
                    it.
                  </Translate>
                </p>
              </div>
            {/if}

            {#if warn_unsupported_os}
              <p class="alert alert-warning" id="os_unsupported_alert">
                <span class="glyphicon glyphicon-alert" />
                <span><Translate>Your <strong>operating system</strong> is not supported.</Translate></span>
              </p>
            {/if}

            {#if warn_unknown_os_version}
              <div class="alert alert-warning" id="os_version_unknown_alert">
                <p>
                  <span class="glyphicon glyphicon-alert" />
                  <span>
                    <Translate>
                      We could not detect which version of
                      <TranslateParam name="operating_system">
                        <strong>{os_name}</strong>
                      </TranslateParam> you are using.
                    </Translate>
                  </span>
                </p>
                <p>
                  <Translate>
                    Before continuing, check your operating system version against those that we support.
                  </Translate>
                </p>
              </div>
            {/if}

            {#if warn_unsupported_os_version}
              <p class="alert alert-warning" id="os_version_unsupported_alert">
                <span class="glyphicon glyphicon-alert" />
                <span>
                  <Translate>
                    Your version of
                    <TranslateParam name="operating_system">
                      <strong>{os_name}</strong>
                    </TranslateParam>
                    is not supported.
                  </Translate>
                </span>
              </p>
            {/if}

            {#if warn_unsupported_browser}
              <p class="alert alert-warning" id="browser_unsupported_alert">
                <span class="glyphicon glyphicon-alert" />
                <span><Translate>Your web <strong>browser</strong> is not supported.</Translate></span>
              </p>
            {/if}

            {#if warn_unsupported_browser_version}
              <p class="alert alert-warning" id="browser_version_unsupported_alert">
                <span class="glyphicon glyphicon-alert" />
                <span>
                  <Translate>
                    Your version of
                    <TranslateParam name="web_browser">
                      <strong>{config.ua.browser.name}</strong>
                    </TranslateParam>
                    is not supported.
                  </Translate>
                </span>
              </p>
            {/if}

            {#if show_min_os_requirements}
              <div class="alert alert-warning no-margin-collapse" id="min_os_requirements_alert">
                {#if warn_all_provisioning_unsupported}
                  <p><Translate>The minimum operating system versions that we support are:</Translate></p>
                {/if}
                {#if warn_app_provisioning_unsupported}
                  <p>
                    <Translate>
                      The minimum operating system versions that are supported by the
                      <TranslateParam name="app_name" text={config.passpoint.app_name} />
                      app are:
                    </Translate>
                  </p>
                {/if}
                {#if warn_web_provisioning_unsupported}
                  <p>
                    <Translate>
                      The minimum operating system versions that we support for downloading a network profile using a
                      browser are:
                    </Translate>
                  </p>
                {/if}
                <ul>
                  {#each Object.entries(min_os_requirements) as [key, version] (key)}
                    <li>{min_os_description(key, version)}</li>
                  {/each}
                </ul>
                {#if warn_all_provisioning_unsupported && (warn_unknown_os_distro || warn_unsupported_os)}
                  <p>
                    <Translate>
                      To be able to provision a network profile on your device, you must
                      <strong>use a supported operating system</strong>.
                    </Translate>
                  </p>
                {/if}
                {#if warn_all_provisioning_unsupported && warn_unsupported_os_version}
                  <p>
                    <Translate>
                      To be able to provision a network profile on your device, you must
                      <strong>upgrade your operating system</strong>.
                    </Translate>
                  </p>
                {/if}
                {#if warn_app_provisioning_unsupported}
                  <InjectHref href="?action=browser">
                    <p>
                      <Translate>
                        To be able to provision a network profile on your device, either
                        <strong>upgrade your operating system</strong>, or
                        <!-- svelte-ignore a11y-missing-attribute (href is injected at runtime) -->
                        <a>use your browser</a>
                        instead.
                      </Translate>
                    </p>
                  </InjectHref>
                {/if}
                {#if warn_web_provisioning_unsupported}
                  <InjectHref href="?action=install">
                    <p>
                      <Translate>
                        To be able to provision a network profile on your device, either
                        <strong>upgrade your operating system</strong>, or
                        <!-- svelte-ignore a11y-missing-attribute (href is injected at runtime) -->
                        <a>install <TranslateParam name="app_name" text={config.passpoint.app_name} /></a>
                        instead.
                      </Translate>
                    </p>
                  </InjectHref>
                {/if}
              </div>
            {/if}

            {#if show_min_browser_requirements}
              <div class="alert alert-warning no-margin-collapse" id="min_browser_requirements_alert">
                {#if warn_all_provisioning_unsupported}
                  <p>
                    <Translate>
                      The browsers that we support on
                      <TranslateParam name="operating_system" text={os_name} />
                      include:
                    </Translate>
                  </p>
                {/if}
                {#if warn_app_provisioning_unsupported}
                  <p>
                    <Translate>
                      The browsers that are supported by the
                      <TranslateParam name="app_name" text={config.passpoint.app_name} />
                      app on
                      <TranslateParam name="operating_system" text={os_name} />
                      include:
                    </Translate>
                  </p>
                {/if}
                {#if warn_web_provisioning_unsupported}
                  <p>
                    <Translate>
                      The browsers that we support for downloading and installing a network profile on
                      <TranslateParam name="operating_system" text={os_name} />
                      include:
                    </Translate>
                  </p>
                {/if}
                <ul>
                  {#each min_browser_requirements as key}
                    <li>{min_browser_description(key)}</li>
                  {/each}
                </ul>
                {#if warn_all_provisioning_unsupported && warn_unsupported_browser}
                  <p>
                    <Translate>
                      To be able to provision a network profile on your device, you must
                      <strong>use a supported browser</strong>.
                    </Translate>
                  </p>
                {/if}
                {#if warn_all_provisioning_unsupported && warn_unsupported_browser_version}
                  <p>
                    <Translate>
                      To be able to provision a network profile on your device, you must
                      <strong>upgrade your browser</strong>.
                    </Translate>
                  </p>
                {/if}
                {#if warn_app_provisioning_unsupported}
                  <InjectHref href="?action=browser">
                    <p>
                      <Translate>
                        To be able to provision a network profile on your device,
                        <!-- svelte-ignore a11y-missing-attribute (href is injected at runtime) -->
                        <a>use your browser</a>
                        instead.
                      </Translate>
                    </p>
                  </InjectHref>
                {/if}
                {#if warn_web_provisioning_unsupported && (warn_unsupported_browser || warn_unsupported_browser_version)}
                  <InjectHref href="?action=install">
                    <p>
                      <Translate>
                        To be able to provision a network profile on your device, either
                        <strong>use a supported browser</strong>, or
                        <!-- svelte-ignore a11y-missing-attribute (href is injected at runtime) -->
                        <a>install <TranslateParam name="app_name" text={config.passpoint.app_name} /></a>
                        instead.
                      </Translate>
                    </p>
                  </InjectHref>
                {/if}
              </div>
            {/if}
          </div>
        {/if}

        {#if show_welcome_notice}
          <div class="form-group" id="welcome_notice">
            <p>
              <Translate>
                To connect to the
                <TranslateParam name="network_name">
                  <strong>{config.passpoint.network_name}</strong>
                </TranslateParam>
                network, we need to create a <strong>network profile</strong> for you.
              </Translate>
            </p>
            {#if app_only_provisioning}
              <p>
                <Translate>
                  Before you can add a network profile to your device, you will first need to install the
                  <TranslateParam name="app_name" text={config.passpoint.app_name} />
                  app.
                </Translate>
              </p>
            {/if}
            {#if allow_app_provisioning && allow_web_provisioning}
              <p>
                <Translate>
                  For the best experience, and to automatically keep your network profile up to date, it is recommended
                  that you install the
                  <TranslateParam name="app_name" text={config.passpoint.app_name} />
                  app before continuing.
                </Translate>
              </p>
            {/if}
          </div>
        {/if}

        {#if allow_app_provisioning}
          <div>
            {#if show_workflow_choices}
              <div class="form-group">
                {#if allow_app_provisioning && allow_web_provisioning}
                  <p>
                    <Translate>
                      Do you want to install the
                      <TranslateParam name="app_name" text={config.passpoint.app_name} />
                      app? <em>(recommended)</em>
                    </Translate>
                  </p>
                {/if}
              </div>
            {/if}

            {#if show_app_installers}
              <div class="form-group">
                <p>
                  <Translate>
                    Use the buttons below to install the
                    <TranslateParam name="app_name" text={config.passpoint.app_name} />
                    app. And then come back to this page once you have done so.
                  </Translate>
                </p>
              </div>
            {/if}

            {#if show_app_logo}
              <div class="form-group" id="app_logo">
                <p style="text-align: center;">
                  <span
                    style="
                    display: inline-block;
                    margin: 0.5em;
                    padding: 0.5em;
                    background-color: white;
                    border: 2px solid rgba(0, 0, 0, 0.1);
                    border-radius: 1em;
                  "
                  >
                    <img src={app_logo_url} alt={config.passpoint.app_name} style="height: 8em; width: 8em" />
                  </span>
                  <br />
                  <span>{config.passpoint.app_name}</span>
                </p>
              </div>
            {/if}

            {#if show_workflow_choices}
              <div class="form-group">
                {#if allow_app_provisioning}
                  <a href="?action=install" class="btn btn-lg btn-primary btn-block btn-wrap" id="install_app_action">
                    <Translate>
                      Yes, I want to install
                      <TranslateParam name="app_name" text={config.passpoint.app_name} />
                    </Translate>
                  </a>
                {/if}
                {#if allow_app_provisioning}
                  <a href="?action=installed" class="btn btn-lg btn-primary btn-block btn-wrap" id="use_app_action">
                    <Translate>
                      Yes, I already have
                      <TranslateParam name="app_name" text={config.passpoint.app_name} />
                    </Translate>
                  </a>
                {/if}
                {#if allow_web_provisioning}
                  <a href="?action=browser" class="btn btn-lg btn-default btn-block btn-wrap" id="use_browser_action">
                    <Translate>No thanks, just use my browser</Translate>
                  </a>
                {/if}
              </div>
            {/if}

            {#if show_app_installers}
              <div class="form-group">
                <AppLinks
                  {config}
                  google_play={app_links.google_play}
                  app_store={app_links.app_store}
                  mac_app_store={app_links.mac_app_store}
                  macos_installer={app_links.macos_installer}
                  windows_installer={app_links.windows_installer}
                  ubuntu_installer={app_links.ubuntu_installer}
                />
              </div>
            {/if}

            {#if show_after_install}
              <div class="form-group">
                <p style="margin-top: 2em">
                  <Translate>
                    After you have installed the
                    <TranslateParam name="app_name" text={config.passpoint.app_name} />
                    app, press the <strong>Yes</strong> button to continue.
                  </Translate>
                </p>
                <a href="?action=installed" class="btn btn-lg btn-primary btn-block btn-wrap" id="use_app_action">
                  <Translate>
                    Yes, I have the
                    <TranslateParam name="app_name" text={config.passpoint.app_name} />
                    app
                  </Translate>
                </a>
              </div>
            {/if}

            {#if show_prefer_browser}
              <div class="form-group">
                <p style="margin-top: 2em">
                  <Translate>
                    If you do not want to install the
                    <TranslateParam name="app_name" text={config.passpoint.app_name} />
                    app, press the <strong>No thanks</strong> button to continue.
                  </Translate>
                </p>
                <a href="?action=browser" class="btn btn-lg btn-default btn-block btn-wrap" id="use_browser_action">
                  <Translate>No thanks, just use my browser</Translate>
                </a>
              </div>
            {/if}
          </div>
        {/if}

        {#if show_browser_only}
          <div>
            <div class="form-group">
              <a href="?action=browser" class="btn btn-lg btn-primary btn-block btn-wrap" id="use_browser_action">
                <Translate>Continue</Translate>
              </a>
            </div>
          </div>
        {/if}

        {#if show_change_browser_notice}
          <div id="change_browser_notice">
            <div class="alert alert-danger">
              {#if warn_unsupported_os || warn_unsupported_os_version}
                <p>
                  <Translate>
                    Your current operating system will not be able to install a <strong>network profile</strong>.
                  </Translate>
                </p>
              {/if}
              {#if warn_unknown_os_distro || warn_unknown_os_version}
                <Translate>
                  Check that your operating system meets the minimum operating system versions listed above. If it does
                  not, you will not be able to install a <strong>network profile</strong>.
                </Translate>
              {/if}
              {#if warn_unsupported_browser || warn_unsupported_browser_version}
                <p>
                  <Translate>
                    Your current browser will not be able to install a <strong>network profile</strong>.
                  </Translate>
                </p>
              {/if}
            </div>
            <div class="form-group">
              {#if warn_unknown_os_distro || warn_unsupported_os || warn_unsupported_os_version || warn_unknown_os_version}
                <p>
                  <Translate>
                    To continue, open this link on a device running one of the supported operating systems listed above.
                  </Translate>
                </p>
              {/if}
              {#if warn_unsupported_browser || warn_unsupported_browser_version}
                <p>
                  <Translate>
                    To continue, copy this link and paste it into the address bar of one of the supported browsers
                    listed above.
                  </Translate>
                </p>
              {/if}

              <pre style="white-space: pre-wrap">{config.passpoint.url}</pre>

              <div>
                <ClipboardCopy data={config.passpoint.url} class="btn btn-default btn-lg btn-block btn-wrap">
                  <Translate>Copy link to clipboard</Translate>
                </ClipboardCopy>
              </div>
            </div>
            <div class="form-group text-center" style="margin-top: 3em">
              <a href="?action=browser&force=true" id="use_browser_action">
                <Translate>No thanks, use my unsupported browser anyway</Translate>
              </a>
            </div>
          </div>
        {/if}

        {#if show_self_service}
          <div class="form-group">
            <a href="self-service" class="btn btn-md btn-link btn-block btn-wrap" id="self_service_action">
              <Translate>Manage my network credentials</Translate>
            </a>
          </div>
        {/if}

        {#if show_privacy_links}
          <div class="form-group">
            <div class="text-center">
              <a
                href="https://www.hpe.com/uk/en/privacy/ww-privacy-statement.html"
                class="btn btn-sm btn-link"
                id="privacy_policy"
              >
                <Translate>Privacy policy</Translate>
              </a>
              <a
                href="https://www.arubanetworks.com/assets/legal/EULA.pdf"
                class="btn btn-sm btn-link"
                id="license_agreement"
              >
                <Translate>License agreement</Translate>
              </a>
            </div>
          </div>
        {/if}
      </div>
    </div>
  </div>
</div>
