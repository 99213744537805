<script context="module" lang="ts">
  import {_, Translate} from "$lib/translate";
  import type {TranslateFn} from "$lib/translate";
  import type {Config} from "./AccessDenied.types";
  export * from "./AccessDenied.types";

  function update(config: Config, _: TranslateFn) {
    // Derived functions block scope
    {
      function reason_message(reason: string): string {
        switch (reason) {
          case "bad_config":
            return _("This portal has not been properly configured");
          case "no_app_access":
            return _("This portal does not allow access to the management application");
          case "no_net_access":
            return _("This portal does not allow access to the network");
          case "no_capture":
            return _("No capture parameters provided");
          case "no_switchip":
            return _("No return hostname provided (switchip parameter)");
          case "invalid_switchip":
            return _("Invalid or unsupported return hostname specified (switchip parameter)");
          case "account_disabled":
            return _("Your account has been disabled");
          case "account_expired":
            return _("Your account has expired");
          case "account_blocked":
            return _("Your account has been blocked");
          case "no_policy":
            return _("No policy available");
          case "policy_no_network":
            return _("Network access denied for user role");
          case "policy_simultaneous_use":
            return _("Simultaneous usage limit exceeded");
          case "policy_periodic_quota":
            return _("Your daily quota has been exceeded");
          case "no_token":
            return _("No token parameter provided");
          case "invalid_token":
            return _("Invalid or expired token specified");
          case "controller_unlicensed":
            return _("This network is not available");
          default:
            return reason;
        }
      }

      // Derived variables block scope
      {
        return {
          page_title: config.page.access_denied_title || _("Access Denied"),
          reason_message,
        };
      }
    }
  }
</script>

<script lang="ts">
  export let config: Config;

  $: ({page_title, reason_message} = update(config, $_));
</script>

<svelte:head>
  <title>{page_title}</title>
</svelte:head>

<div class="skin row limit-columns-1" id="cg-guest-access-denied">
  <div class="skin column">
    <div class="skin content">
      <div class="skin controls narrow">
        {#if config.page.show_access_denied_title}
          <h2 class="form-signin-heading" id="access_denied_title">{page_title}</h2>
        {/if}
        <div class="alert alert-danger">
          {#if !config.access_denied.reasons.length}
            <strong><Translate>Access denied</Translate></strong>
          {/if}
          {#if config.access_denied.reasons.length}
            <p>
              <span class="glyphicon glyphicon-exclamation-sign" />
              <span><Translate>Access is denied for the following reasons:</Translate></span>
            </p>
            <ul>
              {#each config.access_denied.reasons as reason}
                <li>{reason_message(reason)}</li>
              {/each}
            </ul>
          {/if}
        </div>
      </div>
    </div>
  </div>
</div>
