<script context="module" lang="ts">
  export type {PlacementParameters} from "./types";

  export interface Props {
    parameters?: PlacementParameters;
    position?: string;
    tag?: string;
    class?: string;
  }
</script>

<script lang="ts">
  import {onDestroy} from "svelte";
  import {require_place_context} from "./context";
  import {Placement, NamedPosition} from "./types";
  import type {PlacementParameters} from "./types";

  let classes: string | undefined = undefined;
  export let parameters: PlacementParameters | null | undefined = undefined;
  export let position: string | undefined = undefined;
  export let tag: string | undefined = undefined;
  export {classes as class};

  let element: HTMLElement | undefined = undefined;

  const place = require_place_context();
  const placement = new Placement(place);
  const named_position = new NamedPosition(place);
  const deregister_position = named_position.register();
  const deregister_placement = placement.register();

  $: tag_name = tag || "div";
  $: named_position.update(position, element);
  $: placement.update(parameters ?? undefined, element);

  onDestroy(deregister_placement);
  onDestroy(deregister_position);
</script>

<svelte:element this={tag_name} bind:this={element} class={classes}>
  <slot />
</svelte:element>
