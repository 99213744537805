<script context="module" lang="ts">
  import {onMount} from "svelte";
  import {translate, translate_to} from "./index";

  export interface Test {
    language?: string;
  }

  export type TranslatedFn = (msgid: string, msgstr: string, id: string) => void;

  export interface Props {
    tag?: string;
    test?: Test;
    translated?: TranslatedFn; // Only used to test validity of translated HTML
  }

  function build_id(element: HTMLElement, no_trim?: boolean): string {
    let id = "";

    if (element && element.hasChildNodes()) {
      for (const child of element.childNodes) {
        if (child.nodeType === Node.TEXT_NODE) {
          if (child.nodeValue) {
            id += child.nodeValue;
          }
        } else if (child instanceof HTMLElement) {
          if ("cgTranslate" in child.dataset) {
            throw "illegal nested <Translate>: an inner translation MUST be wrapped as a parameter within a <TranslateParam>";
          } else if (child.dataset.cgTranslateParam) {
            id += `{${child.dataset.cgTranslateParam}}`;
          } else {
            const tag = child.tagName.toLowerCase();
            id += `<${tag}>${build_id(child, true)}</${tag}>`;
          }
        } else {
          // console.log("EXCLUDING NODE FROM ID", child);
        }
      }
    }

    id = id.replace(/\s+/g, " ");
    if (!no_trim) {
      id = id.trim();
    }
    return id;
  }

  /**
   * Expands any translation parameters in a message ID to `<span>` elements (as
   * per `<TranslateParam>`).
   *
   * @param {string} id - the message for which the translation parameters are
   * to to be expanded.
   *
   * ### Notes
   *
   * This function is only exported to support testing.
   */
  export function expanded(id: string): string {
    return id.replace(/{([a-z_]+)}/g, (match, name) => {
      return `<span data-cg-translate-param="${name}">${match}</span>`;
    });
  }

  function key_for(element: ChildNode, include_attrs?: boolean): string {
    if (element instanceof Element) {
      if (element instanceof HTMLElement && element.dataset.cgTranslateParam) {
        return `{${element.dataset.cgTranslateParam}}`;
      }

      let tag = element.tagName;
      let attr = "";
      if (include_attrs) {
        let attrs = [];
        if (element.hasAttributes()) {
          for (const attr of element.attributes) {
            const value = attr.value.replace(/\"/g, "&quot;");
            attrs.push(`${attr.name}="${value}"`);
          }
        }
        attrs.sort();
        if (attrs.length > 0) {
          attr = " " + attrs.join(" ");
        }
      }

      let keys = child_keys(element);
      keys.sort();
      const children = keys.join("");

      return `<${tag}${attr}>${children}</${tag}>`;
    } else {
      return "";
    }
  }

  function child_keys(element: Element): string[] {
    const keys = [];
    if (element.hasChildNodes()) {
      for (const child of element.childNodes) {
        const key = key_for(child);
        if (key) {
          keys.push(key);
        }
      }
    }
    return keys;
  }

  function update(element?: HTMLElement, xid_element?: HTMLElement): void {
    if (!element || !xid_element) {
      return;
    }

    const target_key = key_for(element);
    const xid_key = key_for(xid_element);

    if (xid_key !== target_key) {
      return;
    }

    let next = element.firstChild;
    for (let src = xid_element.firstChild; src; src = src.nextSibling) {
      let matched = false;
      for (let dst = next; dst; dst = (dst as ChildNode).nextSibling) {
        if (src.nodeType !== dst.nodeType) {
          continue;
        }
        const src_key = key_for(src);
        const dst_key = key_for(dst);
        if (src_key !== dst_key) {
          continue;
        }
        // Copy the contents
        if (src.nodeType === Node.TEXT_NODE) {
          (dst as Text).data = (src as Text).data;
        } else if (src.nodeType === Node.COMMENT_NODE) {
          (dst as Comment).data = (src as Comment).data;
        } else if (src.nodeType === Node.ELEMENT_NODE) {
          if (!(src as HTMLElement).dataset.cgTranslateParam) {
            // Recursively update non-translation parameter elements.
            update(dst as HTMLElement, src as HTMLElement);
          }
        } else {
          // console.log("EXCLUDING NODE FROM UPDATE:", src);
        }
        // Move the node to the correct location
        if (dst !== next) {
          if (dst.parentNode) {
            dst.parentNode.insertBefore(dst, next);
          }
        } else {
          next = next.nextSibling;
        }
        matched = true;
        break;
      }
      if (!matched && src.nodeType === Node.TEXT_NODE) {
        // console.log("NO MATCH ON UPDATE FOR:", src);
        const text = new Text((src as Text).data);
        element.insertBefore(text, next);
        // console.log("INSERTED NEW TEXT:", text);
      }
    }

    // Assign empty strings to the remaining text/comment nodes
    for (let dst = next; dst; dst = dst.nextSibling) {
      if (dst.nodeType === Node.TEXT_NODE) {
        (dst as Text).data = "";
      } else if (dst.nodeType === Node.COMMENT_NODE) {
        (dst as Comment).data = "";
      }
    }
  }
</script>

<script lang="ts">
  export let tag: string | undefined = undefined;
  export let test: Test | undefined = undefined;
  export let translated: TranslatedFn | undefined = undefined;

  // Perhaps iterate the Node.childNodes to replace text components?
  let element: HTMLElement;
  let xid_element: HTMLElement;
  let id: string;

  $: element_tag = tag ? tag : "span";
  $: msgid = id || "";
  $: msgstr = test?.language ? $translate_to(test.language, msgid) : $translate(msgid);
  $: xid = expanded(msgstr);
  $: {
    update(element, xid_element);
    if (translated) {
      translated(msgid, msgstr, build_id(element));
    }
  }

  onMount(() => {
    id = build_id(element);
  });

  // Add empty text nodes before and after content to allow for moving nodes
  // around.
</script>

<svelte:element this={element_tag} bind:this={element} data-cg-translate>
  {""}<slot />{""}
</svelte:element><!--
    
IMPORTANT: Ensure that there is NO WHITESPACE between the previous translation
element and the following hidden translation HTML. Otherwise, spurious
whitespace will be introduced into displayed translations.

-->{#key xid}
  {#if xid}
    <svelte:element this={element_tag} bind:this={xid_element} data-cg-translate class="cg-translated">
      {""}{@html xid}{""}
    </svelte:element>
  {/if}
{/key}

<style>
  .cg-translated {
    display: none;
  }
</style>
